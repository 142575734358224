import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function Content({ Icon, text, orientation, contentText }) {
	if (Icon && text && !orientation)
		return (
			<>
				<span className="icons">
					<Icon />
				</span>

				<span className="text">{text}</span>
			</>
		);
	if (Icon && text && orientation)
		return (
			<>
				<span className="text">{contentText || text}</span>
				<span className="icons">
					<Icon />
				</span>
			</>
		);
	if (Icon && !text && !orientation)
		return (
			<span className="icons">
				<Icon />
			</span>
		);
	return <span>{text}</span>;
}
function Button({
	classes,
	Icon,
	link,
	action,
	text,
	orientation,
	contentText,
}) {
	if (link)
		return (
			<NavLink to={link} className={`m-button-container ${classes}`}>
				<Content
					Icon={Icon}
					text={text}
					orientation={orientation}
					contentText={contentText}
				/>
			</NavLink>
		);
	if (!text)
		<button
			type="button"
			tabIndex="-1"
			className={`m-button-container ${classes}`}
			onClick={action}
			onKeyPress={action}
		>
			<Content
				Icon={Icon}
			/>
		</button>;

	return (
		<div
			role="button"
			tabIndex="-1"
			className={`m-button-container ${classes}`}
			onClick={action}
			onKeyPress={action}
		>
			<Content
				Icon={Icon}
				text={text}
				orientation={orientation}
				contentText={contentText}
			/>
		</div>
	);
}

Content.propTypes = {
	Icon: PropTypes.object,
	text: PropTypes.string,
	orientation: PropTypes.string,
	contentText: PropTypes.object,
};

Content.defaultProps = {
	Icon: null,
	text: null,
	orientation: null,
	contentText: null,
};

Button.propTypes = {
	classes: PropTypes.string,
	Icon: PropTypes.object,
	link: PropTypes.string,
	action: PropTypes.func,
	text: PropTypes.string,
	orientation: PropTypes.string,
	contentText: PropTypes.object,
};

Button.defaultProps = {
	classes: null,
	Icon: null,
	link: null,
	action: () => {},
	text: null,
	orientation: null,
	contentText: null,
};
export default Button;
