/**
 * @format
 */

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";

import uuid from "react-uuid";
import logPageView from "utils/logPageView";
import parse from "html-react-parser";
import Layout from "mobile/components/layout/Title";

import ShowTermTitle from "../components/ShowTermTitle";
import ShowTermContent from "../components/ShowTermContent";
import ScrollToTop from "../components/buttons/BackToTop";

function Terms({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	termsData,
	fetchWPNoticesData,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"notices",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	return (
		dataLoaded && (
			<Layout
				titleSlot={termsData?.acf?.terms_of_use?.page_title}
				pageClass="terms"
				headerClass="my-0"
			>
				<div className="terms">
					<Container className="notices-anchors">
						<div className="terms-content">
							{parse(termsData?.acf?.terms_of_use?.description)}
						</div>
						<div>
							<ul className="terms-list list-unstyled">
								{termsData?.acf?.terms_of_use?.blocks &&
									termsData.acf.terms_of_use.blocks.map((termsTitles, i) => (
										<ShowTermTitle
											key={termsTitles.title}
											anchorLink={`#terms_of_use-${i}`}
											content={termsTitles}
										/>
									))}
							</ul>
						</div>
					</Container>
					<Container>
						{termsData?.acf?.terms_of_use?.blocks &&
							termsData.acf.terms_of_use.blocks.map((termsTexts, i) => (
								<Fragment key={uuid()}>
									<ShowTermContent
										id={`terms_of_use-${i}`}
										content={termsTexts}
									/>
								</Fragment>
							))}
						<ScrollToTop />
					</Container>
				</div>
			</Layout>
		)
	);
}

Terms.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	termsData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Terms.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	termsData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Terms;
