/** @format */
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { CSSTransition } from 'react-transition-group';
import Button from "components/Buttons/Button";

const SHOW_DELAY = 100;

function Trivia({
	mission_id,
	answers,
	question,
	expiration,
	id,
	sendEventInput,
	resetMission,
	layout,
	contextId,
	timerColor,
	timerOutlineColor,
}) {
	const ref = useRef();
	const [userChoice, setUserChoice] = useState(null);
	const [show, setShow] = useState(false);
	const [inputAnswer, setInputAnswer] = useState('');
	const [duration, setDuration] = useState(0);

	useEffect(() => {
		setDuration((expiration - dayjs().valueOf()) / 1000);
	}, [expiration]);

	function onTimerComplete() {
		return !userChoice ?
			setTimeout(() => {
				resetMission({
					item_id: mission_id,
				});
			}, 600)
			: null;
	}

	function renderTimer() {
		return (
			<CountdownCircleTimer
				isPlaying
				duration={duration}
				size={75}
				strokeWidth={4}
				colors={timerColor}
				trailColor={timerOutlineColor}
				onComplete={() => onTimerComplete()}
				className="timer"
			>
				{({ remainingTime }) => <div className="timer-value">{remainingTime}</div>}
			</CountdownCircleTimer>
		);
	}

	function onClickAnswer(answer) {
		setUserChoice(answer);

		setTimeout(() => {
			window.dataLayer.push({
				'event': 'generic_event',
				'eventCategory': 'answer to gamification',
				'eventAction': mission_id + ' - ' + question,
				'eventLabel': answer,
			});

			sendEventInput({
				mission_id,
				objective_id: id,
				answer,
				instance_id: contextId,
			});
		}, 1200);

		setTimeout(() => {
			setShow(false);
		}, 1000);
	}

	function sendWrittenAnswer() {
		setUserChoice(false);
		answers.forEach((answer) => {
			if (inputAnswer === answer.trigger) {
				setUserChoice(true);
			}
		});

		setTimeout(() => {
			window.dataLayer.push({
				'event': 'generic_event',
				'eventCategory': 'answer to gamification',
				'eventAction': mission_id + ' - ' + question,
				'eventLabel': inputAnswer,
			});

			sendEventInput({
				mission_id,
				objective_id: id,
				'answer': inputAnswer,
				instance_id: contextId,
			});

		}, 1200);

		setTimeout(() => {
			setShow(false);
		}, 1000);
	}

	function renderAnswers() {
		return (
			<>
				{(answers || []).map((answer) => {
					if (answer.image) {
						return (
							<div className='answer-image-wrapper'>
								<img src={answer.image} alt='answer' className='answer-image' />
							</div>
						);
					}

					return null;
				})}

				<div className='answers-wrapper'>
					{(answers || []).map((answer) => {
						if (answer.image) {
							return null;
						}

						if (!userChoice) {
							return (
								<div
									role='button'
									tabIndex='-1'
									onKeyDown={() => onClickAnswer(answer.trigger)}
									onClick={() => onClickAnswer(answer.trigger)}
									key={answer.trigger}
									className='answer'
								>
									{answer.display}
								</div>
							);
						}

						if (answer.image) {
							return null;
						}

						return (
							<div key={answer.trigger} className={`answered ${userChoice && answer.answer ? 'right-answer' : ''}`}>
								{answer.display}
							</div>
						);
					})}
				</div>
			</>
		);
	}

	function renderWritten() {
		return (
			<div className='answers-wrapper'>
				{(answers || []).map((answer) => {
					if (answer.image) {
						return (
							<div className='answer-image-wrapper'>
								<img src={answer.image} alt='answer' className='answer-image' />
							</div>
						);
					}

					return null;
				})}

				{(answers || []).map((answer) => {
					if (answer.answer) {
						return (
							<>
								{userChoice !== null && <div className='right-answer'>{answer.answer}</div>}
								<input
									type='text'
									name='text'
									className={`answer-written ${userChoice ? 'right-answered' : ''}`}
									autoComplete='off'
									value={inputAnswer}
									onChange={(e) => setInputAnswer(e.target.value)}
									placeholder={answer.display}
								/>
								<Button action={() => sendWrittenAnswer()} classes='secondary large' text='Send Answer' />
							</>
						);
					}

					return null;
				})}
			</div>
		);
	}

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, SHOW_DELAY);
	}, []);

	if(layout === null) {
		console.error("Trivia not fully configured in hive");
		return null;
	}

	const type = 'trivia-' + layout;
	
	return (
		<CSSTransition in={show} timeout={60} appear unmountOnExit nodeRef={ref}>
			<div ref={ref} className={type}>
				<div className="background" />
				<div className='timer'>{renderTimer()}</div>
				<div className='question'>{question}</div>
				{layout === 'written' ? renderWritten() : renderAnswers()}
			</div>
		</CSSTransition>
	);
}

Trivia.propTypes = {
	answers: PropTypes.array,
	timerColor: PropTypes.string,
	timerOutlineColor: PropTypes.string,
	question: PropTypes.string,
	mission_id: PropTypes.string,
	expiration: PropTypes.number,
	id: PropTypes.number,
	sendEventInput: PropTypes.func,
	resetMission: PropTypes.func,
	layout: PropTypes.string,
	contextId: PropTypes.string,
};

Trivia.defaultProps = {
	answers: [],
	timerColor: '',
	timerOutlineColor: '',
	question: '',
	mission_id: '',
	expiration: 30,
	id: 0,
	sendEventInput: () => {},
	resetMission: () => {},	
	layout: '',
	contextId: '',
};

export default Trivia;
