import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function Content({ icon, icon2, text, orientation, contentText }) {
	if (icon && !orientation)
		return (
			<>
				<span className="icons">
					{icon && <img className="one" src={icon} alt={`${text} button icon one`} />}
					{icon2 && <img className="two" src={icon2} alt={`${text} button icon two`} />}
					{!icon2 && <img className="two" src={icon} alt={`${text} button icon two`} />}
				</span>

				<span className="text">
					{text}
				</span>
			</>);
	if (icon && orientation)
		return (
			<>
				<span className="text">
					{contentText || text}
				</span>
				<span className="icons">
					{icon && <img className="one" src={icon} alt={`${text} button icon one`} />}
					{icon2 && <img className="two" src={icon2} alt={`${text} button icon two`} />}
					{!icon2 && <img className="two" src={icon} alt={`${text} button icon two`} />}
				</span>
			</>);
	return (
		<span>
			{text}
		</span>);
}
function Button({ classes, icon, icon2, link, action, text, orientation, contentText }) {
	if (link)
		return (
			<NavLink
				to={link}
				className={`button-container ${classes}`}>
				<Content icon={icon} icon2={icon2} text={text} orientation={orientation} contentText={contentText} />
			</NavLink >
		);

	return (
		<div
			role="button"
			tabIndex="-1"
			className={`button-container ${classes}`}
			onClick={action}
			onKeyPress={action}
		>
			<Content icon={icon} icon2={icon2} text={text} orientation={orientation}  contentText={contentText} />
		</div >
	);
}

Content.propTypes = {
	icon: PropTypes.string,
	icon2: PropTypes.string,
	text: PropTypes.string,
	orientation: PropTypes.string,
	contentText: PropTypes.object,
};

Content.defaultProps = {
	icon: null,
	icon2: null,
	text: null,
	orientation: null,
	contentText: null,
};

Button.propTypes = {
	classes: PropTypes.string,
	icon: PropTypes.string,
	icon2: PropTypes.string,
	link: PropTypes.string,
	action: PropTypes.func,
	text: PropTypes.string,
	orientation: PropTypes.string,
	contentText: PropTypes.object,
};

Button.defaultProps = {
	classes: null,
	icon: null,
	icon2: null,
	link: null,
	action: () => { },
	text: null,
	orientation: null,
	contentText: null,
};
export default Button;
