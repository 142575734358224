import { createSlice } from "@reduxjs/toolkit";

const toasts = JSON.parse(localStorage.getItem("toastNotifications"));

const toastNotifications = createSlice({
	name: "toastNotifications",
	reducers: {
		/**
		 *Set the tile Id associated with the video bubble
		 */
		createToast: (state, action) => {
			return {
				...state,
				notifications: [...state.notifications, action.payload],
			};
		},
		/**
		 *Set the tile Id associated with the video bubble
		 */
		markAsRead: (state, action) => {
			const itemMarked = state.notifications.filter(
				(item) => item.id !== action.payload.id
			);
			return {
				...state,
				notifications: [...state.notifications].filter(
					(item) => item.id === action.payload.id
				),
				seenNotifications: [...state.seenNotifications, itemMarked],
			};
		},
	},
	extraReducers: {},
	initialState: {
		notifications: [],
		seenNotifications: [],
	},
});

export default toastNotifications;

export const { createToast, markAsRead } = toastNotifications.actions;
