/* eslint-disable default-case-last */
import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import VideoPlayer from "containers/VideoPlayer/VideoPlayer";
import ActivityFooter from "containers/Activity/ActivityFooter";
import Events from "containers/Events/Events";
import Leaderboard from "containers/Events/Leaderboard";
import PollResults from "containers/Activity/PollResults";
import ProfileCard from "containers/ProfileCard";
import NotFound from "containers/Pages/NotFound";
import StreamStatus from "components/Activity/StreamStatus";
import EmojiComponent from "components/Events/Emojis/EmojiComponent";
import MeetingStarted from "components/Activity/MeetingStarted";
import ActivityLeftColumn from "components/Activity/ActivityLeftColumn";
import ActivityRightColumn from "components/Activity/ActivityRightColumn";
import { PLAYFAB_EVENTS, ROUTES, REACT_APP_AS_STACK_APP_ID } from "Constants";
import { now, toLocalTime } from "utils/dateUtils";
import logPageView from "utils/logPageView";

function ActivityRoom({
	playerStatistics,
	leaderboardOnUserPosition,
	playerData,
	activity,
	chatJoinRoom,
	chatLeaveRoom,
	username,
	playfabId,
	joinMeetingURL,
	history,
	getMeetingStatus,
	clearMessages,
	writePlayerEvent,
	writeTelemetryEvent,
	setTranslation,
	translations,
	bubble_translation,
	video_bubble,
	getQuestions,
	emptyVideoPlayer,
	getJitsiJwt,
	setHasAttended,
	hasAttended,
	wasLate,
	setWasLate,
	leftEarly,
	setLeftEarly,
	setTileId,
	acfPageOptions,
	isAdmin,
}) {
	if (!activity) {
		return <NotFound />;
	}
	let timer = null;
	const [shouldFetchPrerecordedStreamURL, setShouldFetchPrerecordedStreamURL] = useState(false);
	const [url, setUrl] = useState("");
	const [state, setState] = useState("waiting");
	const [adjustedTime, setAdjustedTime] = useState(null);
	const [showTranslations, setTranslations] = useState(false);
	const [feedSwitched, setFeedSwitched] = useState(false);
	const [displayTranslationsButton, setDisplayTranslationsButton] = useState(false);
	useEffect(() => {
		let timeout = null;
		if (shouldFetchPrerecordedStreamURL) {
			getMeetingStatus(activity.StoreTileId);

			timeout = setTimeout(() => {
				setShouldFetchPrerecordedStreamURL(false);
			}, 5000);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [shouldFetchPrerecordedStreamURL]);

	useEffect(() => {
		if (activity && !activity.canJoin && !isAdmin) {
			emptyVideoPlayer();
			history.push(ROUTES.AGENDA);
		}
	}, [activity]);

	useEffect(() => {
		const channel = activity.StoreTileId;
		if (channel && username) {
			chatJoinRoom({
				channel,
				username,
				playfabId,
			});
		}
		getQuestions(activity.StoreTileId);
	}, [activity.StoreTileId, username]);

	useEffect(() => {
		addBodyClass();

		writePlayerEvent({
			name: PLAYFAB_EVENTS.player_joined_stream,
			body: {
				StoreTileId: activity.StoreTileId,
				ActivityName: activity?.content?.title?.rendered,
				ActivityType: activity.data.activity_type,
			},
		});

		//Engagement event on arriving
		writeTelemetryEvent({
			namespace: "custom.engagement",
			name: "enter_room",
			body: {
				activity: activity?.content?.title?.rendered,
				StoreTileId: activity.StoreTileId,
			},
		});

		if (activity?.data?.activity_type !== "MeetingRoom") {
			setTileId(activity.StoreTileId);
		}

		//Log PageView
		logPageView("Activity", playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);

		tracking();

		return () => {
			const channel = activity.StoreTileId;
			chatLeaveRoom({
				channel,
			});
			clearMessages();
			removeBodyClass();

			if (!leftEarly.includes(activity.StoreTileId) && activity.date_end.isAfter(now().add(15, "minutes"))) {
				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						LeftEarly: 1,
						MeetingId: activity.StoreTileId,
					},
				});
				setLeftEarly(activity.StoreTileId);
			}
		};
	}, []);

	useEffect(() => {
		if (bubble_translation !== null) {
			setUrl(bubble_translation);
		}
	}, []);

	useEffect(() => {
		function time() {
			if (toLocalTime(activity.date_close).$d <= toLocalTime(Date.now()).$d) {
				emptyVideoPlayer();
				history.push(ROUTES.ROOT);
				return true;
			}
			timer = setTimeout(time, 1000);
			return false;
		}

		if (state === "ended" && !isAdmin) time();

		return () => {
			clearTimeout(timer);
		};
	}, [state, activity.data, adjustedTime]);

	function tracking() {
		if (!hasAttended.includes(activity.StoreTileId)) {
			setHasAttended(activity.StoreTileId);
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasAttended: 1,
					MeetingId: activity.StoreTileId,
				},
			});
		}

		if (!wasLate.includes(activity.StoreTileId) && now().isAfter(activity.date_begin.add(15, "minutes"))) {
			setWasLate(activity.StoreTileId);
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					WasLate: 1,
					MeetingId: activity.StoreTileId,
				},
			});
		}
	}

	function addBodyClass() {
		document.body.classList.add("withChat");
	}

	function removeBodyClass() {
		document.body.classList.remove("withChat");
	}

	function getPlayerState() {
		if (url === "") {
			if (activity.data.activity_type === "Default" || activity.data.activity_type === "Prerecorded") {
				setState("playing");
				setUrl(activity.data.stream_url);
				setAdjustedTime((Date.now() - activity.date_begin.valueOf()) / 1000);
			}

			if (activity.data.activity_type === "MeetingRoom") {
				if (!joinMeetingURL) {
					if (!shouldFetchPrerecordedStreamURL) {
						setShouldFetchPrerecordedStreamURL(true);
					}
				} else {
					getJitsiJwt();
					setState("meeting");
					setUrl(joinMeetingURL);
				}
			}
		}

		switch (state) {
			case "meeting":
				return (
					<MeetingStarted
						url={url}
						joinMeetingURL={joinMeetingURL}
						meetingTitle={activity?.content?.title?.rendered}
						acfPageOptions={acfPageOptions}
					/>
				);
			case "playing":
				return (
					<VideoPlayer
						adjustedTime={adjustedTime}
						activity={activity}
						src={url}
						setUrl={setUrl}
						feedSwitched={feedSwitched}
						setFeedSwitched={setFeedSwitched}
						setAdjustedTime={setAdjustedTime}
						setState={setState}
					/>
				);
			case "ended":
				return <StreamStatus message={acfPageOptions?.acf?.global?.stream_ended_message}/>;
			default:
			case "waiting":
				return <StreamStatus message={acfPageOptions?.acf?.global?.stream_starting_message}/>;
		}
	}

	function changeTranslation(source) {
		setUrl(source);
		setTranslation(source);
		setTranslations(false);
		setAdjustedTime((Date.now() - activity.date_begin.valueOf()) / 1000);
	}

	useEffect(() => {
		const videoplayer = document.getElementsByClassName("azuremediaplayer")[0];
		
		function displayButton() {
			setDisplayTranslationsButton(true);
		}

		function hideButton() {
			setTimeout(function() {
				setDisplayTranslationsButton(false);
			}, 5000);
		}
		if (videoplayer) {
			videoplayer.addEventListener("mouseenter", displayButton);
			videoplayer.addEventListener("mouseleave", hideButton);
		}

		return () => {
			if (videoplayer) {
				videoplayer.removeEventListener("mouseenter", displayButton);
				videoplayer.removeEventListener("mouseleave", hideButton);
			}
		};
	}, [activity]);

	return (
		<div className="activity-room">
			<div className="top-left">
				<ActivityLeftColumn />
				<Events contextId={activity.StoreTileId} />
				{video_bubble.tileId !== null && (
					<>
						{displayTranslationsButton && (
							<div className="div-translations">
								{(showTranslations === false && activity.data.live_translation !== null && activity.data.live_translation !== "null") && (
									<div
										role="button"
										aria-label="Translations list"
										tabIndex="-1"
										onClick={() => setTranslations(true)}
										className="button-toggle-translate"
										onKeyDown={() => setTranslations(!showTranslations)}
									>
										<img src={acfPageOptions?.acf?.theme?.icon?.live?.translations?.url} alt="" />
									</div>
								)}
								{showTranslations === true && activity.data.live_translation !== null && (
									<div
										role="button"
										tabIndex="-1"
										aria-label="Translations button"
										onClick={() => setTranslations(false)}
										className="button-toggle-translate"
										onKeyDown={() => setTranslations(false)}
									>
										<img src={acfPageOptions?.acf?.theme?.icon?.live?.translations?.url} alt="" />
									</div>
								)}
							</div>
						)}
						<div className="div-translation-options">
							{showTranslations === true &&
								activity.data.live_translation !== null &&
								translations.map((val) => {
									return (
										<div
											key={val.id}
											role="button"
											tabIndex="-1"
											className={
												val.data.output === url
													? "button-change-translate active-translation"
													: "button-change-translate"
											}
											onClick={() => changeTranslation(val.data.output)}
											onKeyDown={() => changeTranslation(val.data.output)}
										>
											<p>{val.playfab.DisplayName}</p>
										</div>
									);
								})}
						</div>
					</>
				)}
				{getPlayerState()}
				<Leaderboard channel={activity.StoreTileId} />
				<PollResults />

				<div className="emojis-container">
					<EmojiComponent />
				</div>
			</div>
			<ActivityRightColumn activity={activity} />
			<ActivityFooter activity={activity} />
			{
				(REACT_APP_AS_STACK_APP_ID === "we8edz") &&  <ProfileCard />
			}
			
		</div>
	);
}

ActivityRoom.defaultProps = {
	playerStatistics: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	activity: null,
	chatJoinRoom: () => { },
	chatLeaveRoom: () => { },
	getMeetingStatus: () => { },
	clearMessages: () => { },
	writePlayerEvent: () => { },
	writeTelemetryEvent: () => { },
	getQuestions: () => { },
	emptyVideoPlayer: () => { },
	getJitsiJwt: () => { },
	setTranslation: () => { },
	username: '',
	playfabId: '',
	video_bubble: {},
	joinMeetingURL: null,
	translations: [],
	setHasAttended: () => {},
	hasAttended: null,
	bubble_translation: null,
	setWasLate: () => {},
	wasLate: null,
	setLeftEarly: () => {},
	leftEarly: null,
	setTileId: () => {},
	acfPageOptions: () => null,
	isAdmin: false,
};

ActivityRoom.propTypes = {
	playerStatistics: PropTypes.object,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	activity: PropTypes.object,
	chatJoinRoom: PropTypes.func,
	chatLeaveRoom: PropTypes.func,
	getMeetingStatus: PropTypes.func,
	clearMessages: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	emptyVideoPlayer: PropTypes.func,
	getJitsiJwt: PropTypes.func,
	username: PropTypes.string,
	playfabId: PropTypes.string,
	video_bubble: PropTypes.object,
	setTranslation: PropTypes.func,
	bubble_translation: PropTypes.string,
	translations: PropTypes.array,
	history: PropTypes.object.isRequired,
	joinMeetingURL: PropTypes.string,
	getQuestions: PropTypes.func,
	setHasAttended: PropTypes.func,
	hasAttended: PropTypes.array,
	setWasLate: PropTypes.func,
	wasLate: PropTypes.array,
	setLeftEarly: PropTypes.func,
	leftEarly: PropTypes.array,
	setTileId: PropTypes.func,
	acfPageOptions: PropTypes.object,
	isAdmin: PropTypes.bool,
};

export default memo(ActivityRoom);
