import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
	purchaseTile,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	writeTelemetryEvent,
} from 'redux/playfab';
import ActivityCard from 'components/Channel/ActivityCard';
import { statsSelector } from 'selector/stats';
import { playerLangGroupSelector } from 'selector/player';

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		teamsUserObjectId: state.teams.userObjectId,
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
		playerStatistics: statsSelector(state, ownProps),
		groupLang: playerLangGroupSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		purchaseTile,
		writePlayerEvent,
		getStoreLoadout,
		getItemInventory,
		writeTelemetryEvent,
	}, dispatch)
)(ActivityCard));
