import React from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from 'assets/icons/WL_icon-avatar.png';
import ProfilePictureGreen from 'assets/icons/WL_icon-avatar-hover.png';

function Avatar({
	avatar,
	showGreenAvatar,
}) {
	const ProfileIcon = showGreenAvatar ? ProfilePictureGreen : ProfilePicture;

	return (
		<div className="avatar-ctn">
			<div className="avatar">
				<img src={avatar || ProfileIcon} alt="" />
			</div>
		</div>
	);
}

Avatar.propTypes = {
	avatar: PropTypes.string,
	showGreenAvatar: PropTypes.bool,
};

Avatar.defaultProps = {
	avatar: '',
	showGreenAvatar: false,
};

export default Avatar;
