/** @format */
import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { now, toLocalTime } from "utils/dateUtils";
import { useHistory } from "react-router-dom";
import { SessionContext } from "mobile/context/SessionContext";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import IconSpeakerMuted from "assets/icons/icon-speaker-mute.svg";
import ReactPlayer from "react-player";
import { ROUTES } from "Constants";
import Emoticon from "assets/icons/icon-live-reaction.svg";
import Close from "assets/icons/icon-x-mark.svg";
import FullScreen from "assets/icons/icon-fullscreen.svg";
import StreamStatus from "mobile/components/session/StreamStatus";
import SoundButton from "./videoPlayer/SoundButton";
import Duration from "./videoPlayer/Duration";
import Translations from "./videoPlayer/Translations";
import List from "./session/emojis/List";
import DisplayZone from "./session/emojis/DisplayZone";
import Confirmation from "./modals/Confirmation";

const PRE_RECORDED_STATUS = "Prerecorded";

function SessionVideoPlayer({
	url,
	setActiveSession,
	setVideoHeight,
	points,
	session,
	translations,
	acfPageOptions,
	setUrl,
	setAdjustedTime,
	adjustedTime,
	channel,
	emojis,
	showSessionDetails,
	setShowSessionDetails,
	playfabId,
	broadcastMessage,
	setStateVideo,
	stateVideo,
	chatLeaveRoom,
	clearMessages,
	clearQuestions,
	setShowCompanelNavigation,
}) {
	if (!url) {
		return null;
	}

	let timer = null;
	let preRecordedTimer = null;

	let enteredTime = 0;
	let currentTime = 0;
	let seconds = 0;
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	const playerRef = useRef();
	const history = useHistory();
	const sessionVideo = document.querySelector(".session-player video");
	const [played, setPlayed] = useState(0);
	const [isPreRecordedStarted, setIsPreRecordedStarted] = useState(false);
	const [playerState, setPlayerState] = useState({
		url,
		pip: false,
		playing: true,
		controls: false,
		light: false,
		volume: 1,
		muted: true,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false,
	});
	const { setFullScreen, sessionAttendanceType, minimizeToggle, setMinimizeToggle } = useContext(SessionContext);
	const {  setActiveCompanelTab } =
		useContext(ComPanelContext);
	const [showEmojis, setShowEmojis] = useState(false);
	const [showOverlay, setShowOverlay] = useState(true);
	const [showCloseSessionModal, setShowCloseSessionModal] = useState(false);
	const [fileConfig, setFileConfig] = useState({});

	useEffect(() => {
		if (session?.data?.activity_type === "Default") {
			setFileConfig({
				file: {
					attributes: {
						controlsList: "nodownload",
					},
					forceHLS: true,
				},
			});
		} else {
			setFileConfig({
				file: {
					attributes: {
						controlsList: "nodownload",
					},
				},
			});
		}
	}, [session?.data?.activity_type]);

	function MinimizePlayer(e) {
		e.stopPropagation();
		setMinimizeToggle((minimizeToggle) => !minimizeToggle);
		if (history.location.pathname.indexOf("session") > -1) {
			history.push(ROUTES.MAIN_HUB);
		}
	}

	function ToggleFullScreen(e) {
		e.stopPropagation();
		setFullScreen((fullScreen) => !fullScreen);
	}

	function ToggleOptions() {
		setShowSessionDetails(!showSessionDetails);
	}

	function handleCloseSessionModal(e) {
		e.stopPropagation();
		setShowCloseSessionModal(true);
	}

	function CloseSession(value) {
		if (value === true) {
			setActiveSession((activeSession) => !activeSession);
			setUrl("");
			const channel = session?.StoreTileId;
			chatLeaveRoom({
				channel,
			});
			clearMessages();
			clearQuestions();
			setActiveCompanelTab("");
			setShowCompanelNavigation(true);
			if (history.location.pathname.indexOf("session") > -1) {
				history.push(ROUTES.MAIN_HUB);
			}
		}
		setMinimizeToggle(false);
		setShowCloseSessionModal(false);
	}

	const handleTimeElapsed = (e) => {
		setPlayed(Math.round(e.playedSeconds));
	};

	function getVideoHeight() {
		const VIDEO_HEIGHT = document.querySelector(
			".session-player-wrapper"
		).clientHeight;
		setVideoHeight(VIDEO_HEIGHT);
	}

	const handleStart = () => {
		//Check if we need to sync the player
		handlePlay();
	};

	const handleReady = () => {
		//TODO: UNCOMMENT IF WE WANT TO AUTOPLAY WITHOUT USER PRESSING UNMUTE BUTTON
		// setTimeout(() => {
		// 	setPlayerState((prevState) => ({
		// 		...prevState,
		// 		muted: false,
		// 	}));
		// }, 5000);
		getVideoHeight();
	};

	const handleVolumeChange = (e) => {
		setPlayerState((prevState) => ({
			...prevState,
			volume: parseFloat(e.target.value),
		}));
	};

	const handleToggleMuted = (e) => {
		e.stopPropagation();
		setPlayerState((prevState) => ({
			...prevState,
			muted: !playerState.muted,
		}));
	};

	const handlePause = () => {
		setPlayerState((prevState) => ({
			...prevState,
			playing: false,
		}));
	};

	const handlePlay = () => {
		playerRef.current.seekTo(calculateTimeElapsep());
		setPlayerState((prevState) => ({
			...prevState,
			playing: true,
		}));
	};

	const handlePlayPause = (e) => {
		e.stopPropagation();
		setPlayerState((prevState) => ({
			...prevState,
			playing: !playerState.playing,
		}));
	};

	const handleDuration = (duration) => {
		setPlayerState((prevState) => ({
			...prevState,
			duration,
		}));
	};

	const handleEmojis = (e) => {
		e.stopPropagation();
		setShowEmojis(!showEmojis);
	};

	const handleOverlay = () => {
		setShowOverlay(!showOverlay);
	};

	const handleEnded = () => {
		setPlayerState((prevState) => ({
			...prevState,
			playing: false,
		}));
	};

	//Calculate the time elapsed since the start of the event
	function calculateTimeElapsep() {
		const diffBegin = (Date.now() - session.date_begin.valueOf()) / 1000;
		return diffBegin;
	}

	//Prevent pre-recorded streams from starting before the time
	useEffect(() => {
		function checkPrerecordedStartTime(check) {
			if (playerRef) {
				if (session?.data?.activity_type === PRE_RECORDED_STATUS && !check) {
					handlePause();
					if (session.date_begin.isBefore(now())) {
						handlePlay();
						setIsPreRecordedStarted(true);
						return true;
					}
				}
			}
			preRecordedTimer = setTimeout(checkPrerecordedStartTime, 100);
			return false;
		}

		if (session?.data?.activity_type === PRE_RECORDED_STATUS) {
			checkPrerecordedStartTime(isPreRecordedStarted);
		}

		return () => {
			clearTimeout(preRecordedTimer);
		};
	}, [playerRef, session?.data, adjustedTime, channel]);

	useEffect(() => {
		function time() {
			if (playerRef && session) {
				seconds = playerRef?.current?.getCurrentTime() || 0;
				currentTime = (Date.now() - session.date_begin.valueOf()) / 1000;
				enteredTime = (Date.now() - session.date_begin.valueOf()) / 1000;
				totalLength = playerRef.current.getDuration();
				percentageCompleted = ((currentTime - enteredTime) / totalLength) * 100;

				// is 0
				if (
					!videoStarted &&
					percentageCompleted > 0 &&
					percentageCompleted < 100
				) {
					videoStarted = true;
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "play",
						eventLabel: session.DisplayName,
					});
				}
				// is 25
				if (
					!videoTwentyFive &&
					percentageCompleted > 25 &&
					percentageCompleted < 100
				) {
					videoTwentyFive = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "25%",
						eventLabel: session.DisplayName,
					});
				}
				// is 50
				if (
					!videoFifty &&
					percentageCompleted > 50 &&
					percentageCompleted < 100
				) {
					videoFifty = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "50%",
						eventLabel: session.DisplayName,
					});
				}
				// is 75
				if (
					!videoSeventyFive &&
					percentageCompleted > 75 &&
					percentageCompleted < 100
				) {
					videoSeventyFive = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "75%",
						eventLabel: session.DisplayName,
					});
				}
				// is 90
				if (
					!videoNinety &&
					percentageCompleted > 90 &&
					percentageCompleted < 100
				) {
					videoNinety = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "90%",
						eventLabel: session.DisplayName,
					});
				}
				// is 100
				if (
					!videoComplete &&
					percentageCompleted > 99 &&
					percentageCompleted < 100
				) {
					videoComplete = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "100%",
						eventLabel: session.DisplayName,
					});
				}
				if (toLocalTime(session.date_end).$d <= toLocalTime(Date.now()).$d) {
					setStateVideo("ended");
					return;
				}

				//If the source duration is smaller than the duration of the session we throw the Session Ended screen
				//only on demo dev
				// if (
				// 	playerRef.current.getDuration() ===
				// 		playerRef.current.getCurrentTime() &&
				// 	REACT_APP_AS_STACK_APP_ID === "we8edz"
				// ) {
				// 	setStateVideo("ended");
				// 	return;
				// }

				const t = Math.min(adjustedTime, playerRef.current.getDuration());
				if (t && seconds < t && session?.data?.activity_type) {
					playerRef.current.seekTo(t);
					handlePlay();
				}
			}

			timer = setTimeout(time, 100);
		}

		timer = setTimeout(time, 100);

		return () => {
			clearTimeout(timer);
		};
	}, [playerRef, session?.data, adjustedTime, channel]);

	function handlePlayerDisplay() {
		if (sessionAttendanceType === true) {
			setStateVideo(null);
			return (
				<StreamStatus
					title={acfPageOptions?.acf?.video_player?.in_person?.title}
					message={acfPageOptions?.acf?.video_player?.in_person?.message}
				/>
			);
		}
		switch (stateVideo) {
			case "playing":
				return (
					<ReactPlayer
						playsinline
						ref={playerRef}
						className="session-video"
						url={playerState.url}
						onReady={handleReady}
						onProgress={handleTimeElapsed}
						width="100%"
						height="auto"
						config={fileConfig}
						playing={playerState.playing}
						controls={playerState.controls}
						// loop={loop}
						volume={playerState.volume}
						muted={playerState.muted}
						onPlay={handlePlay}
						onPause={handlePause}
						// onBuffer={() => console.log("onBuffer")}
						// onSeek={(e) => console.log("onSeek", e)}
						onEnded={handleEnded}
						// onError={(e) => console.log("onError", e)}
						onDuration={handleDuration}
						onStart={handleStart}
					/>
				);
			case "waiting":
				return (
					<StreamStatus
						message={acfPageOptions?.acf?.global?.stream_starting_message}
					/>
				);
			case "ended":
				return (
					<StreamStatus
						title={acfPageOptions?.acf?.video_player?.session_ended?.title}
						message={acfPageOptions?.acf?.video_player?.session_ended?.message}
						link={acfPageOptions?.acf?.video_player?.session_ended?.link_text}
						url={ROUTES.PROFILE_BADGES}
					/>
				);
			case "sourceError":
				return (
					<StreamStatus
						title={acfPageOptions?.acf?.video_player?.session_warning?.title}
						message={acfPageOptions?.acf?.video_player?.session_warning?.message}
					/>
				);
			default:
		}
		return null;
	}

	return (
		<>
			<div
				className="session-player"
				onClick={() => handleOverlay()}
				onKeyDown={() => handleOverlay()}
				role="button"
				tabIndex="-1"
			>
				{handlePlayerDisplay()}
				<DisplayZone />
				{playerState.muted && !minimizeToggle && stateVideo === "playing" && (sessionAttendanceType !== true) && (
					<button
						type="button"
						className="d-flex flex-direction-row justify-content-between muted-warning p-2"
						onClick={() =>
							setPlayerState((prevState) => ({
								...prevState,
								muted: false,
							}))
						}
					>
						<img className="muted w-25" src={IconSpeakerMuted} alt="" />
						<span className="text-uppercase">{acfPageOptions?.acf?.video_player?.muted_button}</span>
					</button>
				)}

				<div className="stream-overlay">
					<div className={`top-row ${showOverlay ? "show" : "hide"}`}>
						<div className="container top d-flex justify-content-between">
							<div className="left">
								{stateVideo === "playing" && (
									<>
										<button
											type="button"
											onClick={(e) => MinimizePlayer(e)}
											className="back-btn"
										>
											<div>{acfPageOptions?.acf?.video_player?.back_button}</div>
										</button>

										<div className="vid-status">
											<div className="green-glow" />
											<div className="status-info">
												<div className="status">{acfPageOptions?.acf?.video_player?.live_title}</div>
												<Duration played={played} />
											</div>
										</div>
									</>
								)}

								<div className="event-name">
									{session?.content?.title?.rendered}
								</div>
							</div>
							<div className="d-flex flex-direction-row right">
								<div className="points-fullscreen">
									<span>{`${points} `} {acfPageOptions?.acf?.video_player?.pts_title}</span>
								</div>
								<button
									type="button"
									onClick={(e) => handleCloseSessionModal(e)}
									className="close-btn norm-version"
								>
									<span>{acfPageOptions?.acf?.video_player?.close_button}</span>
								</button>
							</div>
						</div>
					</div>
					{stateVideo === "playing" && (sessionAttendanceType !== true) && (
						<div className={`middle-row ${showOverlay ? "show" : "hide"}`}>
							<button
								type="button"
								onClick={(e) => handlePlayPause(e)}
								className={`${minimizeToggle ? "hide" : "playPause"}`}
							>
								{playerState.playing ? (
									<img
										src={
											acfPageOptions.acf?.theme?.icon?.gallery?.audio_player
												?.pause?.sizes?.thumbnail
										}
										alt=""
									/>
								) : (
									<img
										src={
											acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.play
												?.sizes?.thumbnail
										}
										alt=""
									/>
								)}
							</button>
						</div>
					)}

					<div className="bottom-row">
						<div className="container d-flex justify-content-between">
							<div className="left">
								<div className="emoticons">
									<button type="button" onClick={(e) => handleEmojis(e)}>
										{!showEmojis ? (
											<img src={Emoticon} alt="Live reaction" />
										) : (
											<img src={Close} alt="Live reaction" />
										)}
									</button>
									{showEmojis && (
										<List
											emojis={emojis}
											playfabId={playfabId}
											broadcastMessage={broadcastMessage}
											channel={channel}
										/>
									)}
								</div>
							</div>

							<div className={`right d-flex align-items-center ${showOverlay ? "show" : "hide"}`}>
								{sessionVideo && stateVideo === "playing" && (
									<>
										<SoundButton
											player={sessionVideo}
											volume={playerState.volume}
											muted={playerState.muted}
											handleVolumeChange={handleVolumeChange}
											handleToggleMuted={handleToggleMuted}
										/>
										{/* //TODO: add closed captions btn */}
										{/* <div className="closed-captions">CC</div> */}
										{/* //TODO: Check if need to add function to change url react-player */}
										<Translations
											session={session}
											translations={translations}
											url={url}
											setUrl={setUrl}
											setAdjustedTime={setAdjustedTime}
											acfPageOptions={acfPageOptions}
										/>
									</>
								)}
								<button
									type="button"
									onClick={(e) => ToggleFullScreen(e)}
									className="fullscreen-btn"
								>
									<img src={FullScreen} alt="Fullscreen" />
								</button>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className="video-info">
				<div className="container">
					{/* TODO: UNCOMMENT CODE WHEN MODERATED ROOM ARE CREATED*/}
					{/* <div className="moderated-room d-flex flex-row justify-content-between my-2">
						<span>Discussion to be pursued</span>
						<button
							type="button"
							className="d-flex align-items-center justify-content-center p-2"
						>
							JOIN MODERATED ROOM
						</button>
					</div> */}
					<div className="d-flex justify-content-between">
						{/* //TODO: add participants count */}
						<div className="participants">20 {acfPageOptions?.acf?.video_player?.participants}</div>
						<div className="points">
							{`${points} `}
							<span>{acfPageOptions?.acf?.video_player?.pts_title}</span>
						</div>
					</div>
					<div
						className={`d-flex justify-content-between align-items-center ${
							minimizeToggle ? "minimized-player" : ""
						}`}
					>
						<div className="event-name">
							{session?.content?.title?.rendered}
						</div>
						<button
							type="button"
							onClick={ToggleOptions}
							className="options-btn"
						>
							...
						</button>
						<button
							type="button"
							onClick={(e) => handlePlayPause(e)}
							className={`${minimizeToggle ? "playPause" : "hide"}`}
						>
							{playerState.playing ? (
								<img
									src={
										acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.pause
											?.sizes?.thumbnail
									}
									alt="video icon"
								/>
							) : (
								<img
									src={
										acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.play
											?.sizes?.thumbnail
									}
									alt="video icon"
								/>
							)}
						</button>
						{sessionVideo && (
							<SoundButton
								player={sessionVideo}
								volume={playerState.volume}
								muted={playerState.muted}
								handleVolumeChange={handleVolumeChange}
								handleToggleMuted={handleToggleMuted}
								minimizeToggle={minimizeToggle}
								className={minimizeToggle ? "minimized-sound-button" : "hide"}
							/>
						)}
						<button
							type="button"
							onClick={(e) => handleCloseSessionModal(e)}
							className="close-btn min-version"
						>
							<span>{acfPageOptions?.acf?.video_player?.close_button}</span>
						</button>
					</div>
				</div>
			</div>
			{showCloseSessionModal && (
				<Confirmation
					onAnswer={(e) => CloseSession(e)}
					text={acfPageOptions?.acf?.video_player?.leave_session_title}
					modal={showCloseSessionModal}
					setModal={setShowCloseSessionModal}
					acfPageOptions={acfPageOptions}
				/>
			)}
		</>
	);
}

SessionVideoPlayer.defaultProps = {
	url: "",
	setActiveSession: () => {},
	setVideoHeight: () => {},
	points: 0,
	session: null,
	translations: [],
	acfPageOptions: null,
	setUrl: () => {},
	setAdjustedTime: () => {},
	adjustedTime: null,
	channel: "",
	emojis: null,
	showSessionDetails: false,
	setShowSessionDetails: () => {},
	playfabId: null,
	broadcastMessage: () => {},
	setStateVideo: () => {},
	stateVideo: null,
	chatLeaveRoom: () => {},
	clearMessages: () => {},
	clearQuestions: () => {},
	setShowCompanelNavigation: () => {},
};

SessionVideoPlayer.propTypes = {
	url: PropTypes.string,
	setActiveSession: PropTypes.func,
	setVideoHeight: PropTypes.func,
	points: PropTypes.number,
	session: PropTypes.object,
	translations: PropTypes.array,
	acfPageOptions: PropTypes.object,
	setUrl: PropTypes.func,
	setAdjustedTime: PropTypes.func,
	adjustedTime: PropTypes.number,
	channel: PropTypes.string,
	showSessionDetails: PropTypes.bool,
	setShowSessionDetails: PropTypes.func,
	emojis: PropTypes.array,
	playfabId: PropTypes.string,
	broadcastMessage: PropTypes.func,
	setStateVideo: PropTypes.func,
	stateVideo: PropTypes.string,
	chatLeaveRoom: PropTypes.func,
	clearMessages: PropTypes.func,
	clearQuestions: PropTypes.func,
	setShowCompanelNavigation: PropTypes.func,
};

export default SessionVideoPlayer;
