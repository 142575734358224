/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from "Constants";
import {ReactComponent as IconSearch} from "assets/icons/pack1/speakers/icon-search.svg";
import {ReactComponent as IconClose} from "assets/icons/pack1/speakers/icon-close-bold.svg";
import Layout from "mobile/components/layout/Title";
import logPageView from "utils/logPageView";
import Speaker from '../components/speaker/Speaker';

function Speakers({ 
	speakersData, 
	eventTiles, 
	acfPageOptions,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData, 
}) {
	const [showAllSpeakers, setShowAllSpeakers] = useState(true);
	const [noMatches, setNoMatches] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"speakers",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	function filterSpeakers(searchInput) {
		let speakersResults = [];
		let finalResults = [];
		const eventsResults = [];
		const speakersArray = speakersData.slice();
		const eventsArray = eventTiles.slice();

		const filteredNames = speakersArray.filter((speaker) => {
			const searchStr = searchInput
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');

			const nameMatches = speaker.title.rendered
				? speaker.title.rendered
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.includes(searchStr)
				: null;

			const companyMatches = speaker.acf.company
				? speaker.acf.company
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.includes(searchStr)
				: null;

			return nameMatches || companyMatches;
		});

		const filteredEvents = eventsArray.filter((event) => {
			const searchEventStr = searchInput
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');

			const nameEventMatches = event.activity.content.title.rendered
				? event.activity.content.title.rendered
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.includes(searchEventStr)
				: null;

			return nameEventMatches;
		});

		if (filteredNames.length > 0) {
			setNoMatches(false);
			setShowAllSpeakers(false);
			speakersResults = [...filteredNames];
		} else if (filteredEvents.length > 0) {
			setNoMatches(false);
			setShowAllSpeakers(false);
			filteredEvents.forEach(speaker => {
				eventsResults.push(...speaker.activity.speakers);
			});
			speakersResults = [...eventsResults];
		} else {
			setSearchResults([]);
			setShowAllSpeakers(false);
			setNoMatches(true);
		}

		// Removing duplicates
		finalResults = [...new Set(speakersResults)];
		setSearchResults(finalResults);
	}

	function renderAllSpeakers(speakers) {
		const allSpeakersArray = speakers.slice();
		// Sort the names in array alphabetically
		const orderAllArray = allSpeakersArray.sort((a, b) =>
			a.title.rendered.localeCompare(b.title.rendered)
		);

		const groupsArray = orderAllArray.reduce((previousValue, currentValue) => {
			// get first letter of name of current element
			const group = currentValue.title.rendered[0];
			// if there is no property in accumulator with this letter create it
			if (!previousValue[group])
				previousValue[group] = { group, children: [currentValue] };
			// if there is push current element to children array for that letter
			else previousValue[group].children.push(currentValue);
			// return accumulator
			return previousValue;
		}, {});

		// since data at this point is an object, to get array of values
		// we use Object.values method
		const completeArray = Object.values(groupsArray);

		if (!completeArray) return null;

		return (
			<>
				{searchResults && (
					<div className="results">Results ({allSpeakersArray.length})</div>
				)}
				{completeArray.map((arrayGroup) => {
					return (
						<div
							className={`group-${arrayGroup.group.toLowerCase()}`}
							key={`group-${arrayGroup.group.toLowerCase()}`}
						>
							<div className="separator">{arrayGroup.group}</div>
							<div className="letter-group">
								<div className="speakers-wrapper">
									{arrayGroup.children.map((child) => {
										return (
											<Link key={`speaker-${child.id}`} to={ROUTES.SPEAKER.replace(":speakerId", child.slug)}>
												<Speaker
													thumbnail={child?.acf?.photo?.sizes?.large}
													type={child?.acf.type}
													name={child?.title?.rendered}
													jobTitle={child?.acf?.subtitle}
												/>
											</Link>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</>
		);
	}

	function searchNames(e) {
		e.preventDefault();
		const inputValue = document.querySelector('.speakers-form input').value.trim();
		if (inputValue.length <= 0) {
			clearFilter();
		} else {
			filterSpeakers(inputValue);
		}
	}
	function clearFilter(){
		// setValue(""); TODO:
		setShowAllSpeakers(true);
		renderAllSpeakers(speakersData);
	}

	return (
		<Layout
			titleSlot={acfPageOptions?.acf?.speakers?.title}
			pageClass="speakers-listing"
		>
			<div className="container-fluid">
				<div className="searchbar-wrapper">
					<form className="speakers-form">
						<input
							type="text"
							placeholder={acfPageOptions?.acf?.speakers?.search_by}
							onChange={searchNames}
						/>
						<button type="button" onClick={!showAllSpeakers ? clearFilter : () => {}}>
							<span className="visually-hidden">{acfPageOptions?.acf?.gallery?.submit}</span>
							{showAllSpeakers ? <IconSearch /> : <IconClose />}
						</button>
					</form>
				</div>
				<div className="listing">
					{speakersData && speakersData.length > 0 && showAllSpeakers &&
						renderAllSpeakers(speakersData)}
					{searchResults  &&
						searchResults.length > 0 && 
						renderAllSpeakers(searchResults)}
					{noMatches && <h5 className="no-match">{acfPageOptions?.acf?.gallery?.no_matches}</h5>}
				</div>
			</div>
		</Layout>
		
	);
}

Speakers.defaultProps = {
	eventTiles: null,
	speakersData: null,
	acfPageOptions: null,
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

Speakers.propTypes = {
	eventTiles: PropTypes.array,
	speakersData: PropTypes.array,
	acfPageOptions: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

export default Speakers;
