import React, { useState } from "react";
import { object, shape, string, func } from "prop-types";
import { getTimeStyleShort, now } from "utils/dateUtils";
import { ReactComponent as Live } from "assets/icons/pack1/agenda/attendance-type/icon-live.svg";
import { ReactComponent as Virtual } from "assets/icons/pack1/agenda/icon-virtual-meeting.svg";
import { ReactComponent as Minus } from "assets/icons/pack1/minus-icon.svg";
import { ReactComponent as Plus } from "assets/icons/pack1/plus-icon.svg";
import { ReactComponent as Arrow } from "assets/icons/pack1/arrow.svg";
import { ReactComponent as VirtualMeet } from "assets/icons/pack1/agenda/virtual-meet-icon.svg";
import { ReactComponent as LiveMeet } from "assets/icons/pack1/agenda/live-meet-icon.svg";
import { ReactComponent as PrivateMeet } from "assets/icons/pack1/agenda/private-event-icon.svg";
import { ReactComponent as BreakoutMeet } from "assets/icons/pack1/agenda/breakout-meet-icon.svg";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Constants";
import uuid from "react-uuid";
import LoadingGif from "components/LoadingGif";
import Modal from "components/Modal";

function AgendaCardItem({
	acfPageOptions,
	tile,
	userAttendanceType,
	purchaseTile,
	writeTelemetryEvent,
	getStoreLoadout,
	getItemInventory,
	writePlayerEvent,
}) {
	const {
		content: {
			acf: { event_type, attendance_type },
			title: { rendered },
		},
		date_begin,
		date_end,
		canUnregister,
		canPurchase,
		canJoin,
		StoreTileId,
		id,
		item_instance_id,
	} = tile.activity;

	const [loading, setLoading] = useState(false);

	const fullTimeEnd = getTimeStyleShort(date_end.$d, "en-US");
	const fullTimeStart = getTimeStyleShort(date_begin.$d, "en-US");
	const [showModal, setShowModal] = useState(false);
	const history = useHistory();
	const dateNow = now(new Date(), "en-US");

	const renderDate = () => {
		return (
			<div className="date">
				<div className="time">
					<div className="start">
						<span className="date-title">
							{acfPageOptions?.acf?.event?.date?.from}
						</span>
						<span className="agenda-standard-font-size">{fullTimeStart}</span>
					</div>
					<div className="end">
						<span className="date-title">
							{acfPageOptions?.acf?.event?.date?.to}
						</span>
						<span className="agenda-standard-font-size">{fullTimeEnd}</span>
					</div>
				</div>
			</div>
		);
	};

	const isEventPast = () => {
		if (dayjs().isAfter(dayjs(date_end), "hour")) {
			return "past-agenda-card";
		}
		return "agenda-card";
	};

	const purchase = () => {
		setLoading(true);

		purchaseTile(StoreTileId).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 500),

			setTimeout(() => {
				getItemInventory();
				setLoading(false);
			}, 1000),

			// Engagement event on arriving
			writeTelemetryEvent({
				namespace: "custom.engagement",
				name: "subscribe",
				body: {
					activity: rendered,
					StoreTileId,
				},
			}),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "add from agenda",
				eventAction: rendered,
			})
		);
	};

	const unregister = () => {
		setLoading(true);

		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
				setLoading(false);
			}, 2300)
		);

		setShowModal(false);
	};

	const renderMeetingStatus = () => {
		if (
			getTimeStyleShort(dateNow.$d) === getTimeStyleShort(fullTimeStart) ||
			(getTimeStyleShort(dateNow.$d) > getTimeStyleShort(fullTimeStart) &&
				getTimeStyleShort(dateNow.$d) < getTimeStyleShort(fullTimeEnd))
		) {
			return (
				<div className="event-now-message">
					{acfPageOptions?.acf?.event?.time?.now}
				</div>
			);
		}
		return null;
	};

	const renderMeetingStock = () => {
		switch (true) {
			case canPurchase && !canUnregister:
				return (
					<div className="booking-info-spacing agenda-standard-font-size">
						{acfPageOptions?.acf?.event?.limited_stock}
					</div>
				);

			case !canPurchase:
				return (
					<div className="booking-info-spacing agenda-standard-font-size">
						{acfPageOptions?.acf?.event?.fully_booked}
					</div>
				);

			case canUnregister && userAttendanceType === "in-person":
				return <div>{acfPageOptions?.acf?.agenda?.in_person_booked}</div>;

			case canUnregister && userAttendanceType === "virtual":
				return (
					<div className="booking-info-spacing agenda-standard-font-size">
						{acfPageOptions?.acf?.agenda?.virtual_booked}
					</div>
				);

			default:
				return <div>{acfPageOptions?.acf?.event?.limited_stock}</div>;
		}
	};

	function triggerClickEvent(eventLink, eventName) {
		window.dataLayer.push({
			event: "generic_event",
			eventCategory: "event",
			eventAction: "enter event",
			eventLabel: eventName,
		});

		history.push(eventLink);
	}

	const renderMeetingIconType = () => {
		switch (event_type) {
			case "Live":
				return (
					<div className="meeting-icon-type">
						<div className="live-icon">
							<Live />
						</div>

						<div className="live-text-icon">
							{acfPageOptions?.acf?.event?.session_type?.live_session}
						</div>
					</div>
				);

			case "Virtual":
				return (
					<div className="meeting-icon-type">
						<div className="live-text-icon">
							<Virtual />
						</div>

						<div className="live-text-icon">
							{acfPageOptions?.acf?.event?.session_type?.live_session}
						</div>
					</div>
				);

			default:
				return (
					<div className="meeting-icon-type">
						<div className="live-icon">
							<Virtual />
						</div>

						<div className="live-text-icon">
							{acfPageOptions?.acf?.event?.session_type?.live_session}
						</div>
					</div>
				);
		}
	};

	const renderMeetingImages = () => {
		if (attendance_type) {
			return attendance_type?.map((item) => {
				switch (item.value) {
					case "virtual":
						return (
							<div key={uuid()} className="meeting-wrapper">
								<div className="background-meeting-image">
									<VirtualMeet className="meeting-image" />
								</div>
								{userAttendanceType === "virtual" ? (
									<div className="meeting-image-attendance-type-selected " />
								) : (
									<div className="meeting-image-attendance-type-not-selected" />
								)}
							</div>
						);

					case "in-person":
						return (
							<div key={uuid()} className="meeting-wrapper">
								<div className="background-meeting-image">
									<LiveMeet className="meeting-image" />
								</div>
								{userAttendanceType === "in-person" ? (
									<div className="meeting-image-attendance-type-selected" />
								) : (
									<div className="meeting-image-attendance-type-not-selected" />
								)}
							</div>
						);

					default:
						return null;
				}
			});
		}

		switch (event_type) {
			case "PrivateTeamsRoom":
				return (
					<div className="meeting-wrapper">
						<div className="background-meeting-image">
							<PrivateMeet className="meeting-image-single" />
						</div>
					</div>
				);

			default:
				return (
					<div className="meeting-wrapper">
						<div className="background-meeting-image">
							<BreakoutMeet className="meeting-image-single" />
						</div>
					</div>
				);
		}
	};

	const renderButton = () => {
		switch (true) {
			case dayjs().isAfter(dayjs(date_end)):
				return null;

			case !canJoin && !canUnregister:
				return (
					<div>
						{loading ? (
							<div className="loader loading-text">
								<LoadingGif
									acfPageOptions={acfPageOptions}
									status={loading ? "active" : ""}
									cardLoader={loading ? "card-loader" : ""}
								/>
							</div>
						) : (
							<div
								role="button"
								tabIndex="-1"
								onKeyPress={purchase}
								onClick={purchase}
								className="plus-icon"
							>
								<Plus className="plus-icon-style" />
							</div>
						)}
					</div>
				);

			case canJoin:
				return (
					<div className="join-room-wrapper">
						<div
							role="button"
							tabIndex="-1"
							onKeyPress={() =>
								triggerClickEvent(
									ROUTES.ACTIVITY.replace(":tileId", id),
									rendered
								)
							}
							onClick={() =>
								triggerClickEvent(
									ROUTES.ACTIVITY.replace(":tileId", id),
									rendered
								)
							}
							className="join-room-icon"
						>
							<Arrow />
						</div>
					</div>
				);
			case canUnregister:
				return (
					<div>
						{loading ? (
							<div className="loader loading-text">
								<LoadingGif
									acfPageOptions={acfPageOptions}
									status={loading ? "active" : ""}
									cardLoader={loading ? "card-loader" : ""}
								/>
							</div>
						) : (
							<div
								role="button"
								tabIndex="-1"
								onKeyPress={() => setShowModal(true)}
								onClick={() => setShowModal(true)}
								className="minus-icon"
							>
								<Minus />
							</div>
						)}
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className={isEventPast()}>
			<div
				className={`agenda-status ${tile?.activity?.content?.acf?.session_topic?.topic}`}
			/>

			<div className="agenda-card-infos-wrapper">
				<div>
					<div>{renderMeetingStatus()}</div>
					<div className="event-title">{rendered}</div>
					<div>{renderMeetingIconType()}</div>
					<div className="mt-10">{renderMeetingStock()}</div>
					<div className="agenda-infos">
						<div className="agenda-meeting-wrapper">
							{renderMeetingImages()}
						</div>
						<div className="date-wrapper">{renderDate()}</div>
					</div>
				</div>
			</div>

			<div>
				<button type="button">{renderButton()}</button>

				<div className="modal-agenda trivia-modal">
					<Modal
						modal={showModal}
						setModal={setShowModal}
						acfPageOptions={acfPageOptions}
					>
						<div className="modal-header" />

						<div className="button-col-wrapper">
							<p className="text-uppercase">
								{acfPageOptions?.acf?.agenda?.cancel_booking_message}
							</p>

							<button
								onClick={unregister}
								type="button"
								className="btn-filter btn-filter-validate"
							>
								{acfPageOptions?.acf?.global?.logout_buttons?.yes_button}
							</button>

							<button
								onClick={() => setShowModal(false)}
								className="btn-filter-cancel"
								type="button"
							>
								{acfPageOptions?.acf?.global?.logout_buttons?.no_button}
							</button>
						</div>
					</Modal>
				</div>
			</div>
		</div>
	);
}

AgendaCardItem.propTypes = {
	acfPageOptions: object,
	tile: shape({
		date_end: string,
		date_begin: string,
	}),
	purchaseTile: func,
	writeTelemetryEvent: func,
	writePlayerEvent: func,
	userAttendanceType: string,
	getStoreLoadout: func,
	getItemInventory: func,
};

AgendaCardItem.defaultProps = {
	acfPageOptions: null,
	tile: {
		date_end: "",
		date_begin: "",
	},
	purchaseTile: () => {},
	writeTelemetryEvent: () => {},
	writePlayerEvent: () => {},
	userAttendanceType: "",
	getStoreLoadout: () => {},
	getItemInventory: () => {},
};

export default AgendaCardItem;
