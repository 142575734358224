import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingJitsi from 'components/LoadingJitsi';
import { PLAYFAB_EVENTS, DOMAIN_MAGIC_COOKIE, REACT_APP_JITSI_MAGIC_COOKIE } from 'Constants';

function Jitsi({
	url,
	jitsiJwtToken,
	userRole,
	displayName,
	writePlayerEvent,
	setJoinJitsi,
	joinMeetingURL,
	acfPageOptions,
}) {
	let localUserId;
	let userRaisedHand = false;
	let userHasSpoken = false;

	const [iframeLoading, setIframeLoading] = useState(true);
	let api;

	const configHost = {
		fileRecordingsEnabled: true,
		fileRecordingsServiceSharingEnabled: true,
		fileRecordingsServiceEnabled: true,
		liveStreamingEnabled: false,
		transcribingEnabled: false,
		autoCaptionOnRecord: false,
		hideLobbyButton: false,
		requireDisplayName: true,
		prejoinPageEnabled: false,
		enableWelcomePage: false,
		disableChatSmileys: true,
		disableReactions: true,
		noticeMessage: '',
		notifications: [
			// 'connection.CONNFAIL',
			// 'dialog.cameraNotSendingData',
			// 'dialog.kickTitle',
			// 'dialog.liveStreaming',
			// 'dialog.maxUsersLimitReached',
			// 'dialog.micNotSendingData',
			// 'dialog.recording',
			// 'dialog.serviceUnavailable',
			// 'dialog.sessTerminated',
			// 'dialog.sessionRestarted',
			// 'dialog.tokenAuthFailed',
			// 'dialog.transcribing',
			// 'dialOut.statusMessage',
			// 'liveStreaming.busy',
			// 'liveStreaming.failedToStart',
			// 'liveStreaming.unavailableTitle',
			// 'notify.grantedTo',
			// 'notify.kickParticipant',
			// 'notify.mutedRemotelyTitle',
			// 'notify.mutedTitle',
			// 'notify.newDeviceAudioTitle',
			// 'notify.newDeviceCameraTitle',
			// 'notify.raisedHand',
			// 'notify.startSilentTitle',
			// 'recording.failedToStart',
			// 'recording.unavailableTitle',
			// 'toolbar.noAudioSignalTitle',
			// 'toolbar.noisyAudioInputTitle',
			// 'toolbar.talkWhileMutedPopup',
			// 'transcribing.failedToStart',
			// 'localRecording.localRecording',
		],
		hideConferenceSubject: true,
		disableDeepLinking: true,
		analytics: { disabled: true },
		hideParticipantsStats: false,
	};

	const configLarge = {
		startWithAudioMuted: true,
		maxFullResolutionParticipants: 20,
		videoQuality: { minHeightForQualityLvl: { 60: 'standard', 720: 'high' } },
		prejoinPageEnabled: false,
		enableWelcomePage: false,
		hideConferenceSubject: true,
		disableDeepLinking: true,
		analytics: { disabled: true },
		hideParticipantsStats: false,
		disableChatSmileys: true,
		disableReactions: true,
	};

	const interfaceConfigHost = {
		DISABLE_TRANSCRIPTION_SUBTITLES: true,
		DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
		MOBILE_APP_PROMO: false,
		DEFAULT_BACKGROUND: '#004F94',
		DEFAULT_LOCAL_DISPLAY_NAME: 'Moderator',
		AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
		TOOLBAR_BUTTONS: [
			'microphone',
			'camera',
			'desktop',
			'fullscreen',
			'raisehand',
			'videoquality',
			'tileview',
			'mute-everyone',
			'mute-video-everyone',
			'recording',
			'filmstrip',
			'hangup',
		],
		SHOW_CHROME_EXTENSION_BANNER: false,
	};

	const interfaceConfigLarge = {
		APP_NAME: 'Jitsi Meet Custom Lg',
		DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
		MOBILE_APP_PROMO: false,
		DEFAULT_BACKGROUND: '#004F94',
		DEFAULT_LOCAL_DISPLAY_NAME: 'Participant',
		AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
		TOOLBAR_BUTTONS: [
			'microphone',
			'camera',
			'fullscreen',
			'etherpad',
			'raisehand',
			'videoquality',
			'filmstrip',
			'tileview',
			'hangup',
		],
		SHOW_CHROME_EXTENSION_BANNER: false,
	};

	function videoConferenceJoined(event) {
		api.executeCommand('displayName', displayName);
		localUserId = event['id'];
		writePlayerEvent({
			name: PLAYFAB_EVENTS.player_meeting_event,
			body: {
				HasJoined: 1,
				MeetingId: joinMeetingURL,
				RoomType: 'MeetingRoom',
			},
		});
		setIframeLoading(false);
	}
	function dominantSpeakerChanged(event) {
		if (localUserId === event['id'] && userHasSpoken === false) {
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasSpoken: 1,
					MeetingId: url,
				},
			});
			userHasSpoken = true;
		}
	}
	function raiseHandUpdated(event) {
		if (localUserId === event['id'] && userRaisedHand === false) {
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasRaisedHand: 1,
					MeetingId: url,
				},
			});
			userRaisedHand = true;
		}
	}

	function videoConferenceLeft() {
		setJoinJitsi(false);
	}

	const initIframeAPI = (jitsiToken, config, interFaceConfig) => {
		const domain = DOMAIN_MAGIC_COOKIE;
		const options = {
			roomName: REACT_APP_JITSI_MAGIC_COOKIE + '/' + url,
			jwt: jitsiToken,
			width: '100%',
			height: '100%',
			configOverwrite: config,
			interfaceConfigOverwrite: interFaceConfig,
			parentNode: document.querySelector('#jitsi-container'),
		};

		if (window.JitsiMeetExternalAPI) {
			api = new window.JitsiMeetExternalAPI(domain, options);
			api.addEventListener('videoConferenceJoined', videoConferenceJoined, false);
			api.addEventListener('dominantSpeakerChanged', dominantSpeakerChanged, false);
			api.addEventListener('raiseHandUpdated', raiseHandUpdated, false);
			api.addEventListener('videoConferenceLeft', videoConferenceLeft, false);
			api.executeCommand('toggleTileView');
		}
	};

	useEffect(() => {
		if (userRole === 1) {
			initIframeAPI(jitsiJwtToken, configHost, interfaceConfigHost);
		} else {
			initIframeAPI(jitsiJwtToken, configLarge, interfaceConfigLarge);
		}
		return () => {
			api.removeListener('videoConferenceJoined', videoConferenceJoined);
			api.removeListener('dominantSpeakerChanged', dominantSpeakerChanged);
			api.removeListener('raiseHandUpdated', raiseHandUpdated);
			api.removeListener('videoConferenceLeft',videoConferenceLeft);
		};
	}, []);

	return (
		<>
			<LoadingJitsi show={iframeLoading} acfPageOptions={acfPageOptions} />

			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<div id="jitsi-container" />
		</>
	);
}

Jitsi.defaultProps = {
	url: '',
	jitsiJwtToken: null,
	userRole: null,
	displayName: null,
	joinMeetingURL: null,
	writePlayerEvent: () => {},
	setJoinJitsi: () => {},
	acfPageOptions: null,
};

Jitsi.propTypes = {
	url: PropTypes.string,
	jitsiJwtToken: PropTypes.string,
	userRole: PropTypes.number,
	displayName: PropTypes.string,
	joinMeetingURL: PropTypes.string,
	writePlayerEvent: PropTypes.func,
	setJoinJitsi: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Jitsi;
