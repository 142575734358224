/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import * as Sentry from "@sentry/react";

import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { AsStackUtility } from "as-stack-sdk/utility";
import { REACT_APP_LOGIN_REDIRECT_URL } from "Constants";
import Button from "components/Buttons/Button";

function LogOutModal({ show, setShow, acfPageOptions, acfIcon }) {
	const modalRef = useRef();

	function logOutPlatform() {
		const utility = new AsStackUtility();
		const sessionTicket = utility.GetCookie("SessionTicket");
		utility.SetCookie("SessionTicket", sessionTicket, -1);
		Sentry.setUser(null);
		window.location.href = REACT_APP_LOGIN_REDIRECT_URL;
	}
	return (
		<CSSTransition
			in={show}
			timeout={600}
			appear
			nodeRef={modalRef}
			unmountOnExit
		>
			<div className="logout-modal" ref={modalRef}>
				<div className="inner">
					<button
						type="button"
						onClick={() => setShow(false)}
						onKeyPress={() => setShow(false)}
						className="close"
					>
						<img alt="" src={acfIcon?.exit?.url} />
					</button>
					<div className="title">
						{acfPageOptions.acf?.global?.logout_message || 'Are you sure you want to log out?'}
					</div>
					<div className="buttons-container">
						<Button
							classes="large secondary"
							action={() => setShow(false)}
							text={
								acfPageOptions.acf?.global?.logout_buttons
									?.no_button || 'NO'
							}
						/>
						<Button
							classes="large primary"
							action={() => logOutPlatform()}
							text={
								acfPageOptions.acf?.global?.logout_buttons
									?.yes_button || 'YES'
							}
						/>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

LogOutModal.defaultProps = {
	show: false,
	setShow: () => {},
	acfPageOptions: null,
	acfIcon: null,
};

LogOutModal.propTypes = {
	show: PropTypes.bool,
	setShow: PropTypes.func,
	acfPageOptions: PropTypes.object,
	acfIcon: PropTypes.object,
};

export default LogOutModal;
