import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Avatar from "components/Partials/Avatar";
import { now } from "utils/dateUtils";

function Question({
	questionData,
	voteQuestion,
	getQuestions,
	channel,
	displayName,
	fetchQuestion,
	acfPageOptions,
}) {
	const [units, setUnits] = useState("s");
	const timeRef = useRef();
	const timestamp = questionData?.createdAt;
	const [questionVoted, setQuestionVoted] = useState("");
	const isSameAuthor = questionData.author === displayName;
	const icons = acfPageOptions.acf?.theme?.icon;
	// const [upvotes, setUpVotes] = useState(questionData);
	const upVotes = useRef(questionData?.upVote);
	const downVotes = useRef(questionData?.downVote);

	function onClickReactQuestion(vote) {

		if (!localStorage.getItem("question-" + questionData.id)) {
			window.dataLayer.push({
				'event': 'generic_event',
				'eventCategory': 'vote Q&A',
				'eventAction': `${vote}`,
				'eventLabel': `${questionData?.question}`,
			});

			voteQuestion({
				questionId: questionData.id,
				vote,
			});

			if (vote === "up") {
				upVotes.current += 1;
			}

			if (vote === "down") {
				downVotes.current += 1;
			}

			setQuestionVoted(vote);
		}

		localStorage.setItem("question-" + questionData.id, vote);
		fetchQuestion();
		getQuestions(channel);
	}

	useEffect(() => {
		const timer = setInterval(
			() => {
				const nowTimestamp = now().valueOf();
				const diffSecs = dayjs(nowTimestamp).diff(
					dayjs.utc(timestamp).local(),
					"second"
				);
				const diffMins = dayjs(nowTimestamp).diff(
					dayjs.utc(timestamp).local(),
					"minute"
				);

				const diffToShow =
          diffSecs <= 60 ? diffSecs + " sec" : Math.floor(diffMins) + " min";
				if (diffSecs >= 60 && units === "s") {
					setUnits("m");
				}

				if (timeRef && timeRef.current) {
					timeRef.current.innerHTML = diffToShow;
				}
			},
			units === "s" ? 1000 : 60000
		);

		return () => {
			clearInterval(timer);
		};
	}, [units, timestamp]);

	useEffect(() => {
		const myVote = localStorage.getItem("question-" + questionData.id);
		if (myVote != null) {
			setQuestionVoted(myVote);
		}
	}, []);

	function renderQuestion() {
		return (
			<>
				<span className="by">
          Question {questionData?.anonymous === false ? "by" : ""}{" "}
				</span>
				{questionData?.anonymous === false && (
					<span className="author-name">{questionData?.author}</span>
				)}
				{isSameAuthor && <span className="my-question-container"> (me)</span>}
				<p className="text">{questionData?.question}</p>
			</>
		);
	}

	return (
		<div className="question-container">
			<div className="question-content">
				{questionData?.answer ? (
					<div className="question-icon" />
				) : (
					<div className="question-icon question-answered-gray" />
				)}
				<div className="author">
					<Avatar avatar={questionData?.avatar} showGreenAvatar={!!questionData?.answer} />
				</div>
				<div className="content">
					<span className="time" ref={timeRef} />
					<div className="question">{renderQuestion()}</div>
					{questionData?.answer && (
						<div className="answer">
							<p className="title">{acfPageOptions?.acf?.live_chat_panel?.qa_owner_name}</p>
							<span className="text">{questionData?.answer}</span>
						</div>
					)}
				</div>
			</div>
			<div className="question-react">
				<div className="likes">
					<span className="count">{upVotes.current}</span>
					<button
						type="button"
						value="up"
						aria-label="Likes"
						className={`${questionVoted === "up" ? "questionVoted" : ""}`}
						onClick={() => onClickReactQuestion("up")}
						onKeyPress={() => onClickReactQuestion("up")}
					>
						<img src={icons?.live?.thumbs_up?.url} alt="" />
					</button>
				</div>
				<div className="dislikes">
					<span className="count">{downVotes.current}</span>
					<button
						type="button"
						value="down"
						aria-label="Dislikes"
						className={`${questionVoted === "down" ? "questionVoted" : ""}`}
						onClick={() => onClickReactQuestion("down")}
						onKeyPress={() => onClickReactQuestion("down")}
					>
						<img src={icons?.live?.thumbs_down?.url} alt="" />
					</button>
				</div>
			</div>
		</div>
	);
}

Question.defaultProps = {
	questionData: null,
	channel: null,
	voteQuestion: () => {},
	getQuestions: () => {},
	fetchQuestion: () => {},
	displayName: "",
	acfPageOptions: {},
};

Question.propTypes = {
	questionData: PropTypes.object,
	voteQuestion: PropTypes.func,
	channel: PropTypes.string,
	getQuestions: PropTypes.func,
	displayName: PropTypes.string,
	fetchQuestion: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Question;
