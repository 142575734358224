import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { checkText } from 'smile2emoji';
import dayjs from 'dayjs';

import ChatSupportMessage from 'containers/ChatSupport/ChatSupportMessage';
import EmojiChatSupportController from 'components/Events/Emojis/EmojiChatSupportController';
import ChatBorder from "components/ComPanel/Chat/ChatBorder"

function ChatSupportChat({
	channel,
	messages,
	displayName,
	broadcastMessage,
	ownPlayfabId,
	subject,
	canChat,
}) {
	const msgEnd = useRef(null);

	function scrollToBottom() {
		msgEnd.current.scrollIntoView({ behavior: 'smooth' });
	}

	function sendMessage(e) {
		e.preventDefault();

		const text = checkText(e.target.elements.text.value);

		if (text !== '') {
			broadcastMessage({
				type: 'userMessage',
				message: text,
				username: displayName,
				channel,
				playfabId: ownPlayfabId,
			});
		}

		e.target.reset();
	}

	function getDate() {
		const now = dayjs();
		const objectDate = 'Today ' + now.format('MMMM DD YYYY');
		return objectDate;
	}

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	let renderMessages = null;
	if (messages.length > 0) {
		renderMessages = messages.slice(-30).map((m) => {
			return (
				<ChatSupportMessage
					channel={channel}
					id={m.id}
					key={m.id}
				/>
			);
		});
	}

	return (
		<div className="support-chat">
			<div className="topic-section">
				<p>
					SUBJECT :
					{' ' + subject}
				</p>
			</div>
			<div className="messages-list">

				<div className="fix" />
				<p className="support-date">{getDate()}</p>
				{renderMessages}
				<div ref={msgEnd} />
			</div>

			<ChatBorder />

			{canChat && (
				<form className="controls" onSubmit={sendMessage}>
					<input type="text" name="text" className="input message-input" placeholder="Type your message here..." autoComplete="off" />
					{/* <button type="button" className="emoji-button" /> */}
					<button type="submit" aria-label="Send Button" className="send-button" />
					<div className="emoji-controller-ctn">
						<EmojiChatSupportController />
					</div>
				</form>
			)}
		</div>
	);
}

ChatSupportChat.defaultProps = {
	channel: null,
	messages: [],
	displayName: null,
	ownPlayfabId: '',
	broadcastMessage: () => { },
	canChat: false,
	subject: '',

};

ChatSupportChat.propTypes = {
	channel: PropTypes.string,
	messages: PropTypes.array,
	displayName: PropTypes.string,
	broadcastMessage: PropTypes.func,
	canChat: PropTypes.bool,
	ownPlayfabId: PropTypes.string,
	subject: PropTypes.string,
};

export default memo(ChatSupportChat);
