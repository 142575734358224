import React from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

import { ReactComponent as IconBack } from "assets/icons/pack1/navigation/light/icon-back.svg";

function BackButton({ link, localBack, acfPageOptions }) {
	const history = useHistory();

	if (link)
		return (
			<div className="mobile-back-btn-ctn">
				<Link to={link}>
					<IconBack />
					{localBack && (
						<span>
							{localBack || acfPageOptions?.acf?.global?.back}
						</span>
					)}
				</Link>
			</div>
		);
	return (
		<div className="mobile-back-btn-ctn">
			<button type="button" onClick={history.goBack}>
				<IconBack />
				{localBack && (
					<span>
						{localBack || acfPageOptions?.acf?.global?.back}
					</span>
				)}
			</button>
		</div>
	);
}

BackButton.defaultProps = {
	link: null,
	localBack: null,
	acfPageOptions: null,
};

BackButton.propTypes = {
	link: PropTypes.string,
	localBack: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default BackButton;
