import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import dayjs from 'dayjs';

import { ROUTES, PLAYFAB_EVENTS } from "Constants";
import MyAgenda from "containers/Partials/LeftPanel/MyAgenda";
import Avatar from "containers/Partials/Avatar";
import NavOption from "components/Partials/NavOption";
import LogOutModal from "containers/Pages/LogOutModal";

function Navigation({
	onLoaded,
	username,
	acfPageOptions,
	activity,
	customListings,
	showrooms,
	playerGroup,
	playerStatistics,
	navigation,
	isVP,
	writePlayerEvent,
}) {

	const appHistory = useHistory();
	useEffect(() => {
		return appHistory.listen(() => {
			window.scroll(0,0);
		})
	},[appHistory]);
	useEffect(() => {
		onLoaded();
	}, []);

	useEffect(() => {
		if (
			acfPageOptions?.acf?.theme?.background?.navigation?.gradient &&
			acfPageOptions?.acf?.theme?.background?.navigation?.background_image?.url
		) {
			setBackground((prevBackground) => {
				return {
					...prevBackground,
					background: `${acfPageOptions?.acf?.theme?.background?.navigation?.gradient}, url(${acfPageOptions?.acf?.theme?.background?.navigation?.background_image?.url})`,
				};
			});
		} else if (acfPageOptions?.acf?.theme?.background?.navigation?.background_image?.url) {
			setBackground((prevBackground) => {
				return {
					...prevBackground,
					background: `url(${acfPageOptions?.acf?.theme?.background?.navigation?.background_image?.url})`,
				};
			});
		} else if (acfPageOptions?.acf?.theme?.background?.navigation?.gradient) {
			setBackground((prevBackground) => {
				return {
					...prevBackground,
					background: `${acfPageOptions?.acf?.theme?.background?.navigation?.gradient}`,
				};
			});
		}
	}, [acfPageOptions]);


	const [isActive, setActive] = useState(false);
	const [showModal, setShowModal] = useState({});
	const [showRegion, setShowRegion] = useState(false);
	const [background, setBackground] = useState(null);
	const currentRegion =
		acfPageOptions?.acf?.global?.group_language?.groups_list?.length > 0
			? acfPageOptions?.acf?.global?.group_language?.groups_list.filter(
				(group) => group?.value === String(playerStatistics?.region)
			  )
			: null;

	function showMenu() {
		setActive(false);
	}

	function showAgenda() {
		document.querySelector('.navigation-wrap').scrollTop = 0;
		setActive(true);
	}

	function onClickShowLogOutModal() {
		onShowModal(true);
	}

	function onShowModal(show) {
		setShowModal((prev) => {
			return {
				s: show,
				key: show ? Math.random() : prev.key,
			};
		});
	}

	return (
		<>
			{navigation?.acf?.navigation_acf?.my_agenda?.show && (
				<div className="side-agenda-buttons-container">
					<button type='button' className='button-toggle' onClick={showMenu}>
						<div className='toggle'>
							<img
								alt=''
								src={
									isActive
										? acfPageOptions.acf?.theme?.icon?.navigation?.toggle_my_agenda?.image1?.sizes?.thumbnail
										: acfPageOptions.acf?.theme?.icon?.navigation?.toggle_my_agenda?.image2?.sizes?.thumbnail
								}
							/>
						</div>
					</button>
					<button type='button' className='button-switch' onClick={showAgenda}>
						<div className='text'>
							<span className={`${playerGroup === "group6" && "russian-agenda"} agenda`}>
								{navigation?.acf?.navigation_acf?.my_agenda?.title}
							</span>
						</div>
					</button>
				</div>
			)}
			<div className={`navigation-wrap ${isActive ? "swap" : ""}`} style={background}>
				<div className='profile-wrap'>
					<div className='profile-image-ctn'>
						{
							navigation?.acf?.navigation_acf?.profile?.show ? (
								<Link to={ROUTES.PROFILE} className='profile'>
									<Avatar showLevel={false} />
									<p className='name'>{username}</p>
								</Link>
							) : (
								<div className="profile profile-disabled">
									<Avatar showLevel={false} />
									<p className='name'>{username}</p>
								</div>
							)
						}
					</div>
					<nav className='main-nav'>
						<NavOption
							link={ROUTES.ROOT}
							icon={acfPageOptions.acf?.theme?.icon?.navigation?.home?.image1?.sizes?.thumbnail}
							activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.home?.image2?.sizes?.thumbnail}
							text={navigation?.acf?.navigation_acf?.home?.title || acfPageOptions.acf?.home?.title}
							order={acfPageOptions.acf?.theme?.icon?.navigation?.home?.nav_order}
						/>
						{navigation?.acf?.navigation_acf?.events?.show && (
							<NavOption
								link={ROUTES.AGENDA}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.events?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.events?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.events?.title || acfPageOptions.acf?.events?.title}
								order={navigation?.acf?.navigation_acf?.events?.nav_order}
							/>
						)}
						{navigation?.acf?.navigation_acf?.showroom?.show && (
							<>
								<NavOption
									link={ROUTES.SHOWROOMS}
									icon={acfPageOptions.acf?.theme?.icon?.navigation?.showroom?.image1?.sizes?.thumbnail}
									activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.showroom?.image2?.sizes?.thumbnail}
									text={navigation?.acf?.navigation_acf?.showroom?.title || acfPageOptions.acf?.showroom?.title}
									order={navigation?.acf?.navigation_acf?.showroom?.nav_order}
								/>
								<div className='sub-menu' style={{order:navigation?.acf?.navigation_acf?.showroom?.nav_order}}>
									{showrooms &&
										showrooms.map((showRoom) => (
											<NavOption
												link={ROUTES.SHOWROOM.replace(":showRoomSlug", showRoom?.slug)}
												icon={showRoom.acf?.navigation_icon?.image1?.sizes?.thumbnail || acfPageOptions.acf?.theme?.icon?.navigation?.showroom?.image1?.sizes?.thumbnail}
												activeIcon={showRoom.acf?.navigation_icon?.image2?.sizes?.thumbnail ||acfPageOptions.acf?.theme?.icon?.navigation?.showroom?.image2?.sizes?.thumbnail}
												text={showRoom?.title?.rendered}
												key={`${showRoom?.slug}`}
												order={navigation?.acf?.navigation_acf?.showroom?.nav_order}
											/>
										))}
								</div>
							</>
						)}
						{navigation?.acf?.navigation_acf?.interactive_gallery?.show && (
							<NavOption
								link={ROUTES.INTERACTIVE_GALLERY}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.interactive_galleries?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.interactive_galleries?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.interactive_gallery?.title}
								order={navigation?.acf?.navigation_acf?.interactive_gallery?.nav_order}
							/>
						)}

						{navigation?.acf?.navigation_acf?.dealers_lounge?.show && (
							<NavOption
								link={ROUTES.DEALER_LOUNGE}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.dealer_lounge?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.dealer_lounge?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.dealers_lounge?.title}
								order={navigation?.acf?.navigation_acf?.dealers_lounge?.nav_order}
							/>
						)}

						{navigation?.acf?.navigation_acf?.partners?.show && (
							<NavOption
								link={ROUTES.PARTNERS}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.partners?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.partners?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.partners?.title || acfPageOptions.acf?.partners?.title}
								enableActive={!!window.location.pathname.split(`${ROUTES.PARTNER_ALONE}/`)[1]}
								order={navigation?.acf?.navigation_acf?.partners?.nav_order}
							/>
						)}

						{navigation?.acf?.navigation_acf?.partner?.show && (
							<NavOption
								link={ROUTES.PARTNER.replace(":partnerId", navigation?.acf?.navigation_acf?.partner?.target?.post_name)}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.partners?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.partners?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.partner?.title || acfPageOptions.acf?.partner?.title}
								order={navigation?.acf?.navigation_acf?.partner?.nav_order}
							/>
						)}

						{navigation?.acf?.navigation_acf?.faq?.show && (
							<NavOption
								link={ROUTES.FAQ}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.faq?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.faq?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.faq?.title || acfPageOptions.acf?.faq?.title}
								order={navigation?.acf?.navigation_acf?.faq?.nav_order}
							/>
						)}

						{navigation?.acf?.navigation_acf?.event?.show && (
							<NavOption
								link={ROUTES.EVENT.replace(":tileId", navigation?.acf?.navigation_acf?.event?.tile_id)}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.events?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.events?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.event?.title || acfPageOptions.acf?.event?.title}
								order={navigation?.acf?.navigation_acf?.event?.nav_order}
							/>
						)}
						{navigation?.acf?.navigation_acf?.speakers?.show && (
							<NavOption
								link={ROUTES.SPEAKERS}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.speakers?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.speakers?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.speakers?.title || acfPageOptions.acf?.speakers?.title}
								order={navigation?.acf?.navigation_acf?.speakers?.nav_order}
							/>
						)}
						{navigation?.acf?.navigation_acf?.speaker?.show && (
							<NavOption
								link={ROUTES.SPEAKER.replace(":speakerId", navigation?.acf?.navigation_acf?.speaker?.target?.post_name)}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.speakers?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.speakers?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.speaker?.title || acfPageOptions.acf?.speaker?.title}
								order={navigation?.acf?.navigation_acf?.speaker?.nav_order}
							/>
						)}
						{navigation?.acf?.navigation_acf?.chat_rooms?.show && (
							<NavOption
								link={ROUTES.ALL_ROOMS}
								icon={acfPageOptions.acf?.theme?.icon?.navigation?.chat_rooms?.image1?.sizes?.thumbnail}
								activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.chat_rooms?.image2?.sizes?.thumbnail}
								text={navigation?.acf?.navigation_acf?.chat_rooms?.title || acfPageOptions.acf?.chat_rooms?.title}
								order={navigation?.acf?.navigation_acf?.chat_rooms?.nav_order}
							/>
						)}
						{navigation?.acf?.navigation_acf?.custom_listings?.show &&
							customListings &&
							customListings.map((l) => (
								<NavOption
									link={ROUTES.CATEGORY.replace(":categorySlug", l?.category?.slug)}
									icon={acfPageOptions.acf?.theme?.icon?.navigation?.custom_listings?.image1?.sizes?.thumbnail}
									activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.custom_listings?.image2?.sizes?.thumbnail}
									text={l?.category.name}
									key={`${l?.category?.id}`}
									order={navigation?.acf?.navigation_acf?.custom_listings?.nav_order}
								/>
							)
							)}

						{(acfPageOptions?.acf?.global?.group_language?.groups_list && isVP) && (
							<>
								<NavOption
									icon={acfPageOptions.acf?.theme?.icon?.navigation?.group_language?.image1?.sizes?.thumbnail}
									activeIcon={acfPageOptions.acf?.theme?.icon?.navigation?.group_language?.image2?.sizes?.thumbnail}
									text={acfPageOptions?.acf?.global?.group_language?.title}
									onClick={() => setShowRegion(!showRegion)}
									withLink={false}
									order="202"
								/>
								{currentRegion && !showRegion && <div style={{order:"202"}} className="current-region">{currentRegion[0]?.label}</div>}
								{showRegion && (
									<div style={{order:"202"}} id='region-ctn' className='sub-menu'>
										{acfPageOptions?.acf?.global?.group_language?.groups_list &&
											acfPageOptions?.acf?.global?.group_language?.groups_list.map((group) => (
												<NavOption
													key={group?.value}
													link={ROUTES.PARTNERS}
													icon={group?.value === String(playerStatistics?.region) ? acfPageOptions.acf?.theme?.icon?.navigation?.group_language_selector?.image2?.sizes?.thumbnail : acfPageOptions.acf?.theme?.icon?.navigation?.group_language_selector?.image1?.sizes?.thumbnail}
													text={group?.label}
													withLink={false}
													onClick={async() => {
														setShowRegion(false);
														if(group?.value === '1'){
															dayjs.locale('fr')
														} else {
															dayjs.locale('en')
														}
														await writePlayerEvent({
															name: PLAYFAB_EVENTS.change_region,
															body: {
																region: group?.value,
															},
														});
														window.location.replace("/");
													}}
													currentRegion={playerStatistics?.region}
													highlight={group?.value === String(playerStatistics?.region)}
													order="202"
												/>
											))}
									</div>
								)}
							</>
						)}

						<hr style={{order:"200"}}/>
						<NavOption
							logOut='true'
							link='/'
							icon={acfPageOptions.acf?.theme?.icon?.navigation?.logout?.sizes?.thumbnail}
							text={navigation?.acf?.navigation_acf?.log_out?.title}
							onClickShowLogOutModal={() => onClickShowLogOutModal()}
							order="201"
							withLink={false}
						/>

						{/* eslint-disable react/jsx-no-bind */}
						<LogOutModal key={showModal.key} show={showModal.s} setShow={onShowModal} />
						{activity !== null && activity !== "" && window.location.pathname.includes("activity") && (
							<div className='logo'>
								<img src={acfPageOptions.acf?.theme?.logo?.secondary?.sizes?.large} alt='' />
							</div>
						)}
					</nav>
				</div>
				<div className='agenda-wrap'>
					<MyAgenda isActive={isActive}/>
				</div>
			</div>
		</>
	);
}

Navigation.defaultProps = {
	onLoaded: () => {},
	username: null,
	acfPageOptions: null,
	activity: null,
	customListings: [],
	showrooms: [],
	playerStatistics: null,
	navigation: null,
	playerGroup: "",
	isVP: false,
	writePlayerEvent: () => {},
};

Navigation.propTypes = {
	onLoaded: PropTypes.func,
	username: PropTypes.string,
	acfPageOptions: PropTypes.object,
	activity: PropTypes.string,
	customListings: PropTypes.array,
	showrooms: PropTypes.array,
	playerStatistics: PropTypes.object,
	navigation: PropTypes.any,
	isVP: PropTypes.bool,
	playerGroup: PropTypes.string,
	writePlayerEvent: PropTypes.func,
};

export default memo(Navigation);
