/** @format */

import React from "react";
import PropTypes from "prop-types";
import {
	REACT_APP_LOADING_LOGO,
	REACT_APP_LOADING_BACKGROUND,
	REACT_APP_VUSE_CLIENT,
	ROUTES,
} from "Constants";
import Button from "components/Buttons/Button";
import { ReactComponent as DebugIcon } from "assets/icons/pack1/code-slash-outline.svg";

function Status({
	acfPageOptions,
	title,
	description,
	message,
	action,
	redirectURL,
	logo,
	homeLink,
	type,
	acfNavigation,
	setShowDebug,
}) {
	if (redirectURL) {
		setTimeout(() => {
			window.location.replace(redirectURL);
		}, 3000);
	}

	const handleDebugIconClick = () => {
		setShowDebug(true);
	};

	//TODO: Use Status component
	return (
		<div className={`status-screens ${type}`}>
			<div
				className="background-img"
				style={{
					backgroundImage:
						acfPageOptions.acf?.progress_screens?.background?.url ||
						"url(" + REACT_APP_LOADING_BACKGROUND + ")",
				}}
			/>
			<div
				className={`welcome-block ${
					REACT_APP_VUSE_CLIENT ? "vuse-centering" : ""
				}`}
			>
				{!REACT_APP_VUSE_CLIENT && type === "404" && (
					<div className="logo">
						<img
							src={
								logo ||
								acfPageOptions.acf?.theme?.logo?.main?.sizes?.large ||
								REACT_APP_LOADING_LOGO
							}
							alt=""
						/>
					</div>
				)}

				<div className="title">
					<h2>{title || acfPageOptions.acf?.progress_screens?.setup?.title}</h2>
				</div>
				<div className="description">
					<p>
						{description ||
							acfPageOptions.acf?.progress_screens?.setup?.description}
					</p>
				</div>
				{action && (
					<div className="action">
						{redirectURL && <p>{redirectURL && "Redirecting..."}</p>}
						{action && action}
					</div>
				)}
				{homeLink && (
					<div className="action mt-5">
						<Button
							link={ROUTES.ROOT}
							classes="primary large px-5"
							text={
								acfNavigation?.acf?.navigation_acf?.home?.title ||
								"Click here to go back to homepage!"
							}
						/>
					</div>
				)}
				{message && (
					<p className="account-status">
						{message}
						<DebugIcon
							onClick={handleDebugIconClick}
							className="debug-icon"
						/>
					</p>
				)}
			</div>
		</div>
	);
}

Status.defaultProps = {
	acfPageOptions: null,
	title: null,
	description: null,
	redirectURL: null,
	action: null,
	message: null,
	logo: null,
	homeLink: null,
	type: "",
	acfNavigation: null,
	setShowDebug: () => {},
};

Status.propTypes = {
	acfPageOptions: PropTypes.object,
	title: PropTypes.string,
	description: PropTypes.string,
	redirectURL: PropTypes.string,
	action: PropTypes.string,
	message: PropTypes.string,
	logo: PropTypes.string,
	homeLink: PropTypes.bool,
	type: PropTypes.string,
	acfNavigation: PropTypes.any,
	setShowDebug: PropTypes.func,
};
export default Status;
