import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { PLAYFAB_EVENTS, ROUTES, PARTNERSTAT } from "Constants";
import Field from "components/Partials/Field";
import SelectField from "components/Partials/SelectField";
import Avatar from "containers/Partials/Avatar";
import ContentOuterContainer from "components/Profile/ContentOuterContainer";
import ContentInnerContainer from "components/Profile/ContentInnerContainer";
import Row from "components/Profile/Row";
import ColSm from "components/Profile/ColSm";
import ColLg from "components/Profile/ColLg";
import Answers from "containers/Profile/Answers";
import ToggleSwitchLabel from "components/Buttons/ToggleSwitchLabel";
import Button from "components/Buttons/Button";

function ProfileTab({
	playerData,
	updatePlayerData,
	acfPageOptions,
	onClickEditAvatar,
	displayName,
	writePlayerEvent,
	getItemInventory,
	enableNotifications,
	isPartner,
	friendList,
	friendRequests,
	playfabId,
	setActiveTab,
	contactListTab,
	navigation,
}) {
	const { questions, mission_id } =
		acfPageOptions?.acf?.profile?.profile_tab || {};
	const [checkedNotificationsToggle, setCheckedNotificationsToggle] =
		useState(
			enableNotifications?.Value && JSON.parse(enableNotifications?.Value)
		);
	const profileTabAcf = acfPageOptions.acf?.profile?.profile_tab;
	const acfIcons = acfPageOptions?.acf?.theme?.icon?.profile;

	const contactCards = [
		{
			id: 1,
			label: profileTabAcf?.contact_list?.number_of_contacts_title,
			counter: friendList?.length || 0,
			icon: acfIcons?.number_contacts?.url,
			button: acfPageOptions?.acf?.profile?.profile_tab?.contact_list
				?.contact_button,
			action: () => setActiveTab(contactListTab),
		},
		{
			id: 2,
			label: profileTabAcf?.contact_list?.invitation_sent_title,
			counter:
				(friendRequests?.length > 0 &&
					friendRequests.filter(
						(invitations) => invitations.To !== playfabId
					)?.length) ||
				0,
			icon: acfIcons?.invitation_sent?.url,
			button: null,
		},
		{
			id: 3,
			label: profileTabAcf?.contact_list?.invitation_received_title,
			counter:
				(friendRequests?.length > 0 &&
					friendRequests?.filter(
						(invitations) => invitations.To === playfabId
					)?.length) ||
				0,
			icon: acfIcons?.invitation_received?.url,
			button: acfPageOptions?.acf?.profile?.profile_tab?.contact_list
				?.invitation_button,
			action: () => setActiveTab(contactListTab),
		},
	];

	function onUpdateValue(name, value) {
		updatePlayerData({ [name]: value });
	}

	const displayAnswers = () => {
		// This also checks for empty strings
		if (!mission_id || !questions) {
			return null;
		}

		if (questions.length === 0) {
			return null;
		}

		return <Answers />;
	};

	useEffect(() => {
		if (playerData && Object.keys(playerData).length > 0) {
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_completed_profile,
				body: { UserName: displayName },
			});
		}
		getItemInventory();
	}, [playerData]);

	const switchNotifications = () => {
		setCheckedNotificationsToggle(!checkedNotificationsToggle);
		onUpdateValue(
			"EnableNotifications",
			String(!checkedNotificationsToggle)
		);
	};

	return (
		<ContentOuterContainer>
			<Row>
				<ColSm>
					<ContentInnerContainer customClass="left-side">
						{/*TODO: Move the avatar section into its own component*/}
						<div className="avatar-section">
							<h2>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.profile_picture_title
								}
							</h2>
							{acfPageOptions.acf?.profile?.profile_tab
								?.show_edit_picture_button && (
								<Button
									action={() => onClickEditAvatar()}
									classes="secondary large fit"
									text={
										acfPageOptions.acf?.profile?.profile_tab
											?.edit_profile_picture
									}
								/>
							)}
							<div className="avatar-container">
								<Avatar />
							</div>
						</div>
					</ContentInnerContainer>
					<ContentInnerContainer customClass="left-side">
						{/*TODO: Move the section into its own component*/}
						<div>
							<h2 className="title">
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.notices_title
								}
							</h2>
							<p className="label">
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.notices_description
								}
							</p>
							<NavLink
								className="p-0 notices-link"
								to={ROUTES.PRIVACY}
							>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.privacy_policy_label
								}
							</NavLink>
							<NavLink
								className="p-0 notices-link"
								to={ROUTES.TERMS}
							>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.terms_label
								}
							</NavLink>
							<NavLink
								className="p-0 notices-link"
								to={ROUTES.CODE_OF_CONDUCT}
							>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.code_of_conduct_label
								}
							</NavLink>
						</div>
					</ContentInnerContainer>
				</ColSm>
				<ColLg>
					<ContentInnerContainer customClass="right-side">
						<div className="profile-info">
							<h2>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.information_title
								}
							</h2>
							{/*TODO: Move the form to its own component and separate the logics with better docs. Also: test initial case*/}
							{acfPageOptions.acf?.profile?.profile_tab?.inputs
								.length > 0 &&
								acfPageOptions.acf?.profile?.profile_tab?.inputs.map(
									(input) => {
										if (
											isPartner === false &&
											input.player_data_key ===
												PARTNERSTAT.PROFILE_FIELD_NAME
										)
											return null;

										if (!input?.values) {
											return (
												<Field
													key={input?.label}
													name={input?.label}
													value={
														(playerData &&
															playerData[
																input
																	?.player_data_key
															]?.Value) ||
														""
													} //reading playerData with the dynamic value of the label
													onBlur={(value) =>
														onUpdateValue(
															input?.player_data_key,
															value
														)
													} //dynamic assignment to playerData
													isPartner={isPartner}
													icon={
														acfPageOptions?.acf?.theme
															?.icon?.profile
															?.edit_profile?.url
													}
													disabled={input?.disabled}
												/>
											);
										}
										return (
											<SelectField
												key={input?.label}
												name={input?.label}
												options={input?.values.map(
													(inputValue) => {
														return {
															...inputValue,
															label: inputValue?.value,
														};
													}
												)} //matching the required format for the options array [{value:'', label:''},...]
												value={
													(playerData &&
														playerData[
															input
																?.player_data_key
														]?.Value) ||
													""
												}
												onChange={(option) =>
													onUpdateValue(
														input?.player_data_key,
														option.value
													)
												}
												acfPageOptions={acfPageOptions}
												disabled={input?.disabled}
											/>
										);
									}
								)}
							<div className="cols">{displayAnswers()}</div>
						</div>
					</ContentInnerContainer>
				</ColLg>
			</Row>
			<ColSm>
				{navigation?.acf?.navigation_acf?.profile?.display_tab_contact_list && contactCards.map((card) => {
					return (
						<ContentInnerContainer key={card.id}>
							<div className="card-contact-container">
								<div className="card-label">
									<img
										src={card.icon}
										alt="profile"
										className="card-label-icon"
									/>
									<span>{card.label}</span>
								</div>
								<h1>{card.counter}</h1>
							</div>
							<div className="card-button-container">
								{card.button && (
									<Button
										action={() => card.action()}
										classes="secondary large fit"
										text={card.button}
									/>
								)}
							</div>
						</ContentInnerContainer>
					);
				})}
				<ContentInnerContainer>
					<div className="notification-settings">
						<h2>
							{
								acfPageOptions.acf?.profile?.profile_tab
									?.settings_title
							}
						</h2>
						{/* Booleans are not recognized in the PlayerData so we store the value as a String */}
						<div className="button-container">
							<p>
								{
									acfPageOptions.acf?.profile?.profile_tab
										?.notifications_title
								}
							</p>
							<ToggleSwitchLabel
								acfPageOptions={acfPageOptions}
								checked={checkedNotificationsToggle}
								onChange={switchNotifications}
							/>
						</div>
					</div>
				</ContentInnerContainer>
			</ColSm>
		</ContentOuterContainer>
	);
}

ProfileTab.defaultProps = {
	displayName: "",
	playerData: null,
	enableNotifications: null,
	updatePlayerData: () => {},
	onClickEditAvatar: () => {},
	acfPageOptions: null,
	writePlayerEvent: () => {},
	getItemInventory: () => {},
	isPartner: false,
	friendList: {},
	friendRequests: {},
	playfabId: "",
	setActiveTab: () => {},
	contactListTab: null,
	navigation: null,
};

ProfileTab.propTypes = {
	displayName: PropTypes.string,
	enableNotifications: PropTypes.object,
	playerData: PropTypes.object,
	updatePlayerData: PropTypes.func,
	onClickEditAvatar: PropTypes.func,
	acfPageOptions: PropTypes.object,
	writePlayerEvent: PropTypes.func,
	getItemInventory: PropTypes.func,
	isPartner: PropTypes.bool,
	friendList: PropTypes.object,
	friendRequests: PropTypes.object,
	playfabId: PropTypes.string,
	setActiveTab: PropTypes.func,
	contactListTab: PropTypes.number,
	navigation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ProfileTab;
