import React from "react";
import PropTypes from "prop-types";
import IconSpeaker from "assets/icons/icon-speaker.svg";
import IconSpeakerMuted from "assets/icons/icon-speaker-mute.svg";

function SoundButton({
	player,
	muted,
	handleToggleMuted,
	className,
}) {
	if (!player) return null;
	return (
		<div className={`sound-button ${className}`}>
			<button
				type="button"
				className={`mute-button ${muted ? "muted" : "not-muted"}`}
				onClick={(e) => handleToggleMuted(e)}
				onKeyPress={(e) => handleToggleMuted(e)}
			>
				<img className="not-muted" src={IconSpeaker} alt="" />
				<img className="muted" src={IconSpeakerMuted} alt="" />
			</button>
			{/* <input className="slider" type="range" min={0} max={1} step="any" value={volume} onChange={(e) => handleVolumeChange(e)} /> */}
		</div>
	);
}

SoundButton.defaultProps = {
	player: null,
	muted: false,
	handleToggleMuted: () => {},
	className: "",
};

SoundButton.propTypes = {
	player: PropTypes.object,
	muted: PropTypes.bool,
	handleToggleMuted: PropTypes.func,
	className: PropTypes.string,
};

export default SoundButton;
