import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GA from 'GA';

const usePageTracking = () => {
	const location = useLocation();

	useEffect(() => {
		GA.pageView(location.pathname + location.search);
	}, [location]);
};

export default usePageTracking;
