import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { now } from "utils/dateUtils";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SocialLinks from "mobile/components/speaker/SocialLinks";
import SessionCard from "mobile/components/containers/session/SessionCard";
import parse from "html-react-parser";
import Layout from "mobile/components/layout/Title";
import logPageView from "utils/logPageView";

export default function Speaker({
	speaker, 
	acfPageOptions,	
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
}) {
	if (!speaker) return null;
	const { id } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"speaker",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	const events =
		speaker?.tiles?.filter(
			(tile) => !tile?.activity.date_end.isBefore(now())
		) || [];

	function renderSessions() {
		if (!speaker?.tiles || speaker?.tiles?.length === 0) return null;

		const speakersEvent = speaker.tiles
			.filter((tile) => !tile?.activity.date_end.isBefore(now()))
			.map((tile) => {
				return (
					<SwiperSlide key={tile.id}>
						<SessionCard
							key={tile.id}
							id={tile.id}
							stock={tile.stock}
							activity={tile.activity}
							link={acfPageOptions?.acf?.home?.link_to_event_page}
							className="is-happening"
						/>
					</SwiperSlide>
				);
			});

		return (
			<div className= "talk-cards">
				<h1 className="talks">{acfPageOptions?.acf?.speaker?.talks_title}</h1>
				<div className="live-events">
					<Swiper
						breakpoints={{
							375: {
								width: 345,
								slidesPerView: 1.5,
							},
							667: {
								width: 575,
								slidesPerView: 2.5,
							},
						}}
						className="speakerSwiper"
					>
						{speakersEvent}
					</Swiper>
				</div>
			</div>
		);
	}

	return (
		<Layout
			titleSlot={acfPageOptions?.acf?.speaker?.title}
			pageClass="speaker"
			headerClass="my-0"
		>
			<Container className="speaker-container">
				<Row className="speaker-img-ctn justify-content-end">
					{speaker?.acf?.photo?.sizes?.large && (
						<img
							className="px-0"
							src={speaker?.acf?.photo?.sizes?.large}
							alt={`${speaker.title.rendered} display pic`}
						/>
					)}
					{speaker?.acf?.socials && (
						<SocialLinks socials={speaker?.acf?.socials} />
					)}
				</Row>
				<Row className="pt-4">
					<Col xs>
						<div className="speaker-name">{speaker.title.rendered}</div>
					</Col>
					<Col xs>
						<div className="speaker-type text-end">{speaker.acf.type}</div>
					</Col>
				</Row>
				<Row>
					<Col xs>
						<div className="speaker-title">{speaker.acf.subtitle}</div>
						<div className="company-name">{speaker.acf.company}</div>
					</Col>
				</Row>
				<Row>
					<Col xs className="info">
						{speaker?.content?.rendered && parse(speaker?.content?.rendered)}
					</Col>
				</Row>
			</Container>
			{events.length > 0 && (
				<div className="talks-ctn">
					{renderSessions()}
				</div>
			)}
		</Layout>
	);
}

Speaker.defaultProps = {
	speaker: null,
	acfPageOptions: null,
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

Speaker.propTypes = {
	speaker: PropTypes.object,
	acfPageOptions: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};
