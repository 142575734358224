import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import uuid from "react-uuid";
import { func, instanceOf, array } from "prop-types";
import { ReactComponent as IconArrowRight } from "assets/icons/icon-arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "assets/icons/icon-arrow-left.svg";

//TODO: Use DAYJS to do these.
const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

function Calendar({ today, dayWithEvent, setSelectedDate, selectedDate }) {
	const [month, setMonth] = useState(today.getMonth());
	const [year, setYear] = useState(today.getFullYear());

	const [weekView, setWeekView] = useState([]);

	useEffect(() => {
		if (weekView.length > 0) {
			setMonth(weekView[0].$M);
			setYear(weekView[0].$y);
		}
	}, [weekView]);

	useEffect(() => {
		if (dayWithEvent.length > 0) {
			createWeekView();
		}
	}, [dayWithEvent]);

	const handleGoToToday = () => {
		setSelectedDate(today);
	};

	const renderHeader = () => {
		return (
			<div className="calendar-header">
				<h1>
					<span>{months[month]}</span> <span>{year}</span>
				</h1>
				<div 	onKeyDown={handleGoToToday} role="button" tabIndex="-1" onClick={handleGoToToday}>
					Today
				</div>
			</div>
		);
	};

	const createWeekView = () => {
		const weekViews = [];

		const dayOfWeek = dayjs(today).$W;

		weekViews.push(dayjs(today));
		const day = today.getDate();

		for (let i = 1; i <= dayOfWeek; i += 1) {
			weekViews.push(dayjs().subtract(i, "day"));
		}

		for (let j = 1; j <= weekViews.length; j += 1) {
			if (weekViews.length <= 6) {
				weekViews.push(dayjs().add(j, "day"));
			}
		}

		const sortedDays = weekViews.sort((a, b) => a.$D - b.$D);

		const hasEvent = isDayWithEvent(sortedDays);

		setWeekView(hasEvent);
	};

	const handleChangeDate = (day) => {
		if (day.hasEvent) {
			const dateSelected = new Date(day);

			setSelectedDate(dateSelected);
		}
	};

	const isDayWithEvent = (sortedDays) => {
		for (let i = 0; i <= dayWithEvent.length - 1; i += 1) {
			const d1 = new Date(dayWithEvent[i]);

			sortedDays.forEach((sort) => {
				const d2 = new Date(sort);

				if (
					d1.getDate() === d2.getDate() &&
					d1.getMonth() === d2.getMonth() &&
					d1.getFullYear() === d2.getFullYear()
				) {
					sort.hasEvent = true;
				}
			});
		}

		return sortedDays;
	};

	const renderWeekView = () => {
		return weekView.map((day) => {
			return (
				<div
					role="button"
					tabIndex="-1"
					onKeyPress={() => handleChangeDate(day)}
					key={day}
					className={`${
						dayjs(day).isSame(dayjs(selectedDate), "day") ? "selected-date" : ""
					}`}
					onClick={() => handleChangeDate(day)}
				>
					<div
						className={day.hasEvent ? "days-with-event" : "days-without-event"}
					>
						{day.$D}
					</div>
				</div>
			);
		});
	};

	const renderDay = () => {
		return weekdays.map((day) => {
			return (
				<div className="calendar-weekday" key={uuid()}>
					{day}
				</div>
			);
		});
	};

	const prevWeek = () => {
		const min = dayjs(Math.min(...weekView));

		const prevNextView = [];

		for (let i = 1; i <= 7; i += 1) {
			prevNextView.push(dayjs(min).subtract(i, "day"));
		}

		const sortedDays = prevNextView.sort((a, b) => a.$D - b.$D);

		const hasEvent = isDayWithEvent(sortedDays);

		setWeekView(hasEvent);
	};

	const nextWeek = () => {
		const nextWeekView = [];

		const max = dayjs(Math.max(...weekView));

		for (let i = 1; i <= 7; i += 1) {
			nextWeekView.push(dayjs(max).add(i, "day"));
		}

		const hasEvent = isDayWithEvent(nextWeekView);

		setWeekView(hasEvent);
	};

	return (
		<div className="calendar-wrapper">
			<IconArrowLeft onClick={prevWeek} className="arrow-left-agenda" />

			<div>{renderHeader()}</div>

			<div className="agenda-grid">
				{renderDay()}
				{renderWeekView()}
			</div>

			<IconArrowRight onClick={nextWeek} className="arrow-right-agenda" />
		</div>
	);
}

Calendar.propTypes = {
	today: instanceOf(Date),
	dayWithEvent: array,
	setSelectedDate: func,
	selectedDate: instanceOf(Date),
};

Calendar.defaultProps = {
	today: new Date(),
	setSelectedDate: () => {},
	dayWithEvent: [],
	selectedDate: new Date(),
};

export default Calendar;
