import { createSlice } from '@reduxjs/toolkit';
import { jsonWordpressDataApi } from 'api';
import { REACT_APP_DEFAULT_LANG } from 'Constants';
import { createDebouncedAsyncAction } from 'utils/reduxhelpers';
/**
* Wordpress fetching
*/

/**
*Posts
*/

export const fetchWPContentData = createDebouncedAsyncAction(
	'wpcontent/getData',
	(data, { getState }) => {
		const { lang } = getState().env;
		const url = `${data.postType}/?slug=${data.postSlug}`;
		return jsonWordpressDataApi(lang === REACT_APP_DEFAULT_LANG ? url : `${url}&lang=${lang}`);
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				wpNotifContent: {
					...action.payload?.[0],
				},
			};
		},
	}
);

/**
*Activities
*/

export const fetchWPActivities = createDebouncedAsyncAction(
	'wpcontent/fetchActivities',
	(data) => {
		const url = 'events?per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				activities: [
					...state.activities,
					...action.payload,
				],
			};
		},
	}
);

/**
*ACF Options
*/

export const fetchWPAcfPageOptions = createDebouncedAsyncAction(
	'wpcontent/fetchAcfPageOptions',
	(data) => {
		const url = 'pages?per_page=100';
		return jsonWordpressDataApi(`${url}&lang=${data.playerGroup}`);
	},
	{
		fulfilled: (state, action) => {
			const newAcfArrayObject = {};
			newAcfArrayObject.acf = {};
			
			action.payload.forEach(function(item, index) {
				Object.assign(newAcfArrayObject.acf, item.acf); 
			});

			return {
				...state,
				acfPageOptions: newAcfArrayObject,
			};
		},
	}
);


export const fetchWPNavigation = createDebouncedAsyncAction(
	'wpcontent/fetchWPNavigation',
	(data) => {
		const url = 'nav?per_page=100';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				navigation: action.payload[0],
			};
		},
	}
);

const wpcontent = createSlice({
	name: 'wpcontent',
	reducers: {
		setActivityFooterContent: (state, action) => {
			return {
				...state,
				activityFooterContent: action.payload,
			};
		},
		clearActivityFooterContent: (state) => {
			return {
				...state,
				activityFooterContent: {},
			};
		},
	},
	extraReducers: {
		...fetchWPContentData.reducers,
		...fetchWPAcfPageOptions.reducers,
		...fetchWPActivities.reducers,
		...fetchWPNavigation.reducers,
	},
	initialState: {
		activities: [],
		acfPageOptions: {},
		wpNotifContent: {},
		activityFooterContent: null,
		navigation:[],
	},
});

export default wpcontent;

export const { setActivityFooterContent, clearActivityFooterContent } = wpcontent.actions;
