/* eslint-disable react/jsx-no-useless-fragment */
import React, {
	memo,
	useRef,
	useState,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import * as MicrosoftTeams from '@microsoft/teams-js';
import { NOTIFICATION_TYPES_MAP, ROUTES, WP_TYPES_MAP } from 'Constants';

function Notification({
	acfPageOptions,
	notification,
	addSeenNotification,
	history,
	speaker,
	consumeInventoryItem,
	setActivityFooterContent,
	authMethod,
	getRandomRooms,
	enableNotifications,
}) {
	const ref = useRef();

	const [show, setShow] = useState(true);

	function onClick() {
		setShow(false);

		setTimeout(() => {
			onClickNotification();
		}, 600);
	}

	function onClose() {
		setShow(false);
	}

	function onClickNotification() {

		if (notification.type === 'Read More') {
			setActivityFooterContent({
				post_type: WP_TYPES_MAP[notification.post_type],
				post_slug: notification.target_url,
			});
		}

		if (notification.type === 'Go to page') {
			window.open(notification.target_url);
		}

		addSeenNotification({
			id: notification.id,
		});

		consumeInventoryItem(notification.id);
	}

	function onClickTakeoverNotification() {
		setShow(false);
	}

	useEffect(() => {
		const notificationType = NOTIFICATION_TYPES_MAP[notification?.type];

		if (notification) {
			setShow(true);
			if (notificationType === 'randomroom') {
				getRandomRooms();
				setTimeout(() => {
					setShow(true);
				}, 3000);
			}
		}

		if (notification && notification.target_url && notificationType === 'takeover') {
			setTimeout(() => {
				consumeInventoryItem(notification.id);
				addSeenNotification({
					id: notification.id,
				});
				history.push(notification.target_url);
			}, 7500);
		}
	}, [notification]);

	function goToLink(url) {
		MicrosoftTeams.executeDeepLink(url);
	}

	//Render notification based on type
	function renderNotification() {

		if (!notification) return null;
		const notificationType = NOTIFICATION_TYPES_MAP[notification.type];

		if (!enableNotifications) return null;

		if (enableNotifications && enableNotifications?.Value === 'false') return null;

		//TODO: Handle WP notification only when on the activity page
		if (notificationType === 'wpcontent') {
			//If no speaker is found return null for now since we only support speakers
			if (!speaker) return null;

			return (
				<div ref={ref} tabIndex="-1" role="button" onKeyPress={onClick} className="notification -style-3" onClick={onClick}>
					<div className="notif-content">
						{ speaker.acf?.cutoutpicture ? <img src={speaker.acf?.cutoutpicture} alt="" /> : '' }
						<div className="presented-by"><span>Presented By</span></div>
						<div className="name">
							<div className="first-name">{notification.firstName}</div>
							<div className="last-name">{notification.lastName}</div>
						</div>
						{notification.cta && <div className="btn-wrapper"><span className="btn">{notification.cta}</span></div>}
					</div>

				</div>
			);
		}

		if (notificationType === 'reminder') {
			return (
				<div ref={ref} tabIndex="-1" role="button" onKeyPress={onClick} className={`notification -style-1 ${!notification.icon ? "has-icon" : ""}`} onClick={onClick}>

					{!notification.icon ?
						<div className="icon-container">
							<img src={acfPageOptions.acf?.theme?.icon?.general?.notification?.sizes?.thumbnail} className="icon" alt="" />
						</div>
						: <div className="icon" />}

					{notification.title && (
						<div className="notification-content">
							<div className="title">{notification.title}</div>
							{notification.content && <div className="text">{notification.content}</div>}
						</div>
					)}
					{notification.cta && <div className="btn"><span>{notification.cta}</span></div>}
				</div>
			);
		}

		if (notificationType === 'redirect') {
			return (
				<div ref={ref} className={`notification -style-1 -redirect ${!notification.icon ? "has-icon" : ""}`}>
					<div className="close" tabIndex="-1" role="button" onKeyPress={onClose} onClick={onClose}>
						<img src={acfPageOptions.acf?.theme?.icon?.general?.exit?.image2?.sizes?.thumbnail} alt="" />
					</div>
					{!notification.icon ?
						<div className="icon-container">
							<img src={acfPageOptions.acf?.theme?.icon?.general?.notification?.sizes?.thumbnail} className="icon" alt="" />
						</div>
						: <div className="icon" />}

					{notification.title && (
						<div className="notification-content">
							<div className="title">{notification.title}</div>
							{notification.content && <div className="text">{notification.content}</div>}
						</div>
					)}
					{notification.cta && <div className="btn" tabIndex="-1" role="button" onKeyPress={onClick} onClick={onClick} to={notification.target_url}><span>{notification.cta}</span></div>}
				</div>
			);
		}

		if (notificationType === 'takeover') {
			return (
				<div ref={ref} className="notification -style-takeover">
					{notification.title && (
						<div className="notif-content" tabIndex="-1" role="button" onKeyPress={onClickTakeoverNotification} onClick={onClickTakeoverNotification}>
							<div className="title">{notification.title}</div>
							{notification.content && <div className="text">{notification.content}</div>}
							{notification.cta && <span className="button--isi tile-button-join"><span>{notification.cta}</span></span>}
						</div>
					)}
				</div>
			);
		}

		if (notificationType === 'virtualstudio') {
			return (
				<div ref={ref} className={`notification -style-1 ${!notification.icon ? "has-icon" : ""}`}>
					<div className="close" tabIndex="-1" role="button" onKeyPress={onClose} onClick={onClose}>
						<img src={acfPageOptions.acf?.theme?.icon?.general?.exit?.image2?.sizes?.thumbnail} alt="" />
					</div>
					
					{!notification.icon ?
						<div className="icon-container">
							<img src={acfPageOptions.acf?.theme?.icon?.general?.notification?.sizes?.thumbnail} className="icon" alt="" />
						</div>
						: <div className="icon" />}

					{notification.title && (
						<div className="notification-content">
							<div className="title">{notification.title}</div>
							{notification.content && <div className="text">{notification.content}</div>}
						</div>
					)}
					{notification.cta && (authMethod === 'TeamsLogIn' ? <button type="button" className="btn" onClick={() => goToLink(notification.target_url)}><span>{notification.cta}</span></button>
						: <a className="btn" href={notification.target_url} target="_blank" rel="noreferrer"><span>{notification.cta}</span></a>)}
				</div>
			);
		}

		if (notificationType === 'randomroom') {
			return (
				<div ref={ref} tabIndex="-1" role="button" onKeyPress={onClick} className={`notification -style-1 ${!notification.icon ? "has-icon" : ""}`} onClick={onClick}>
					<div className="close" tabIndex="-1" role="button" onKeyPress={onClose} onClick={onClose}>
						<img src={acfPageOptions.acf?.theme?.icon?.general?.exit?.image2?.sizes?.thumbnail} alt="" />
					</div>
					
					{!notification.icon ?
						<div className="icon-container">
							<img src={acfPageOptions.acf?.theme?.icon?.general?.notification?.sizes?.thumbnail} className="icon" alt="" />
						</div>
						: <div className="icon" />}

					{notification.title && (
						<div className="notification-content">
							<div className="title">{notification.title}</div>
							{notification.content && <div className="text">{notification.content}</div>}
						</div>
					)}
					{notification.link_to_room
						&& (
							<Link to={ROUTES.RANDOM_ROOM_SINGLE.replace(':roomId', notification.link_to_room)} className="button--isi tile-button tile-button-join">
								<span>{notification.cta}</span>
							</Link>
						)}
				</div>
			);
		}

		return (null);
	}

	return (
		<>
			{ notification ? (
				<CSSTransition in={show} timeout={600} unmountOnExit appear nodeRef={ref}>
					{ renderNotification }
				</CSSTransition>
			) : ''}
		</>
	);
}

Notification.defaultProps = {
	acfPageOptions: null,
	notification: null,
	speaker: null,
	addSeenNotification: () => {},
	consumeInventoryItem: () => {},
	setActivityFooterContent: () => {},
	getRandomRooms: () => {},
	authMethod: null,
	enableNotifications: null,
};

Notification.propTypes = {
	acfPageOptions: PropTypes.object,
	notification: PropTypes.object,
	speaker: PropTypes.object,
	addSeenNotification: PropTypes.func,
	consumeInventoryItem: PropTypes.func,
	history: PropTypes.object.isRequired,
	setActivityFooterContent: PropTypes.func,
	authMethod: PropTypes.string,
	getRandomRooms: PropTypes.func,
	enableNotifications: PropTypes.string,
};

export default memo(Notification);
