import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import PropTypes from 'prop-types';
import anime from 'animejs';

export default function Emoji({
	delay,
	name,
	width,
	negativeEndY,
	id,
	duration,
	completedCallback,
}) {
	let animation = null;
	const state = useStore().getState();

	function renderEmoji() {
		const emoji = state.wpcontent?.acfPageOptions?.acf?.theme?.emojis[name];
		return <img src={emoji.image?.sizes?.thumbnail} alt={emoji.name} />;
	}

	useEffect(() => {
		animate();
	}, []);

	const animate = () => {
		animation = anime({
			targets: `.${id}`,
			translateX() {
				return [anime.random(0, width), anime.random(0, width)];
			},
			translateY() { return negativeEndY - 200; },
			scale() { return anime.random(2, 4); },
			rotate() { return anime.random(-45, 45); },
			opacity() { return anime.random(0.8, 1); },
			duration,
			easing: 'linear',
			loop: false,
			delay: anime.stagger(delay),
			complete: animationComplete,
		});

		//TODO: Does this do anything?
		animation.suspendWhenDocumentHidden = true;
	};

	const animationComplete = () => {
		completedCallback(id);
	};

	return (
		<div>
			{renderEmoji()}
		</div>
	);
}

Emoji.defaultProps = {
	delay: 0,
	name: '',
	width: 0,
	negativeEndY: 0,
	id: '',
	duration: 0,
	completedCallback: () => { },
};

Emoji.propTypes = {
	delay: PropTypes.number,
	name: PropTypes.string,
	width: PropTypes.number,
	negativeEndY: PropTypes.number,
	id: PropTypes.string,
	duration: PropTypes.number,
	completedCallback: PropTypes.func,
};
