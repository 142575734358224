import { createSelector } from '@reduxjs/toolkit';
import { playerLangGroupSelector } from 'selector/player';
import { toLocalTime } from 'utils/dateUtils';

const storeLoadoutSelector = (state) => state.playfab?.StoreLoadout;
const expertTilePropSelector = (state, props) => props.customPayloadKey;

export const expertTileSelector = createSelector([storeLoadoutSelector], (storeLoadout) => {
	if (!storeLoadout) return null;

	const expertLoadout = storeLoadout.find((store) => store.name.toLowerCase().includes('appointment'));

	if (!expertLoadout) return [];

	return expertLoadout.tiles.filter((tile) => tile.purchaseCount === 0 && (toLocalTime(tile.customPayload.date_begin).$d >= toLocalTime(Date.now()).$d)).sort(function(
		a,
		b
	) {
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
	});
});

export const expertTileTopicSelector = createSelector(
	[expertTileSelector, expertTilePropSelector, playerLangGroupSelector],
	(expertTiles, expertProp) => {
		if (!expertTiles) return null;

		const results = [];
		expertTiles.map((tile) => {
			const topic = { value: tile.customPayload[expertProp], label: tile.customPayload[expertProp] };
			results.push(topic);
			return null;
		});
		return [...new Map(results.map((item) => [item['value'], item])).values()];
	}
);
