/** @format */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import HomeHeader from "mobile/components/containers/home/HomeHeader";
import SessionCard from "mobile/components/containers/session/SessionCard";
import LoadingInnerContent from "mobile/components/containers/LoadingInnerContent";
import { ROUTES } from "Constants";
import { now } from "utils/dateUtils";
import logPageView from "utils/logPageView";
import Button from "mobile/components/buttons/Button";
import Modal from "mobile/components/modals";
import { Link } from "react-router-dom";

function index({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	acfPageOptions,
	history,
	eventTiles,
	isDesktop,
}) {
	const [showModal, setShowModal] = useState(true);
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	useEffect(() => {
		setShowModal(!localStorage.getItem("seen_intro_modal"));
	}, []);

	useEffect(() => {
		history.push("/");
		if (playerStatistics)
			logPageView(
				"home",
				playerStatistics,
				playfabId,
				playerData,
				leaderboardOnUserPosition[0]?.Position,
				null
			);
	}, []);

	function onTimeUpdate() {
		getPercentage();
	}

	function getPercentage() {
		const myPlayer = document.querySelector(".videoModal");

		totalLength = myPlayer.duration;
		percentageCompleted = (myPlayer.currentTime / totalLength) * 100;

		// is 0
		if (!videoStarted && percentageCompleted > 0) {
			videoStarted = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "play",
				eventLabel: "Welcome Modal",
			});
		}
		// is 25
		if (!videoTwentyFive && percentageCompleted > 25) {
			videoTwentyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "25%",
				eventLabel: "Welcome Modal",
			});
		}
		// is 50
		if (!videoFifty && percentageCompleted > 50) {
			videoFifty = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "50%",
				eventLabel: "Welcome Modal",
			});
		}
		// is 75
		if (!videoSeventyFive && percentageCompleted > 75) {
			videoSeventyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "75%",
				eventLabel: "Welcome Modal",
			});
		}

		// is 90
		if (!videoNinety && percentageCompleted > 90) {
			videoNinety = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "90%",
				eventLabel: "Welcome Modal",
			});
		}

		// is 100
		if (!videoComplete && percentageCompleted > 99) {
			videoComplete = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "100%",
				eventLabel: "Welcome Modal",
			});
		}
	}

	function closeModal() {
		localStorage.setItem("seen_intro_modal", true);
		setShowModal(false);
	}

	function renderModal() {
		const nodeRef = React.useRef(null);
		return (
			<CSSTransition
				in={showModal}
				timeout={600}
				unmountOnExit
				nodeRef={nodeRef}
			>
				<Modal
					modal={showModal}
					setModal={() => closeModal()}
					className="welcome-modal"
				>
					<div className="content">
						<div className="text">
							<h3>{acfPageOptions.acf?.welcome_modal?.title}</h3>
							<p>{acfPageOptions.acf?.welcome_modal?.description}</p>
						</div>
						{acfPageOptions.acf?.welcome_modal?.video_link && (
							<div className="video">
								<video
									className="videoModal"
									src={acfPageOptions.acf?.welcome_modal?.video_link}
									controls
									onTimeUpdate={onTimeUpdate}
								/>
							</div>
						)}
					</div>
				</Modal>
			</CSSTransition>
		);
	}

	function displayEvents() {
		const homePageEvents = eventTiles
			.filter(
				(tile) =>
					tile?.activity?.data?.activity_type !== "DealersLounge" &&
					tile?.activity?.data?.activity_type !== "MeetingRoom" &&
					tile?.activity.date_begin.isSame(now(), "day") &&
					!tile?.activity.date_end.isBefore(now())
			)
			.slice(0, acfPageOptions.acf?.home?.number_of_events)
			.map((tile) => {
				return (
					<SwiperSlide key={tile.id}>
						<SessionCard
							key={tile.id}
							id={tile.id}
							stock={tile.stock}
							activity={tile.activity}
							link={acfPageOptions.acf?.home?.link_to_event_page}
							className="is-happening"
							isDesktop={isDesktop}
						/>
					</SwiperSlide>
				);
			});

		if (homePageEvents.length === 0)
			return (
				<div className="p-4 m-live-agenda">
					<h4 className="agenda-title">
						{acfPageOptions.acf?.home?.live_events_section_title}
					</h4>
					<div className="live-events">
						<div className="p-4 m-session-card agenda-activity-card no-events">
							<h4 className="title">
								{acfPageOptions.acf?.home?.end_of_day_title}
							</h4>

							<Button
								link={ROUTES.AGENDA}
								classes="primary medium"
								text={acfPageOptions.acf?.home?.end_of_day_button}
							/>
						</div>
					</div>
				</div>
			);
		return (
			<div className="p-4 m-live-agenda">
				<h4 className="agenda-title">
					{acfPageOptions.acf?.home?.live_events_section_title}
				</h4>
				<div className="live-events">
					<Swiper
						breakpoints={{
							375: {
								slidesPerView: 1,
							},
							450: {
								slidesPerView: 2,
							},
							667: {
								slidesPerView: 3,
							},
						}}
						className="myHomeSwiper"
					>
						{homePageEvents}
					</Swiper>
					<Link to={ROUTES.AGENDA} className="agenda-link">
						<span>{acfPageOptions.acf?.home?.all_live_events}</span>
					</Link>
				</div>
			</div>
		);
	}

	function displayMeetings() {
		const homePageMeetings = eventTiles
			.filter(
				(tile) =>
					tile?.activity?.data?.activity_type !== "DealersLounge" &&
					tile?.activity?.data?.activity_type !== "Default" &&
					tile?.activity.date_begin.isSame(now(), "day") &&
					!tile?.activity.date_end.isBefore(now())
			)
			.slice(0, acfPageOptions.acf?.home?.number_of_events)
			.map((tile) => {
				return (
					<SwiperSlide key={tile.id}>
						<SessionCard
							key={tile.id}
							id={tile.id}
							stock={tile.stock}
							activity={tile.activity}
							link={acfPageOptions.acf?.home?.link_to_event_page}
							className="is-happening"
						/>
					</SwiperSlide>
				);
			});

		if (homePageMeetings.length === 0)
			return (
				<div className="p-4 pt-3 m-live-agenda">
					<h4 className="agenda-title">
						{acfPageOptions.acf?.home?.meeting_rooms_section_title}
					</h4>
					<div className="live-events">
						<div className="p-4 m-session-card agenda-activity-card no-events">
							<h4 className="title">
								{acfPageOptions.acf?.home?.end_of_day_title}
							</h4>

							<Button
								link={ROUTES.AGENDA}
								classes="primary medium"
								text={acfPageOptions.acf?.home?.end_of_day_button}
							/>
						</div>
					</div>
				</div>
			);
		return (
			<div className="p-4 pt-3 m-live-agenda">
				<h4 className="agenda-title">
					{acfPageOptions.acf?.home?.meeting_rooms_section_title}
				</h4>
				<div className="live-events">
					<Swiper
						breakpoints={{
							375: {
								width: 345,
								slidesPerView: 1.5,
							},
							667: {
								width: 575,
								slidesPerView: 2.5,
							},
						}}
						className="myHomeSwiper"
					>
						{homePageMeetings}
					</Swiper>
					<Link to={ROUTES.AGENDA} className="agenda-link">
						<span>{acfPageOptions.acf?.home?.all_meetings_rooms}</span>
					</Link>
				</div>
			</div>
		);
	}

	return (
		<LoadingInnerContent
			isLoading={
				acfPageOptions?.acf?.home === null ||
				acfPageOptions?.acf?.home?.length === 0
			}
			className="loadingWrapper"
		>
			{renderModal()}
			<div className={`text-white ${isDesktop ? "d-homepage" : "m-homepage"}`}>
				<HomeHeader isDesktop={isDesktop} />
				<div className="homepage-content">
					<div className="m-home-content">
						<div className="col-lg-12">
							<h1 className="title-ctn text-uppercase">
								{acfPageOptions.acf?.home?.main_title &&
									parse(acfPageOptions.acf?.home?.main_title)}
							</h1>
							<div className="subtitle text-uppercase">
								{acfPageOptions.acf?.home?.subtitle &&
									parse(acfPageOptions.acf?.home?.subtitle)}
							</div>
							<div className="description">
								{acfPageOptions.acf?.home?.description &&
									parse(acfPageOptions.acf?.home?.description)}
							</div>
						</div>
					</div>
					{eventTiles &&
						eventTiles.length > 0 &&
						acfPageOptions.acf?.home?.number_of_events &&
						displayEvents()}

					{eventTiles &&
						eventTiles.length > 0 &&
						acfPageOptions.acf?.home?.number_of_events &&
						displayMeetings()}
				</div>
			</div>
		</LoadingInnerContent>
	);
}

index.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	acfPageOptions: null,
	lang: "en",
	myAgendaTiles: [],
	eventTiles: [],
	windowSize: null,
	isDesktop: false,
};

index.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	acfPageOptions: PropTypes.object,
	lang: PropTypes.string,
	myAgendaTiles: PropTypes.array,
	eventTiles: PropTypes.array,
	history: PropTypes.object.isRequired,
	windowSize: PropTypes.number,
	isDesktop: PropTypes.bool,
};

export default index;
