import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/fr';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
	relativeTime: {
	  future: "%s",
	  past: "%s",
	  s: 'a few sec',
	  m: "a min",
	  mm: "%d min",
	  h: "h",
	  hh: "%d h",
	  d: "a day",
	  dd: "%d days",
	  M: "a month",
	  MM: "%d months",
	  y: "a year",
	  yy: "%d years",
	},
})

dayjs.updateLocale('fr', {
	relativeTime: {
	  future: "%s",
	  past: "%s",
	  s: 'quelque secondes',
	  m: "une minute",
	  mm: "%d minutes",
	  h: "h",
	  hh: "%d h",
	  d: "un jour",
	  dd: "%d jours",
	  M: "un mois",
	  MM: "%d mois",
	  y: "une année",
	  yy: "%d années",
	},
})

/**
 * Converts date to UTC format and back to local
 * to ensure that all dates are compared from the UTC timezone
 * @param {Date|String|dayjs.Dayjs} date
 */
export const toLocalTime = (date) => {
	return dayjs.utc(date).local();
};

export const toUTC = (date) => {
	return dayjs.tz(date, 'UTC');
};

export const toUnix = (date) => {
	return dayjs.utc(date).unix();
};

export const now = () => toLocalTime(dayjs());

export const timeFromNow = (date) => date.fromNow();

//Formating
export const getTheHour = (date) => date.format('h');
export const getTheMinutes = (date) => date.format('mm');
export const getTheDay = (date) => date.format('Do');
export const getThePM = (date) => date.format('a');
export const getWeekday = (date) => date.format('dddd');
export const getMonthName = (date) => date.format('MMMM');
//TODO: Remove with the new agenda merged
// export const getDayTwoDigits = (date) => date.format('DD');
export const getDayShort = (date) => date.format('ddd');

//toLocaleString functions
export const getThe24hHour = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		hour: 'numeric',
		hour12: false,
		minute: 'numeric',
	});
export const getHourTwoDigits = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		hour: '2-digit',
	});
export const getHourNumeric = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		hour: 'numeric',
	});
export const getMinuteTwoDigits = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		minute: '2-digit',
	});
export const getMinuteNumeric = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		minute: 'numeric',
	});
export const getDayTwoDigits = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		day: '2-digit',
	});
export const getDayNumeric = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		day: 'numeric',
	});
export const getMonthTwoDigits = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		month: '2-digit',
	});
export const getMonthLong = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		month: 'long',
	});
export const getMonthNarrow = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		month: 'narrow',
	});
export const getMonthNumeric = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		month: 'numeric',
	});
export const getMonthShort = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		month: 'short',
	});
export const getWeekdayLong = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		weekday: 'long',
	});
export const getWeekdayShort = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		weekday: 'short',
	});
export const getWeekdayNarrow = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		weekday: 'narrow',
	});
export const getTimeStyleFull = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		timeStyle: 'full',
	});
export const getTimeStyleLong = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		timeStyle: 'long',
	});
export const getTimeStyleMedium = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		timeStyle: 'medium',
	});
export const getTimeStyleShort = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		timeStyle: 'short',
	});
export const getDateStyleFull = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		dateStyle: 'full',
	});
export const getDateStyleLong = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		dateStyle: 'long',
	});
export const getDateStyleMedium = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		dateStyle: 'medium',
	});
export const getDateStyleShort = (initialDate, lang) =>
	initialDate.toLocaleString(lang, {
		dateStyle: 'short',
	});
