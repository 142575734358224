/* eslint-disable react/jsx-props-no-spreading */
import React, {
	memo,
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import LoadingInnerContent from 'containers/LoadingInnerContent';
import ActivityCard from 'containers/AgendaActivityCard';
import DetailsDialog from 'components/Events/DetailsDialog';
import { now,
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayShort,
	getMonthShort,
} from 'utils/dateUtils';
import logPageView from 'utils/logPageView';
import { POLL_RATES } from 'Constants';

const noop = () => { };

/*
* Agenda Page displaying the event tiles for each day and each channel
*/

function Agenda({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	channels,
	days,
	activeDay,
	activeChannel,
	agendaHours,
	agendaTracks,
	allTracks,
	changePollingRate,
	setActiveChannel,
	setActiveDay,
	setActiveTile,
	setActiveTilePosition,
	acfPageOptions,
	groupLang,
}) {
	const scheduleDivRef = useRef(null);
	const agendaHeaderRef = useRef(null);
	const [hasScrolled, setHasScrolled] = useState(false);
	const [isAllSessionsActive, setIsAllSessionsActive] = useState(false);

	function scrollToHour(hour) {
		if (scheduleDivRef.current && agendaHeaderRef.current && !hasScrolled) {
			window.scrollTo({
				top: (hour / 24) * document.body.scrollHeight - agendaHeaderRef.current.clientHeight,
				left: 0,
				behavior: 'smooth',
			});
			setHasScrolled(true);
		}
	}

	const activateAllSessions = () => {
		setIsAllSessionsActive(true);
		setActiveChannel(null);
	};

	useEffect(() => {
		if (!agendaTracks) {
			return null;
		}
		if (agendaTracks && agendaTracks.length > 0 && !hasScrolled) {
			if (agendaTracks[0].tiles?.length > 0) {
				const tracksSortedBySmallerElements = agendaTracks[0].tiles.sort((track1, track2) => {
					return (track1.timeSlotStart - track2.timeSlotStart);
				});
				return scrollToHour(Math.trunc(Math.abs(tracksSortedBySmallerElements[0]?.timeSlotStart) / 4));
			}
		}
		return null;
	}, [agendaTracks, hasScrolled, groupLang]);

	useEffect(() => {
		if (!allTracks) {
			return null;
		}
		if (!agendaTracks && allTracks && allTracks.length > 0 && !hasScrolled) {
			if (allTracks[0].tiles?.length > 0) {
				const tracksSortedBySmallerElements = allTracks[0].tiles.sort((track1, track2) => {
					return (track1.timeSlotStart - track2.timeSlotStart);
				});
				return scrollToHour(Math.trunc(Math.abs(tracksSortedBySmallerElements[0]?.timeSlotStart) / 4));
			}
		}
		return null;
	}, [allTracks, hasScrolled]);

	useEffect(() => {
		//Log PageView
		logPageView('agenda', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	const displaySessionSlot = (tile, trackIdx, size) => {
		const isHappeningClass = tile.activity.isHappening ? 'is-happening' : null;

		return (
			groupLang && (
				<div
					className={`
					session-slot
					span-${tile.timeSlotEnd - tile.timeSlotStart} ${tile.timeSlotEnd - tile.timeSlotStart > 0 && tile.timeSlotEnd - tile.timeSlotStart <= 3 ? 'small-event' : ''}
					${tile.timeSlotEnd - tile.timeSlotStart > 0 && tile.timeSlotEnd - tile.timeSlotStart <= 1 ? 'short-event' : ''}
					`}
					key={tile.id}
					style={{
						gridRowStart: `${tile.timeSlotStart || 1}`,
						gridRowEnd: `${tile.timeSlotEnd} `,
					}}>
					{acfPageOptions.acf?.events?.show_details_dialog ?
						<DetailsDialog
							direction={size === 1 ? "center" : (trackIdx + 1) / size > 0.5 ? "left" : "right"}
							closeIcon={acfPageOptions.acf?.theme?.icon?.general?.exit?.image2?.sizes?.thumbnail}
							link_to_event_page={acfPageOptions.acf?.events?.link_to_event_page}
							child={
								<ActivityCard
									onMouseEnter={() => { setActiveTile(tile.id); return setActiveTilePosition(trackIdx + 1); }}
									onMouseLeave={() => { setActiveTile(null); return setActiveTilePosition(null); }}
									key={tile.id}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...tile}
									className={`span-${tile.timeSlotEnd - tile.timeSlotStart} ${isHappeningClass}`}
								/>
							}
							details={tile}
						/>
						:
						<ActivityCard
							onMouseEnter={() => { setActiveTile(tile.id); return setActiveTilePosition(trackIdx + 1); }}
							onMouseLeave={() => { setActiveTile(null); return setActiveTilePosition(null); }}
							key={tile.id}
							link={acfPageOptions.acf?.events?.link_to_event_page}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...tile}
							className={`span-${tile.timeSlotEnd - tile.timeSlotStart} ${isHappeningClass}`}
						/>
					}
				</div>
			)
		);
	};

	useEffect(() => {
		changePollingRate({
			key: POLL_RATES.GETSTORELOADOUT,
			rate: 60 * 1000,
		});

		return function cleanup() {
			changePollingRate({
				key: POLL_RATES.GETSTORELOADOUT,
				rate: 0,
			});
		};
	}, []);

	useEffect(() => {
		if (channels && channels.length && !activeChannel) {
			// setActiveChannel(channels[0].id);
			setIsAllSessionsActive(true);
		}
	}, [channels]);

	useEffect(() => {
		if (days && days.length && !activeDay) {
			const today = now();
			const todayInDays = days.find(day => day.isSame(today, 'day'));

			setActiveDay(todayInDays || days[0]);
		}
	}, [days]);

	useEffect(() => {
		if (activeChannel) {
			setIsAllSessionsActive(false);
			setHasScrolled(false);
		}
	}, [activeChannel]);

	useEffect(() => {
		if (activeDay) {
			setHasScrolled(false);
		}
	}, [activeDay]);

	useEffect(() => {
		if (isAllSessionsActive) {
			setHasScrolled(false);
		}
	}, [isAllSessionsActive]);

	const tracksClass = `tracks-${(agendaTracks && agendaTracks.length) || (allTracks && allTracks.length)}`;

	return (
		<LoadingInnerContent isLoading={!channels} className="loadingWrapper">
			<div className="agenda-page page-wrapper">
				<div className="sidenav-left-column" />
				<div className="content-column">
					<div className="background" style={{backgroundImage: acfPageOptions?.acf?.events?.background.url ? `url(${acfPageOptions?.acf?.events?.background.url})` : null}} />
					<div className="site-wrapper">
						<div className="agenda-header fixed" ref={agendaHeaderRef}>
							<h3 className="page-title">{acfPageOptions.acf?.events?.title}</h3>
							{channels && channels.length > 1 &&
								<div className="channel-list">
									<div
										className={`channel-list-item ${isAllSessionsActive ? 'active' : ''}`}
										key="allSessions"
										role="button"
										tabIndex="-1"
										onKeyPress={noop}
										onClick={() => {
											activateAllSessions();
										}}
									>
										{acfPageOptions?.acf?.navigation_events?.all_sessions_channel_label}
									</div>
									{channels && channels.map((channel) => {
										const isActive = channel.id === activeChannel;
										return (
											<div
												className={`channel-list-item ${isActive ? 'active' : ''}`}
												key={channel.id}
												role="button"
												tabIndex="-1"
												onKeyPress={noop}
												onClick={() => {
													setActiveChannel(channel.id);
												}}
											>
												{channel.wp_data?.title?.rendered}
											</div>
										);
									})}
								</div>
							}

							<div className="days-list">
								{
									days && days.map(date => {
										const isActive = date.isSame(activeDay);
										return (
											<div
												className={`days-list-item ${isActive ? 'active' : ''}`}
												key={date}
												role="button"
												tabIndex="-1"
												onKeyPress={noop}
												onClick={() => { setActiveDay(date); }}
											>
												<div className="day-num">{getDayTwoDigits(date.$d, groupLang)}</div>
												<div className="day-month">
													<div>{getWeekdayShort(date.$d, groupLang)}</div>
													<div>{getMonthShort(date.$d, groupLang)}</div>
												</div>
											</div>
										);
									})
								}
							</div>
						</div>

						<div className="schedule-container">
							<div className="time-slot-grid">
								{
									agendaHours && agendaHours.map(timeSlot => (
										<div className="time-slot" key={timeSlot}>{getTimeStyleShort(timeSlot.$d, 'en-US')}</div>
									))
								}
							</div>
							<div className={`schedule ${tracksClass}`} ref={scheduleDivRef}>
								{
									agendaTracks && agendaTracks.map((track, trackIdx) => {

										return track.tiles.map(tile => {
											if (agendaTracks.length < 6) {
												return displaySessionSlot(tile, trackIdx, agendaTracks.length);
											}
											return displaySessionSlot(tile, trackIdx, agendaTracks.length);
										});
									})
								}
								{
									!agendaTracks && allTracks && isAllSessionsActive && allTracks.map((track, trackIdx) => {
										return track.tiles.map((tile) => {
											if (allTracks.length < 6) {
												return displaySessionSlot(tile, trackIdx, allTracks.length);
											}
											return displaySessionSlot(tile, trackIdx, allTracks.length);
										});
									})
								}
							</div>
						</div>

					</div>
				</div>
			</div>
		</LoadingInnerContent>
	);
}

Agenda.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	channels: [],
	days: [],
	activeDay: null,
	activeChannel: null,
	activeTile: null,
	activeTilePosition: null,
	agendaHours: null,
	agendaTracks: null,
	allTracks: null,
	acfPageOptions: null,
	groupLang: null,
};

Agenda.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	channels: PropTypes.array,
	days: PropTypes.array,
	activeDay: PropTypes.string,
	activeChannel: PropTypes.number,
	activeTile: PropTypes.string,
	activeTilePosition: PropTypes.number,
	agendaHours: PropTypes.array,
	agendaTracks: PropTypes.array,
	allTracks: PropTypes.array,
	acfPageOptions: PropTypes.object,
	setActiveDay: PropTypes.func.isRequired,
	setActiveChannel: PropTypes.func.isRequired,
	setActiveTile: PropTypes.func.isRequired,
	setActiveTilePosition: PropTypes.func.isRequired,
	changePollingRate: PropTypes.func.isRequired,
	groupLang: PropTypes.string,
};

export default memo(Agenda);