/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDifference } from "utils/compareArrays";
import {
	POLL_RATES,
	BUILD_DATETIME,
	UPDATE_PLAYER_DATA_DELAY,
} from "Constants";
import GA from "GA";
import { averageMsCalls, maxMsCalls, numberOfCalls } from "middleware/tracker";

function Playfab({
	teamsUserObjectId,
	userJWT,
	authTeamsPlayfab,
	authPlayfabJWT,
	getLeaderboardAroundCurrentUser,
	getPlayerProfile,
	getPlayerData,
	getPlayerReadOnlyData,
	getPlayerStatistics,
	getItemInventory,
	getCatalog,
	getStoreLoadout,
	getEventsInventory,
	setDataLoaded,
	setAuthStep,
	setChatAuth,
	fetchWPActivities,
	fetchWPAcfPageOptions,
	fetchWPMobileTheme,
	playerId,
	playerChatAuth,
	children,
	pollRates,
	writeTelemetryEvent,
	isAccountCompleted,
	isAccountDeclined,
	IsInvalidLoginJwt,
	acfPageOptions,
	getRandomRooms,
	purchaseTile,
	inventory,
	previousInventory,
	isDataLoaded,
	getPlayerStatus,
	playerStatus,
	playerGroup,
	fetchWPNavigation,
	getUsers,
	createToast,
	channel,
}) {
	useEffect(() => {
		if (
			previousInventory !== inventory &&
			previousInventory.length !== 0 &&
			inventory.length !== 0
		) {
			const newItem = getDifference(inventory, previousInventory);
			if (newItem !== null && newItem !== []) {
				const item = newItem[0];
				if (item !== null) {
					switch (item?.type?.title) {
						case "Badge":
							if (item?.playfab.Tags.includes(playerGroup)) {
								const notificationType =
									channel !== null ? "Toast" : "TakeOver";
								createToast({
									content: `${acfPageOptions?.acf?.notification?.badge_earn} ${newItem[0]?.data[4]?.dataVal}.`,
									type: notificationType,
									title: acfPageOptions?.acf?.notification?.badge_title,
									id: item.id,
									icon: newItem[0]?.data[1]?.dataVal,
									badge: true,
								});
							}
							break;

						case "Activity":
							createToast({
								content: acfPageOptions?.acf?.notification?.event_added,
								type: "Toast",
								id: item.id,
							});
							break;

						default:
					}
				}
			}
		}
	}, [previousInventory]);

	useEffect(() => {
		// POLL RATES defines the rate of which data are fetched from Playfab. Check Constants for the details. If 0, it's only called once during the second iteration.
		const polls = [
			{
				fn: getPlayerData,
				rate: pollRates[POLL_RATES.GETPLAYERDATA],
				interval: null,
			},
			{
				fn: getPlayerProfile,
				rate: pollRates[POLL_RATES.GETPLAYERPROFILE],
				interval: null,
			},
			{
				fn: getCatalog,
				rate: pollRates[POLL_RATES.GETCATALOG],
				interval: null,
			},
			{
				fn: getStoreLoadout,
				rate: pollRates[POLL_RATES.GETSTORELOADOUT],
				interval: null,
			},
			{
				fn: getEventsInventory,
				rate: pollRates[POLL_RATES.GETEVENTSINVENTORY],
				interval: null,
			},
			{
				fn: fetchWPActivities,
				rate: pollRates[POLL_RATES.FETCH_WPACTIVITIES],
				interval: null,
			},
			{
				fn: getPlayerStatus,
				rate: pollRates[POLL_RATES.GET_PLAYER_STATUS],
				interval: null,
			},
		];

		// First iteration when the playerId is not set yet
		if (!playerId) {
			const startCall = Date.now();
			let endCall = null;
			let callDuration = null;

			if (userJWT) {
				authPlayfabJWT(userJWT).then((d) => {
					endCall = Date.now();
					callDuration = endCall - startCall;
					writeTelemetryEvent({
						namespace: "custom.state",
						name: "authentication",
						body: {
							app_version: BUILD_DATETIME,
							status: d.payload.code,
							delay: callDuration,
						},
					});

					if (d.payload?.data?.LoginResult?.PlayFabId) {
						GA.setUserProperties({
							userId: d.payload.data.LoginResult.PlayFabId,
						});
					}
				});

				setTimeout(() => {
					if (!callDuration) {
						setAuthStep("jwtTimeOut");
					}
				}, 3000);
			} else {
				authTeamsPlayfab(teamsUserObjectId).then((d) => {
					endCall = Date.now();
					callDuration = endCall - startCall;
					writeTelemetryEvent({
						namespace: "custom.state",
						name: "authentication",
						body: {
							app_version: BUILD_DATETIME,
							status: d.payload.code,
							delay: callDuration,
						},
					});

					if (d.payload?.data?.LoginResult?.PlayFabId) {
						GA.setUserProperties({
							userId: d.payload.data.LoginResult.PlayFabId,
						});
					}
				});

				setTimeout(() => {
					if (!callDuration) {
						setAuthStep("teamsCustomIDTimeout");
					}
				}, 3000);
			}
		}

		const data = {
			playerGroup,
		};

		// Second iteration when the playerId is set
		if (playerId) {
			Promise.all([
				getLeaderboardAroundCurrentUser(),
				getPlayerReadOnlyData(),
				getPlayerProfile(),
				getPlayerData(),
				getPlayerStatistics(),
				getItemInventory(),
				getCatalog(),
				getStoreLoadout(),
				getEventsInventory(),
				getRandomRooms(),
				getUsers(),
			]);
		}
		if (playerGroup) {
			Promise.all([fetchWPActivities(data)]);
		}

		const hiveReport = setInterval(() => {
			writeTelemetryEvent({
				namespace: "custom.state",
				name: "hive_report",
				body: {
					CPM: Math.round(numberOfCalls(["playfab"])),
					Error: Math.round(numberOfCalls(["playfab"], ["rejected"])),
					Avg_ms: Math.round(
						averageMsCalls(["playfab"], ["rejected", "fulfilled"])
					),
					Max_ms: Math.round(maxMsCalls(["playfab"])),
				},
			});
		}, 60000);

		const signalrReport = setInterval(() => {
			writeTelemetryEvent({
				namespace: "custom.state",
				name: "signalr_report",
				body: {
					CPM: Math.round(numberOfCalls(["chat"])),
					Error: Math.round(numberOfCalls(["chat"], ["rejected"])),
					Avg_ms: Math.round(
						averageMsCalls(["chat"], ["rejected", "fulfilled"])
					),
					Max_ms: Math.round(maxMsCalls(["chat"])),
				},
			});
		}, 60000);

		const contentReport = setInterval(() => {
			writeTelemetryEvent({
				namespace: "custom.state",
				name: "content_report",
				body: {
					CPM: Math.round(numberOfCalls(["content", "wppage", "wpcontent"])),
					Error: Math.round(
						numberOfCalls(["content", "wppage", "wpcontent"], ["rejected"])
					),
					Avg_ms: Math.round(
						averageMsCalls(
							["content", "wppage", "wpcontent"],
							["rejected", "fulfilled"]
						)
					),
					Max_ms: Math.round(maxMsCalls(["content", "wppage", "wpcontent"])),
				},
			});
		}, 60000);

		// Set intervals for the calls
		polls.forEach((poll) => {
			if (poll.rate !== 0 && playerId) {
				poll.interval = setInterval(poll.fn, poll.rate);
			}
		});

		return () => {
			clearInterval(hiveReport);
			clearInterval(signalrReport);
			clearInterval(contentReport);
			polls.forEach((poll) => clearInterval(poll.interval));
		};
	}, [playerId, teamsUserObjectId, pollRates, IsInvalidLoginJwt, playerGroup]);

	useEffect(() => {
		const checkForAuth = setInterval(() => {
			if (!playerChatAuth) {
				getPlayerReadOnlyData();
			}
		}, 3000);

		if (playerChatAuth) {
			setChatAuth(true);
			clearInterval(checkForAuth);
		}

		return () => {
			clearInterval(checkForAuth);
		};
	}, [playerChatAuth]);

	useEffect(() => {
		const data = {
			playerGroup,
		};
		if (playerGroup !== -1 && playerGroup) {
			fetchWPAcfPageOptions(data)
				.then(fetchWPNavigation(data))
				.then(() => setDataLoaded(true));
			fetchWPMobileTheme(data)
				.then(fetchWPNavigation(data))
				.then(() => setDataLoaded(true));
		}
		const fetchNavigationData = setInterval(() => {
			fetchWPNavigation(data);
		}, 300000);

		return () => {
			clearInterval(fetchNavigationData);
		};
	}, [playerGroup]);

	function purchase(id) {
		purchaseTile(id);
	}

	useEffect(() => {
		if (!inventory || !isDataLoaded) {
			return;
		}
		if (isDataLoaded === true && acfPageOptions.acf?.global?.granted_items) {
			acfPageOptions.acf?.global?.granted_items?.map((id) => {
				if (!isInInventory(id.tile_id)) {
					return purchase(id.tile_id);
				}
				return null;
			});
		}
	}, [isDataLoaded]);

	useEffect(() => {
		playerStatus.forEach((status) => {
			switch (status) {
				case "player_inventory_item_added":
					getItemInventory();
					break;
				case "player_statistic_changed":
					getPlayerStatistics();
					getLeaderboardAroundCurrentUser();
					break;

				default:
					break;
			}
		});
	}, [playerStatus]);

	const isInInventory = (tileId) => {
		if (!inventory) {
			return null;
		}
		return inventory.some((inventoryItem) => {
			return inventoryItem?.playfab?.InstanceData?.StoreTileId === tileId;
		});
	};

	//More control to debug flow
	function renderChildren() {
		if (!playerId) {
			return null;
		}

		if (!isAccountCompleted) {
			return null;
		}

		if (!playerChatAuth) {
			return null;
		}

		if (isAccountDeclined) {
			return null;
		}

		return <>{children}</>;
	}
	return renderChildren();
}

Playfab.defaultProps = {
	teamsUserObjectId: null,
	teamsUserPrincipalName: null,
	userJWT: null,
	authTeamsPlayfab: () => {},
	authPlayfabJWT: () => {},
	AddOrUpdateContactEmail: () => {},
	getLeaderboardAroundCurrentUser: () => {},
	getPlayerProfile: () => {},
	getPlayerProfileEmail: () => {},
	getPlayerData: () => {},
	getPlayerReadOnlyData: () => {},
	getPlayerStatistics: () => {},
	getItemInventory: () => {},
	getCatalog: () => {},
	getStoreLoadout: () => {},
	setLoading: () => {},
	setAuthStep: () => {},
	setChatAuth: () => {},
	getEventsInventory: () => {},
	fetchWPActivities: () => {},
	updatePlayerData: () => {},
	fetchWPAcfPageOptions: () => {},
	fetchWPMobileTheme: () => {},
	writeTelemetryEvent: () => {},
	playerId: null,
	playerChatAuth: null,
	children: null,
	isEmailSet: null,
	isAccountCompleted: null,
	isAccountDeclined: null,
	IsInvalidLoginJwt: null,
	acfPageOptions: null,
	getRandomRooms: () => {},
	getUsers: () => {},
	purchaseTile: () => {},
	inventory: null,
	previousInventory: [],
	isDataLoaded: null,
	getPlayerStatus: () => {},
	fetchWPNavigation: () => {},
	playerStatus: [],
	playerGroup: null,
	createToast: () => {},
	channel: "",
};

Playfab.propTypes = {
	teamsUserObjectId: PropTypes.string,
	teamsUserPrincipalName: PropTypes.string,
	userJWT: PropTypes.string,
	authTeamsPlayfab: PropTypes.func,
	authPlayfabJWT: PropTypes.func,
	AddOrUpdateContactEmail: PropTypes.func,
	getLeaderboardAroundCurrentUser: PropTypes.func,
	getPlayerProfile: PropTypes.func,
	getPlayerProfileEmail: PropTypes.func,
	getPlayerData: PropTypes.func,
	getPlayerReadOnlyData: PropTypes.func,
	getPlayerStatistics: PropTypes.func,
	getItemInventory: PropTypes.func,
	getCatalog: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	setLoading: PropTypes.func,
	setAuthStep: PropTypes.func,
	setChatAuth: PropTypes.func,
	getEventsInventory: PropTypes.func,
	fetchWPActivities: PropTypes.func,
	fetchWPAcfPageOptions: PropTypes.func,
	fetchWPMobileTheme: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	updatePlayerData: PropTypes.func,
	playerId: PropTypes.string,
	playerChatAuth: PropTypes.string,
	children: PropTypes.object,
	isDataLoaded: PropTypes.bool,
	inventory: PropTypes.array,
	previousInventory: PropTypes.array,
	pollRates: PropTypes.object.isRequired,
	isEmailSet: PropTypes.number,
	isAccountCompleted: PropTypes.bool,
	isAccountDeclined: PropTypes.bool,
	IsInvalidLoginJwt: PropTypes.bool,
	acfPageOptions: PropTypes.object,
	getRandomRooms: PropTypes.func,
	getUsers: PropTypes.func,
	purchaseTile: PropTypes.func,
	getPlayerStatus: PropTypes.func,
	fetchWPNavigation: PropTypes.func,
	playerStatus: PropTypes.array,
	playerGroup: PropTypes.string,
	createToast: PropTypes.func,
	channel: PropTypes.string,
};

export default Playfab;
