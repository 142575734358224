import usePageTracking from 'utils/usePageTracking';

function PageViewTracker() {
	usePageTracking();
	
	return (
		null
	);
}

export default PageViewTracker;
