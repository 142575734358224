/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { MESSAGE_MODERATOR, MESSAGE_SYSTEM } from "redux/chat";
import { now } from "utils/dateUtils";
import Avatar from "components/Partials/Avatar";
import { PARTNERSTAT, REACT_APP_AS_STACK_APP_ID } from "Constants";

const HIDDEN_MESSAGES = [MESSAGE_SYSTEM];

function ChatMessage({
	author,
	channel,
	message,
	timestamp,
	displayName,
	pfid,
	broadcastMessage,
	getOtherPlayerData,
	isModerator,
	otherPlayerData,
	setActiveProfileCard,
	partnerName,
}) {
	let className = "default";
	if (author === "_SYSTEM_" || author === "_VIDEO_DEBUG_") {
		className = "system";
	} else if (author === displayName) {
		className = "me";
	}

	const [units, setUnits] = useState("s");
	const timeRef = useRef();
	const isSameAuthor = author === displayName;

	useEffect(() => {
		if (!otherPlayerData) {
			getOtherPlayerData(pfid);
		}
	}, []);

	useEffect(() => {
		const timer = setInterval(
			() => {
				const nowTimestamp = now().valueOf();

				const diffSecs = dayjs(nowTimestamp).diff(dayjs(timestamp), "second");
				const diffMins = dayjs(nowTimestamp).diff(dayjs(timestamp), "minute");

				const diffToShow = diffSecs <= 60 ? diffSecs + " sec" : Math.floor(diffMins) + " min";
				if (diffSecs >= 60 && units === "s") {
					setUnits("m");
				}

				if (timeRef && timeRef.current) {
					timeRef.current.innerHTML = diffToShow;
				}
			},
			units === "s" ? 1000 : 60000
		);

		return () => {
			clearInterval(timer);
		};
	}, [units, timestamp]);

	//TODO: change this in hive call
	function onModeratorAction(action) {
		const payload = {
			original_author: author,
			original_message: message,
			action,
		};

		broadcastMessage({
			username: MESSAGE_MODERATOR,
			message: JSON.stringify(payload),
			channel,
			playfabId: pfid,
		});
	}

	if (isModerator) className += " moderation";

	function onClickUserName() {
		setActiveProfileCard(pfid);
		getOtherPlayerData(pfid);
	}

	function getModeration() {
		if (!isModerator) return null;
		return (
			<div className="moderation-dots">
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="tooltip">
					<div
						role="button"
						tabIndex="-1"
						className="action"
						onClick={() => onModeratorAction("question")}
						onKeyPress={() => onModeratorAction("question")}
					>
						Q&amp;A
					</div>
					<div
						role="button"
						tabIndex="-1"
						className="action"
						onClick={() => onModeratorAction("ban_user")}
						onKeyPress={() => onModeratorAction("ban_user")}
					>
						Ban
					</div>
					<div
						role="button"
						tabIndex="-1"
						className="action"
						onClick={() => onModeratorAction("disable_chat")}
						onKeyPress={() => onModeratorAction("disable_chat")}
					>
						Disable Chat
					</div>
				</div>
			</div>
		);
	}

	function DisplayPartnerName() {
		if (otherPlayerData && otherPlayerData.data[PARTNERSTAT.PROFILE_FIELD_NAME]) {
			if (otherPlayerData.data[PARTNERSTAT.PROFILE_FIELD_NAME].toLowerCase() === partnerName.toLowerCase().trim()) {
				return <span className="partner-name">from {partnerName}</span>;
			}
			getOtherPlayerData(pfid);
		}
		return null;
	}

	function checkMentions(text) {
		const index = text.indexOf(displayName);
		if (index >= 0) {
			const highlightedText =
				text.substring(0, index) +
				"<span class='highlight'>" +
				displayName +
				"</span>" +
				text.substring(index + displayName.length);
			return parse(highlightedText);
		}
		return text;
	}

	// ignore _SYSTEM_ messages?
	return HIDDEN_MESSAGES.find((x) => x === author) ? null : (
		<div className={"message " + className}>
			<div className={` author ${REACT_APP_AS_STACK_APP_ID === 'we8edz' ? 'display-public-profile' : ""}`} onClick={onClickUserName}>
				<Avatar showGreenAvatar={isSameAuthor} avatar={otherPlayerData && otherPlayerData.data.Avatar} />
				<div className="author-name">
					{author} {isSameAuthor && "(me)"} <DisplayPartnerName /> {isSameAuthor}
				</div>
				<span className="time" ref={timeRef} />
			</div>
			<div className="msg">{checkMentions(message)}</div>
			{getModeration()}
		</div>
	);
}

ChatMessage.defaultProps = {
	author: null,
	channel: null,
	message: null,
	partnerName: null,
	displayName: null,
	isModerator: false,
	pfid: "",
	otherPlayerData: null,
	broadcastMessage: () => {},
	getOtherPlayerData: () => {},
	setActiveProfileCard: () => {},
	timestamp: 0,
};

ChatMessage.propTypes = {
	author: PropTypes.string,
	channel: PropTypes.string,
	message: PropTypes.string,
	partnerName: PropTypes.string,
	displayName: PropTypes.string,
	isModerator: PropTypes.bool,
	broadcastMessage: PropTypes.func,
	getOtherPlayerData: PropTypes.func,
	setActiveProfileCard: PropTypes.func,
	timestamp: PropTypes.number,
	pfid: PropTypes.string,
	otherPlayerData: PropTypes.object,
};

export default memo(ChatMessage);
