
import environments from './Environments.json';

const wordpressUrl = process.env.REACT_APP_WP || "https://demo.dev.cms.holoscene.events";

const defaultSettings = {
	"REACT_APP_APP_NAME":"Local Demo HoloScene",
	"REACT_APP_AS_STACK_APP_ID": process.env.REACT_APP_AS_STACK_APP_ID  ||  "we8edz",
	"REACT_APP_PLAYFAB_APP_ID": process.env.REACT_APP_PLAYFAB_APP_ID || "E08DE",
	"REACT_APP_PLAYFAB_URL": process.env.REACT_APP_PLAYFAB_URL || "https://e08de.prod.holoscene.events",
	"REACT_APP_REALTIME_API_KEY": process.env.REACT_APP_REALTIME_API_KEY || "TSCHDaQkJKXwD2ajpaF4eo79An1kFP",
	"REACT_APP_DEFAULT_LANG": process.env.REACT_APP_DEFAULT_LANG || "en",
	"REACT_APP_WP_API": `${wordpressUrl}/wp-json/wp/v2/`,
	"REACT_APP_WP_API_ACF": `${wordpressUrl}/wp-json/acf/v2/`,
	"REACT_APP_LOGIN_REDIRECT_URL": process.env.REACT_APP_LOGIN_REDIRECT_URL || "https://holosceneb2c.b2clogin.com/holosceneb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_DemoSignUpLogIn&client_id=feb39d8f-2163-403b-9549-941befadba93&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fdemo.dev.holoscene.events&scope=openid&response_type=id_token&prompt=login",
	"REACT_APP_LOADING_LOGO": process.env.REACT_APP_LOADING_LOGO || "https://wp.cf.holoscene.events/demo/2022/04/11115420/Property-1Main-Property-2Large-v2.png",
	"REACT_APP_LOADING_BACKGROUND": process.env.REACT_APP_LOADING_BACKGROUND || "https://wp.cf.holoscene.events/demo/2022/03/23114234/Property-1Large-1.png",
	"REACT_APP_PAGE_ICON": process.env.REACT_APP_PAGE_ICON || "https://wp.cf.holoscene.events/demo/2022/03/14131346/Vector-2.png",
	"REACT_APP_CHAT_API": process.env.REACT_APP_CHAT_API || "https://chat.holoscene.events/chat",
	"REACT_APP_STACK_API": process.env.REACT_APP_STACK_API || "https://hive.prod.holoscene.events",
	"REACT_APP_MANAGER_API": process.env.REACT_APP_MANAGER_API || "https://api-2.prod.holoscene.events",
	"REACT_APP_REALTIME_API": process.env.REACT_APP_REALTIME_API || "https://realtime.prod.holoscene.events",
	"REACT_APP_WP_CDN": process.env.REACT_APP_WP_CDN || "wp.cf.holoscene.events",
	"REACT_APP_EVENTS_PAGE_ENALED": process.env.REACT_APP_EVENTS_PAGE_ENALED || true,
	"REACT_APP_EVENT_PAGE_ENALED": process.env.REACT_APP_EVENT_PAGE_ENALED || false,
	"REACT_APP_HOME_PAGE_ENABLED": process.env.REACT_APP_HOME_PAGE_ENABLED || true,
	"REACT_APP_MY_AGENDA_PAGE_ENABLED": process.env.REACT_APP_MY_AGENDA_PAGE_ENABLED || true,
	"REACT_APP_CHAT_ROOMS_PAGE_ENABLED": process.env.REACT_APP_CHAT_ROOMS_PAGE_ENABLED || true,
	"REACT_APP_SPEAKERS_PAGE_ENABLED": process.env.REACT_APP_SPEAKERS_PAGE_ENABLED || true,
	"REACT_APP_SPEAKER_PAGE_ENABLED": process.env.REACT_APP_SPEAKER_PAGE_ENABLED || false,
	"REACT_APP_PARTNERS_PAGE_ENABLED": process.env.REACT_APP_PARTNERS_PAGE_ENABLED || true,
	"REACT_APP_PARTNER_PAGE_ENABLED": process.env.REACT_APP_PARTNER_PAGE_ENABLED || true,
	"REACT_APP_HAS_MANY_PARTNER": process.env.REACT_APP_HAS_MANY_PARTNER || true,
	"REACT_APP_FAQ_PAGE_ENABLED": process.env.REACT_APP_FAQ_PAGE_ENABLED || true,
	"REACT_APP_PROFILE_PAGE_ENABLED": process.env.REACT_APP_PROFILE_PAGE_ENABLED || true,
	"REACT_APP_TECH_SUPPORT_PAGE_ENABLED": process.env.REACT_APP_TECH_SUPPORT_PAGE_ENABLED || true,
	"REACT_APP_SHOW_ROOM_PAGE_ENABLED": process.env.REACT_APP_SHOW_ROOM_PAGE_ENABLED || true,
	"REACT_APP_CUSTOM_LISTING_PAGE_ENABLED": process.env.REACT_APP_CUSTOM_LISTING_PAGE_ENABLED || true,
	"REACT_APP_VIRTUAL_LOUNGE_PAGE_ENABLED": process.env.REACT_APP_VIRTUAL_LOUNGE_PAGE_ENABLED || true,
	"REACT_APP_BOOK_MEETING_PAGES": process.env.REACT_APP_BOOK_MEETING_PAGES || null,
	"REACT_APP_HAS_ANALYTICS_DASHBOARD": process.env.REACT_APP_HAS_ANALYTICS_DASHBOARD || true,
	"REACT_APP_AMPLIFY_REGION":"us-east-2",
	"REACT_APP_HOSTNAME": process.env.REACT_APP_HOSTNAME || document.location.hostname,
 	"REACT_APP_BRP_CLIENT": process.env.REACT_APP_BRP_CLIENT || false,
	"REACT_APP_VUSE_CLIENT": process.env.REACT_APP_VUSE_CLIENT || false,
 	"REACT_APP_JITSI_MAGIC_COOKIE": process.env.REACT_APP_JITSI_MAGIC_COOKIE || "vpaas-magic-cookie-02b3f150a9ca4d4d8616b65c151ebf2d",
	"REACT_APP_STREAM_STARTING_SOON": process.env.REACT_APP_STREAM_STARTING_SOON || "https://d1wfxcioahjmrj.cloudfront.net/videos/ScreenSaver_StartingSoon.mp4",
	"REACT_APP_STREAM_ENDED_URL": process.env.REACT_APP_STREAM_ENDED_URL || "https://d1wfxcioahjmrj.cloudfront.net/videos/ScreenSaver_ThanksForJoiningUs.mp4",
	"REACT_APP_DEFAULT_NO_APP_VIDEO": process.env.REACT_APP_DEFAULT_NO_APP_VIDEO || "https://d1r6hujl5y77og.cloudfront.net/assets-video/loop%2Bmusic_720p.mp4"};

function getEnvironmentSettings(host) {
	return environments[host] ? environments[host] : {};
};

function assignWordPressUrl(overridingSettings){
	const url = overridingSettings["REACT_APP_WP"];
	if(url){
		overridingSettings["REACT_APP_WP_API"] = `${url}/wp-json/wp/v2/`;
		overridingSettings["REACT_APP_WP_API_ACF"]= `${url}/wp-json/acf/v2/`;
	}
}

function createFromHostname(host) {
	// const host = defaultSettings["REACT_APP_HOSTNAME"];
	const overridingSettings = getEnvironmentSettings(host);
	assignWordPressUrl(overridingSettings);
	// console.log(JSON.stringify(overridingSettings, null, 2));
	return Object.assign(defaultSettings, overridingSettings);
}

export { createFromHostname, defaultSettings };