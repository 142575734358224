import { createSlice } from '@reduxjs/toolkit';
import { jsonWordpressDataApi } from 'api';
import { REACT_APP_DEFAULT_LANG, REACT_APP_WP_CDN } from 'Constants';
import { createDebouncedAsyncAction } from 'utils/reduxhelpers';

/**
*Fetch content from WordPress pages like FAQ
*/
export const fetchWPPageData = createDebouncedAsyncAction(
	'wppage/getData',
	(data) => {
		return jsonWordpressDataApi(`${data.pageSlug}&lang=${data.playerGroup}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				pageContent: action.payload[0],

			};
		},
	}
);

export const fetchWPNoticesData = createDebouncedAsyncAction(
	'wppage/getNoticesData',
	(data) => {
		return jsonWordpressDataApi(`pages?slug=notices&lang=${data.playerGroup}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				notices: action.payload[0],
			};
		},
	}
);

export const fetchWPMobileTheme = createDebouncedAsyncAction(
	'wppage/getDataMobileTheme',
	(data) => {
		return jsonWordpressDataApi(`pages?slug=mobiletheme&lang=${data.playerGroup}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				mobileTheme: action.payload[0],
			};
		},
	}
);

export const fetchWPSpeakersData = createDebouncedAsyncAction(
	'wppage/getSpeakersData',
	(data) => {
		const url = 'partners?per_page=9999&order=asc&orderby=id';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				speakers: [
					...action.payload.sort((
						a,
						b
					) => {
						const speakersA = a?.acf?.visual_order;
						const speakersB = b?.acf?.visual_order;
						if (speakersA > speakersB) {
							return 1;
						}
						if (speakersA < speakersB) {
							return -1;
						}
						return 0;
					}),
				],
			};
		},
	}
);

export const fetchWPGalleriesData = createDebouncedAsyncAction(
	'wppage/getGalleryData',
	(data) => {
		const url = 'gallery?per_page=100';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				galleries: [
					...action.payload,
				],
			};
		},
	}
);

/**
*Return Tags from the WordPress, tags are used to associate content to a region (NA,APAC,EMEA)
*/
export const fetchWPTagsData = createDebouncedAsyncAction(
	'wppage/getTags',
	(data) => {
		const url = 'tags?per_page=100&order=asc&orderby=id';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				tags: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWPSpeakerData = createDebouncedAsyncAction(
	'wppage/getSpeakerSingleData',
	(data) => {
		const url = `partners?slug=${data}`;
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				speakerSingle: action.payload[0],
			};
		},
	}
);

export const fetchWPPartnersData = createDebouncedAsyncAction(
	'wppage/getPartnersData',
	(data) => {
		const url = 'techpartners?order=asc&orderby=title&per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				techpartners: [
					...action.payload.sort((
						a,
						b
					) => {
						const partnersA = a?.acf?.visual_order;
						const partnersB = b?.acf?.visual_order;
						if (partnersA > partnersB) {
							return 1;
						}
						if (partnersA < partnersB) {
							return -1;
						}
						return 0;
					}),
				],
			};
		},
	}
);

export const fetchWPPartnerData = createDebouncedAsyncAction(
	'wppage/getPartnerSingleData',
	(data) => {
		const url = `techpartners?slug=${data.partnerId}`;
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				techpartnerSingle: action.payload[0],
			};
		},
	}
);

export const fetchWPChannelsData = createDebouncedAsyncAction(
	'wppage/getChannels',
	(data) => {
		const url = 'channels?per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			
			if (action.payload.length > 0) {
				action.payload = JSON.parse(
					JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
				);
				return {
					...state,
					channels: [
						...action.payload,
					],
				};
			}
			return {
				...state,
				channels: [],
			};
		},
	}
);

export const fetchWPEventData = createDebouncedAsyncAction(
	'wppage/getEventSingleData',
	(data) => {
		const url = `events?slug=${data.EventId}`;
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				eventSingle: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWPInteractiveGalleriesData = createDebouncedAsyncAction(
	'wppage/getInteractiveGalleriesData',
	(data) => {
		const url = 'interactive_gallery?per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				interactive_gallery: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWPInteractiveGalleryData = createDebouncedAsyncAction(
	'wppage/getInteractiveGallerySingleData',
	(data) => {
		const url = `interactive_gallery?slug=${data.interactive_galleryId}`;
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload[0] = JSON.parse(
				JSON.stringify(action.payload[0]).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				interactive_gallerySingle: action.payload[0],
			};
		},
	}
);

export const fetchWPCustomListings = createDebouncedAsyncAction(
	'wppage/fetchWPCustomListings',
	(data) => {
		const url = 'custom_listing?per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				custom_listings: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWpContentGalleryData = createDebouncedAsyncAction(
	'wppage/fetchGalleryMedias',
	(data) => {
		const url = 'content_gallery?per_page=100';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				gallery_medias: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWpShowroomData = createDebouncedAsyncAction(
	'wppage/fetchShowrooms',
	(data) => {
		const url = 'showroom?per_page=100';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				showrooms: [
					...action.payload.sort((
						a,
						b
					) => {
						const showroomA = a.title.rendered;
						const showroomB = b.title.rendered;
						if (showroomA > showroomB) {
							return -1;
						}
						if (showroomA < showroomB) {
							return 1;
						}
						return 0;
					}),
				],
			};
		},
	}
);

export const fetchWpDealerLoungeData = createDebouncedAsyncAction(
	'wppage/fetchDealersLounged',
	(data) => {
		const url = 'dealer-lounge?per_page=100';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				dealers_lounge: [
					...action.payload,
				],
			};
		},
	}
);

export const fetchWPCustomListingCategories = createDebouncedAsyncAction(
	'wppage/fetchWPCustomListingCategories',
	(data) => {
		const url = 'custom_listing_category?per_page=9999';
		return jsonWordpressDataApi(data.playerGroup ? `${url}&lang=${data.playerGroup}` : `${url}&lang=${REACT_APP_DEFAULT_LANG}`);
	},
	{
		fulfilled: (state, action) => {
			action.payload = JSON.parse(
				JSON.stringify(action.payload).replace(/holoscene-wp-prod.s3.us-east-2.amazonaws.com/g, `${REACT_APP_WP_CDN}`)
			);
			return {
				...state,
				custom_listing_categories: [
					...action.payload,
				],
			};
		},
	}
);

const wppage = createSlice({
	name: 'wppage',
	reducers: {
	},
	extraReducers: {
		...fetchWPPageData.reducers,
		...fetchWPNoticesData.reducers,
		...fetchWPMobileTheme.reducers,
		...fetchWPSpeakersData.reducers,
		...fetchWPSpeakerData.reducers,
		...fetchWPGalleriesData.reducers,
		...fetchWPTagsData.reducers,
		...fetchWPPartnersData.reducers,
		...fetchWPPartnerData.reducers,
		...fetchWPChannelsData.reducers,
		...fetchWPEventData.reducers,
		...fetchWPInteractiveGalleriesData.reducers,
		...fetchWPInteractiveGalleryData.reducers,
		...fetchWPCustomListings.reducers,
		...fetchWpContentGalleryData.reducers,
		...fetchWpShowroomData.reducers,
		...fetchWpDealerLoungeData.reducers,
		...fetchWPCustomListingCategories.reducers,
	},
	initialState: {
		notices: {},
		mobileTheme: {},
		speakers: [],
		galleries: [],
		tags: [],
		techpartners: [],
		speakerSingle: {},
		techpartnerSingle: {},
		channelSingle: [],
		channels: null,
		eventSingle: [],
		interactive_gallery: [],
		interactive_gallerySingle: {},
		pageContent: {},
		custom_listings: [],
		gallery_medias: [],
		dealers_lounge: [],
		showrooms: [],
	},
});

export default wppage;
