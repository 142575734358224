/** @format */

import preval from "preval.macro";
import { createFromHostname } from "./GetEnvironment";

const environment = createFromHostname(
	process.env.REACT_APP_HOSTNAME || document.location.hostname
);

export const {
	REACT_APP_AMPLIFY_REGION,
	REACT_APP_AS_STACK_APP_ID,
	REACT_APP_PLAYFAB_APP_ID,
	REACT_APP_PLAYFAB_URL,
	REACT_APP_REALTIME_API_KEY,
	REACT_APP_DEFAULT_LANG,
	REACT_APP_WP_API,
	REACT_APP_WP_API_ACF,
	REACT_APP_CHAT_API,
	REACT_APP_STACK_API,
	REACT_APP_MANAGER_API,
	REACT_APP_REALTIME_API,
	REACT_APP_WP_CDN,
	REACT_APP_LOGIN_REDIRECT_URL,
	REACT_APP_PAGE_ICON,
	REACT_APP_APP_NAME,
	REACT_APP_LOADING_LOGO,
	REACT_APP_LOADING_BACKGROUND,
	REACT_APP_EVENTS_PAGE_ENALED,
	REACT_APP_EVENT_PAGE_ENALED,
	REACT_APP_HOME_PAGE_ENABLED,
	REACT_APP_MY_AGENDA_PAGE_ENABLED,
	REACT_APP_CHAT_ROOMS_PAGE_ENABLED,
	REACT_APP_SPEAKERS_PAGE_ENABLED,
	REACT_APP_SPEAKER_PAGE_ENABLED,
	REACT_APP_PARTNERS_PAGE_ENABLED,
	REACT_APP_PARTNER_PAGE_ENABLED,
	REACT_APP_FAQ_PAGE_ENABLED,
	REACT_APP_PROFILE_PAGE_ENABLED,
	REACT_APP_TECH_SUPPORT_PAGE_ENABLED,
	REACT_APP_SHOW_ROOM_PAGE_ENABLED,
	REACT_APP_CUSTOM_LISTING_PAGE_ENABLED,
	REACT_APP_VIRTUAL_LOUNGE_PAGE_ENABLED,
	REACT_APP_BOOK_MEETING_PAGES,
	REACT_APP_HAS_ANALYTICS_DASHBOARD,
	REACT_APP_JITSI_MAGIC_COOKIE,
	REACT_APP_BRP_CLIENT,
	REACT_APP_VUSE_CLIENT,
	REACT_APP_STREAM_STARTING_SOON,
	REACT_APP_STREAM_ENDED_URL,
	REACT_APP_DEFAULT_NO_APP_VIDEO,
} = environment;

export const { NODE_ENV } = process.env;

export const BUILD_DATETIME = preval`module.exports = new Date().toLocaleString()`;

export const MAX_ERRORS_BEFORE_REBOOT = 50;

export const DEBUG = {
	RULES: true,
};

export const LANGS = ["en", "fr"];

// Responsive breakpoints.
// See $grid-breakpoints in index.scss
export const BREAKPOINTS = {
	xs: 384, // TODO: Validate breakpoint value.
	sm: 576,
	md: 768,
	lg: 1024,
	xl: 1366,
	xxl: 1920,
}
// Responsive breakpoints queries.
// See $grid-breakpoints in index.scss
export const BREAKPOINTS_QUERIES = {
	xs: '(max-width: 384px)', // TODO: Validate breakpoint value.
	sm: '(max-width: 576px)',
	md: '(max-width: 768px)',
	lg: '(max-width: 1024px)',
	xl: '(max-width: 1366px)',
	xxl: '(max-width: 1920px)',
	//or: '(orientation: portrait)',
}

// Poll rates
export const POLL_RATES = {
	FETCH_WPACTIVITIES: "FETCH_WPACTIVITIES",
	GETPLAYERPROFILE: "GETPLAYERPROFILE",
	GETPLAYERDATA: "GETPLAYERDATA",
	GETPLAYERREADONLYDATA: "GETPLAYERREADONLYDATA",
	GETPLAYERSTATISTICS: "GETPLAYERSTATISTICS",
	GETCATALOG: "GETCATALOG",
	GETSTORELOADOUT: "GETSTORELOADOUT",
	GETEVENTSINVENTORY: "GETEVENTSINVENTORY",
	GETITEMINVENTORY: "GETITEMINVENTORY",
};

export const UPDATE_PLAYER_DATA_DELAY = 5000;

export const APP_MODES = {
	SINGLE_CHANNEL: "SINGLE_CHANNEL",
	MULTI_CHANNEL: "MULTI_CHANNEL",
};

export const ROUTES = {
	ERROR_404: "/404",
	ROOT: "/",
	MAIN_HUB: "/",
	CHANNEL: "/channel/:channelId",
	ACTIVITY: "/activity/:tileId",
	FAQ: "/faq",
	NOTICES: "/notices",
	SPONSORS: "/sponsors",
	PRIVACY: "/notices/privacy-policy",
	TERMS: "/notices/terms-of-use",
	CODE_OF_CONDUCT: "/code-of-conduct",
	SPEAKERS: "/speakers",
	SUPPORT: "/support",
	SPEAKER: "/speaker/:speakerId",
	PARTNERS: "/partners",
	PARTNER: "/partner/:partnerId",
	PARTNER_ALONE: "/partner",
	EVENT: "/events/:tileId",
	AGENDA: "/agenda",
	CALENDAR: "/calendar",
	PRIVATETEST: "/private-test",
	PROFILE: "/profile",
	PROFILE_BADGES: "/profile/badges_prizes",
	PROFILE_LEADERBOARD: "/profile/leaderboard",
	PROFILE_CONTACTS: "/profile/contact_list",
	PUBLIC_PROFILES: "/public-profiles/:profileId",
	TEAMS_MEETING:
		"https://teams.microsoft.com/l/entity/c2f624d3-bc2e-408f-8316-cd236e0308e4/001688a8-e758-4d84-82f6-fb8727986bfb?event=:roomUID",
	GENETEC_BOOTH: "/genetec-booth",
	ALL_ROOMS: "/all-rooms",
	RANDOM_ROOM_SINGLE: "/random-room/:roomId",
	CUSTOM_LISTING: "/custom-listing/:listingSlug",
	CATEGORY: "/category/:categorySlug",
	INTERACTIVE_GALLERY: "/interactive-gallery",
	GALLERY_MEDIA: "/gallery-media/:mediaId",
	DEALER_LOUNGE: "/dealer-lounge",
	SHOWROOMS: "/showrooms",
	SHOWROOM: "/showroom/:showRoomSlug",
	SESSION: "/session/:tileId",
	TECHSUPPORT: "/tech-support",
	SESSION_DETAILS: "/session-details/:tileId",
	GALLERY: "/gallery",
	HOME: "/",
};

export const DEFAULT_USERNAME = "VIP";

// Inventory ids

export const CATALOG = {
	banned_user_item: "Account Banned",
	disable_chat: "i-1607195687355-282",
	update_needed_item: "i-1607199781464-208",
	account_completed_item: "Account Completed",
	account_declined_item: "Account Declined",
};

export const ACTIVITY_TYPES = {
	PRIVATE_ROOM: "TeamsPrivateRoom",
};

// Playfab Events

export const PLAYFAB_EVENTS = {
	player_completed_welcomekit: "player_completed_welcomekit",
	private_room_info: "PrivateRoomInfo",
	player_completed_profile: "player_completed_profile",
	player_joined_stream: "player_joined_stream",
	player_questionbox_ask: "player_questionbox_ask",
	player_meeting_event: "player_meeting_event",
	player_thank_you_page: "player_reached_thankyou",
	change_region: "change_region",
};

export const WP_TYPES_MAP = {
	Speaker: "partners",
};

export const NOTIFICATION_TYPES_MAP = {
	"Meeting Reminder": "reminder",
	"Read More": "wpcontent",
	"Go to page": "redirect",
	"Take Over Page": "takeover",
	"Virtual Studio": "virtualstudio",
	"Random Room": "randomroom",
};

export const USER_LEVELS = {
	USER: 1,
	MODERATOR: 2,
	ADMIN: 3,
};

export const PARTNERSTAT = {
	PARTNER: 1,
	PROFILE_FIELD_NAME: "partner_company",
};
export const ADMINSTAT = {
	ADMIN: 1,
};

export const VPSTAT = {
	VP: 1,
};

export const REGISTRATIONSTAT = {
	BACKEND: "backend_registration_complete",
};

//Player Regions
export const REGION = {
	APAC: "APAC",
	EMEA: "EMEA",
	NA: "NA",
	ALL: "ALL",
};

//Player Groups
export const GROUP = {
	0: "group0",
	1: "group1",
	2: "group2",
	3: "group3",
	4: "group4",
	5: "group5",
	6: "group6",
	7: "group7",
	8: "group8",
	9: "group9",
	10: "group10",
	11: "group11",
	12: "group12",
	13: "group13",
	14: "group14",
	15: "group15",
	16: "group16",
	17: "group17",
	18: "group18",
	19: "group19",
	20: "group20",
	21: "group21",
	22: "group22",
	23: "group23",
	24: "group24",
	25: "group25",
};

//Player Regions Stat
export const REGIONSTAT = {
	REGION: "region",
};

//Player UserModerator Stat
export const USERMODERATORROOMSTAT = {
	USERMODERATORROOM: "user_moderator_room",
	MODERATOR: 1,
};

export const COMPANEL_TABS = {
	CHAT: "chat",
	QA: "qa",
	AVAILABILITIES: "availabilities",
};

export const ROOMSETTINGS = {
	PRIVATE: "Private",
	PUBLIC: "Public",
	MAXUSERCOUNT: 90,
	MAXPARTICIPANTCARDDISPLAYED: 5,
};

export const USERMODERATORSTAT = {
	USERMODERATOR: "user_moderator",
};
export const EXPERTSTAT = {
	EXPERTSTAT: "expert",
};

//Player HasEmail Stat
export const USERHASEMMAILSTAT = {
	USEREMAIL: "has_email",
};

export const EXCLUDED_LOGGER_ACTIONS = [
	"playfab/fetch",
	"playfab/getPlayerProfile",
	"playfab/getPlayerStatistics",
	// 'playfab/getItemInventory',
	"playfab/getMeetingStatus",
	"playfab/writePlayerEvent",
	"playfab/writeTelemetryEvent",
	"playfab/acquireCatalogItem",
	"playfab/consumeInventoryItem",
	"playfab/resetMission",
	"playfab/getItemCatalog",
	"playfab/getStoreLoadout",
	"playfab/purchaseStoreItem",
	"playfab/getEventsInventory",
	"playfab/sendEventInput",
	"auth/",
	"playfab/",
	// 'chat/',
	"teams/",
	"wp",
	// 'playfab/getPlayerStatus',
	// 'playfab/getLiveVersion',
];

/**
 * DOMAIN_MAGIC_COOKIE
 */
export const DOMAIN_MAGIC_COOKIE = "8x8.vc";

/**
 * VPAS_MAGIC_COOKIE
 */
export const VPAS_MAGIC_COOKIE =
	"vpaas-magic-cookie-02b3f150a9ca4d4d8616b65c151ebf2d/40f733";

/**
 * PRIVATE_TEAMS_EVENTS
 */
export const PRIVATE_TEAMS_EVENTS = "https://private.teams.events/";

/**
 * JITSI_SCRIPT_URL
 */
export const JITSI_SCRIPT_URL = `https://${DOMAIN_MAGIC_COOKIE}/external_api.js`;

/**
 * AWS_AVATAR_URL
 */
export const AWS_AVATAR_URL = (playfabId) =>
	`https://s3.amazonaws.com/vuse-power-on/avatar/${REACT_APP_AS_STACK_APP_ID}/${playfabId}.png`;
