/** @format */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AgendaCard from "mobile/components/agenda/AgendaCard";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import dayjs from "dayjs";
import Tab from "react-bootstrap/Tab";
import { Navbar, Tabs } from "react-bootstrap";
import { ReactComponent as FilterIcon } from "assets/icons/pack1/icon-filter.svg";
import Calendar from "mobile/components/agenda/Calendar";
import AgendaFilter from "mobile/components/agenda/AgendaFilter";
import AgendaSearch from "mobile/components/agenda/AgendaSearch";
import logPageView from "utils/logPageView";

function Agenda({
	eventTiles,
	acfPageOptions,
	myAgendaTiles,
	channels,
	userAttendanceType,
	purchaseTile,
	writeTelemetryEvent,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
}) {
	const navItems = [
		{ name: acfPageOptions?.acf?.agenda?.event_schedule, key: "event" },
		{ name: acfPageOptions?.acf?.agenda?.my_booking, key: "booking" },
	];

	const [today, setDate] = useState(new Date());
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [tab, setTab] = useState("event");
	const [expanded, setExpanded] = useState(false);
	const [filters, setFilters] = useState({});
	const [applyFilters, setApplyFilters] = useState(false);
	const [dayWithEvent, setDateWithEvent] = useState([]);
	const [filterItemLength, setFilterItemLength] = useState(0);
	const [filterItemLengthAgenda, setFilterItemLengthAgenda] = useState(0);
	const [events, setEvents] = useState([]);
	const [myAgenda, setMyAgenda] = useState(myAgendaTiles);

	const [clearFilters, setClearFilters] = useState(false);

	const filteredItems = [];

	useEffect(() => {
		getAllDayWithEvent();
	}, [events]);

	useEffect(() => {
		if (eventTiles !== events && !applyFilters) {
			setEvents(eventTiles);

			getAllDayWithEvent();
		}

		if (myAgendaTiles !== myAgenda && !applyFilters) {
			setMyAgenda(myAgendaTiles);
		}
	}, [eventTiles, myAgendaTiles, applyFilters]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"agenda",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	useEffect(() => {
		if (applyFilters) {
			searchByFilters();
		}
	}, [applyFilters, tab]);

	const handleClearFilters = () => {
		setFilters({});
		setApplyFilters(false);

		setClearFilters(true);
	};

	const getAllDayWithEvent = () => {
		events
			?.filter((tile) => {
				return (
					tile?.activity?.data?.activity_type !== "DealersLounge" &&
					tile?.activity?.data?.activity_type !== "MeetingRoom"
				);
			})
			.map((tile) => {
				if (!dayWithEvent?.includes(tile.activity.date_begin.toString())) {
					setDateWithEvent((prevState) => [
						...prevState,

						tile.activity.date_begin.toString(),
					]);
				}

				return tile;
			});
	};

	const renderTabContent = (data, name) => {
		return (
			<>
				<div>
					{data &&
						data?.length > 0 &&
						acfPageOptions?.acf?.home?.number_of_events &&
						displayEvents(data, name)}
				</div>
				<div>
					<p className="past-event-text">
						{acfPageOptions?.acf?.agenda?.past_sessions}
					</p>

					{data &&
						data?.length > 0 &&
						acfPageOptions?.acf?.home?.number_of_events &&
						renderPastEvent(data, name)}
				</div>
			</>
		);
	};

	const handleSelectTab = (tab) => {
		setTab(tab);
	};

	const searchByFilters = () => {
		let data;
		if (tab === "event") {
			data = events || eventTiles;
		} else if (tab === "booking") {
			data = myAgenda || myAgendaTiles;
		}

		const filteredEvents = data.reduce((_, item) => {
			if (Object.keys(filters).length > 0) {
				if (
					(filters?.topic &&
						filters?.topic[
							item?.activity?.content?.acf?.session_topic?.topic
						]) ||
					(filters?.session &&
						filters?.session[
							item?.activity?.content?.acf?.event_type?.toLowerCase()
						])
				) {
					filteredItems.push(item);

					return filteredItems;
				}
				if (filters?.speaker?.internal || filters?.speaker?.external) {
					const filteredItem = item?.activity.speakers.map((speaker) => {
						if (
							(filters.speaker.internal && speaker.type === "partners") ||
							(filters.speaker.external && speaker.type !== "partners")
						) {
							return item;
						}

						return null;
					});
					return filteredItem;
				}

				return filteredItems;
			}

			return [];
		}, []);

		if (tab === "event") {
			setEvents(filteredEvents);
			setFilterItemLength(filteredEvents.length);
		} else {
			setMyAgenda(filteredEvents);
			setFilterItemLengthAgenda(filteredEvents.length);
		}
	};

	const renderNavItem = () => {
		return navItems.map(({ key, name }) => {
			return (
				<Nav.Item key={key}>
					<Nav.Link eventKey={key} href="#">
						<p className={tab === key ? "tab-title-selected" : "tab-title"}>
							{name}
						</p>
					</Nav.Link>
				</Nav.Item>
			);
		});
	};

	const searchByName = (name) => {
		let data;

		if (tab === "event") {
			data = events || eventTiles;

			const searchResult = data.filter((item) =>
				item.activity.content.title.rendered
					.toLowerCase()
					.includes(name.toLowerCase())
			);

			return setEvents(searchResult);
		}
		if (tab === "booking") {
			data = myAgenda || myAgendaTiles;

			const searchResult = data.filter((item) =>
				item.activity.content.title.rendered
					.toLowerCase()
					.includes(name.toLowerCase())
			);

			return setMyAgenda(searchResult);
		}

		return null;
	};

	function renderPastEvent(data) {
		const pastEvents = data.filter((tile) => {
			return (
				tile?.activity?.data?.activity_type !== "DealersLounge" &&
				tile?.activity?.data?.activity_type !== "MeetingRoom" &&
				dayjs(today).isAfter(tile?.activity?.date_end, "hour")
			);
		}).map((event) => {
			return (
				<AgendaCard
					key={event.id}
					channels={channels}
					userAttendanceType={userAttendanceType}
					purchaseTile={purchaseTile}
					writeTelemetryEvent={writeTelemetryEvent}
					writePlayerEvent={writePlayerEvent}
					getStoreLoadout={getStoreLoadout}
					getItemInventory={getItemInventory}
					data={event}
					acfPageOptions={acfPageOptions}
				/>
			);
		});

		if (pastEvents?.length === 0) return null;


		return (
			<div className="live-agenda">
				<div className="live-events">{pastEvents}</div>
			</div>
		)
	}

	function displayEvents(data, name) {
		const homePageEvents = data
			.filter((tile) => {
				return (
					tile?.activity?.data?.activity_type !== "DealersLounge" &&
					dayjs(selectedDate).isSame(tile.activity.date_begin, "day") &&
					dayjs(today).isBefore(tile?.activity?.date_end, "hour")
				);
			})
			.slice(0, acfPageOptions.acf?.home?.number_of_events)
			.map((tile) => {
				return (
					<AgendaCard
						key={tile.id}
						channels={channels}
						userAttendanceType={userAttendanceType}
						purchaseTile={purchaseTile}
						writeTelemetryEvent={writeTelemetryEvent}
						writePlayerEvent={writePlayerEvent}
						getStoreLoadout={getStoreLoadout}
						getItemInventory={getItemInventory}
						data={tile}
						acfPageOptions={acfPageOptions}
					/>
				);
			});

		if (homePageEvents.length !== filterItemLength && name === "event") {
			setFilterItemLength(homePageEvents.length);
		} else if (
			homePageEvents.length !== filterItemLengthAgenda &&
			name === "booking"
		) {
			setFilterItemLengthAgenda(homePageEvents.length);
		}

		if (homePageEvents.length === 0) return null;

		return (
			<div className="live-agenda">
				<div className="live-events">{homePageEvents}</div>
			</div>
		);
	}

	return (
		<div className="agenda-page mobile-block">
			<div className="container-fluid">
				<div className="mobile-agenda align-items-start">
					<div className="container-fluid">
						<div className="agenda-content card-wrapper">
							<Calendar
								selectedDate={selectedDate}
								dayWithEvent={dayWithEvent}
								setSelectedDate={setSelectedDate}
								today={today}
								setDate={setDate}
							/>

							<div className="icon-wrapper">
								<AgendaSearch
									onChange={searchByName}
									acfPageOptions={acfPageOptions}
								/>

								<Navbar
									expanded={expanded}
									fixed="bottom"
									bg="dark"
									expand={false} //TODO: use lg when old desktop removed
									variant="black"
								>
									<Navbar.Collapse  id="basic-navbar-nav">
										<Nav
											className="pb-5 me-auto vh-100 justify-content-end text-uppercase fw-bold"
											as="ul"
										>
											<AgendaFilter
												clearFilters={clearFilters}
												searchByFilters={searchByFilters}
												setApplyFilters={setApplyFilters}
												filters={filters}
												applyFilters={applyFilters}
												setFilters={setFilters}
												setExpanded={setExpanded}
												acfPageOptions={acfPageOptions}
												setEvents={setEvents}
												setMyAgenda={setMyAgenda}
												eventTiles={eventTiles}
												myAgendaTiles={myAgendaTiles}
												setClearFilters={setClearFilters}
											/>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
								<Navbar.Toggle
									onClick={() => setExpanded(true)}
									aria-controls="basic-navbar-nav"
									className="p-0 mt-2 border-0 shadow-none"
								>
									<FilterIcon
										onClick={() => setExpanded(true)}
										className={applyFilters ? "filter-selected" : "filter-icon"}
									/>
								</Navbar.Toggle>

							</div>

							<div className="filter-infos-wrapper">
								{Object.keys(filters).length > 0 && (
									<>
										<div className="filter-filtered-results text-uppercase">
											{acfPageOptions?.acf?.agenda?.filtered_results} (
											{tab === "event"
												? filterItemLength
												: filterItemLengthAgenda}
											)
										</div>

										<div
											role="button"
											tabIndex="-1"
											onKeyPress={handleClearFilters}
											onClick={handleClearFilters}
											className="filter-clear-button text-uppercase"
										>
											{acfPageOptions?.acf?.agenda?.clear_all_filters}
										</div>
									</>
								)}
							</div>

							<Tabs
								defaultActiveKey="event"
								id="justify-tab-example"
								className="mb-3"
								justify
							>
								<Tab className="tab-container" eventKey="event" title="Event Schedule">
									{renderTabContent(events, "event")}
								</Tab>
								<Tab className="tab-container" eventKey="booking" title="My Bookings">
									{renderTabContent(myAgenda, "booking")}
								</Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Agenda.defaultProps = {
	acfPageOptions: null,
	eventTiles: [],
	channels: [],
	myAgendaTiles: [],
	userAttendanceType: "",
	purchaseTile: () => {},
	writeTelemetryEvent: () => {},
	getStoreLoadout: () => {},
	getItemInventory: () => {},
	writePlayerEvent: () => {},
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

Agenda.propTypes = {
	acfPageOptions: PropTypes.object,
	eventTiles: PropTypes.array,
	myAgendaTiles: PropTypes.array,
	channels: PropTypes.array,
	userAttendanceType: PropTypes.string,
	purchaseTile: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

export default Agenda;
