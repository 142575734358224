import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MESSAGE_EMOJI } from "redux/chat";

function List({ emojis, playfabId, broadcastMessage, channel }) {
	const [emojiLimitReached, setEmojiLimitReached] = useState(false);
	useEffect(() => {
		if (emojiLimitReached) {
			setTimeout(() => {
				setEmojiLimitReached(false);
			}, 1000);
		}
	}, [emojiLimitReached]);

	function emitEmoji(e, key, name) {
		e.stopPropagation();
		if (!emojiLimitReached) {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "emoji",
				eventAction: "click",
				eventLabel: name,
			});

			broadcastMessage({
				type: MESSAGE_EMOJI,
				message: key,
				channel,
				playfabId,
			});

			setEmojiLimitReached(true);
		}
	}

	return (
		<div className="d-flex flex-row justify-content-between align-items-center emojis-list">
			{emojis &&
				emojis.map(
					(emoji, key) =>
						emoji?.name && (
							<button type="button"key={`${emoji?.image?.sizes?.thumbnail}`} onClick={(ev) => emitEmoji(ev,`${key}`, `${emoji?.name}`)}>
								<img
									className=""
									src={emoji?.image?.sizes?.thumbnail}
									alt={emoji.name}
								/>
							</button>
						)
				)}
		</div>
	);
}

List.defaultProps = {
	emojis: null,
	playfabId: null,
	broadcastMessage: () => {},
	channel: null,
};

List.propTypes = {
	emojis: PropTypes.array,
	playfabId: PropTypes.string,
	broadcastMessage: PropTypes.func,
	channel: PropTypes.string,
};

export default List;
