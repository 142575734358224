import React from "react";
import EventOver from "containers/Pages/EventOver";
import ThankYou from "containers/Pages/ThankYou";
import { bool } from "prop-types";

function ProgressScreens({ isAdmin, isEventOver, isPlatformClosed }) {
	//TODO: handle premissions else where and set them like a var for canViewClosedEvents

	const renderComponents = () => {
		if (isAdmin === false) {
			if (isEventOver) {
				return <EventOver />;
			}
			if (isPlatformClosed) {
				return <ThankYou />;
			}
			return null;
		}
		return null
	}

	return <>{renderComponents()}</>
}

ProgressScreens.propTypes = {
	isAdmin: bool,
	isEventOver: bool,
	isPlatformClosed: bool,
};

ProgressScreens.defaultProps = {
	isAdmin: false,
	isEventOver: false,
	isPlatformClosed: false,
};

export default ProgressScreens;
